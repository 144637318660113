import { Box, Button } from '@mui/material'
import { FC } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { COLOR } from '../constants/COLOR'

const HEADER_ROUTES = ['/settings']

interface Props {
  title: string
  to: string
}

export const AppHeaderNavigationButton: FC<Props> = (props) => {
  const location = useLocation()
  const isSelected =
    props.to === '/'
      ? HEADER_ROUTES.every((path) => !location.pathname.startsWith(path))
      : location.pathname.startsWith(props.to)

  return (
    <Box mx={0.5}>
      <Button
        component={Link}
        sx={{
          fontSize: 16,
          fontWeight: 600,
          ...(isSelected && {
            borderBottom: `2px solid ${COLOR.blue[500]}`,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
          }),
        }}
        to={props.to}
        color={isSelected ? 'primary' : 'inherit'}
        data-testid={`NavButton-${props.title}`}
      >
        {props.title}
      </Button>
    </Box>
  )
}
