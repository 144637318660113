export const ENVIRONMENT = {
  nodeEnv: process.env.NODE_ENV as 'production' | 'development',
  name: process.env.REACT_APP_ENVIRONMENT as string,
  version: process.env.REACT_APP_VERSION as string,
  appRegion: process.env.REACT_APP_REGION as string,
  zCommerceOrderUrl: process.env.REACT_APP_ZCOMMERCE_ORDER_URL as string,
  zappboardOrderUrl: process.env.REACT_APP_ZAPPBOARD_ORDER_URL as string,
  firebaseConfig: {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY as string,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN as string,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID as string,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET as string,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID as string,
    appId: process.env.REACT_APP_FIREBASE_APP_ID as string,
  },
}
