import { Alert, Box, FormControlLabel, FormGroup, Radio, Typography } from '@mui/material'
import { FC } from 'react'
import { useLocalStorage } from '../../Common/hooks/useLocalStorage'
import { WarehouseStatusIcon } from '../../Warehouse/components/WarehouseStatusIcon'
import { useMyWarehouses } from '../../Warehouse/hooks/useMyWarehouses'

const BOX_HEIGHT = 313

export const FilterWarehouseView: FC = () => {
  const warehouses = useMyWarehouses()
  const localStorage = useLocalStorage()

  return (
    <Box p={2} maxHeight={BOX_HEIGHT} height={BOX_HEIGHT} overflow="auto" data-testid="FilterWarehouseView">
      <Typography fontWeight="bold" mb={1}>
        Stores
      </Typography>
      {warehouses.length === 0 && (
        <Alert sx={{ mt: 2 }} severity="error">
          No stores assigned to your user. Please contact the Zephyr support!
        </Alert>
      )}
      <FormGroup>
        {warehouses.map((warehouse) => (
          <FormControlLabel
            key={warehouse.id}
            control={
              <Radio
                inputProps={{
                  'data-testid': `FilterWarehouseViewRadio-${warehouse.id}`,
                }}
                checked={localStorage.data.selectedWarehouseId === warehouse.id}
                onChange={() => localStorage.set({ selectedWarehouseId: warehouse.id })}
              />
            }
            label={
              <Box display="flex" alignItems="center">
                <Typography ml={0.5}>{warehouse.name}</Typography>
                <Box ml={1}>
                  <WarehouseStatusIcon warehouseStatus={warehouse.status} />
                </Box>
              </Box>
            }
          />
        ))}
      </FormGroup>
    </Box>
  )
}
