import { TaskStatus } from '@quickcommerceltd/zephyr-types'

export function getTaskStatusTranslationByStatus(status: TaskStatus): string {
  switch (status) {
    case 'PENDING_RIDER_ASSIGNMENT':
      return 'Pending'
    case 'ASSIGNED':
      return 'Assigned'
    case 'PICKED_UP':
      return 'Stacked'
    case 'DELIVERING':
      return 'Delivering'
    case 'DELIVERED':
      return 'Successful'
    case 'FAILED':
      return 'Failed'
    case 'UNASSIGNED':
      return 'Unassigned'
    case 'RETURNING_TO_STORE':
      return 'Returning'
    case 'RETURNED':
      return 'Returned'
    case 'NOT_RETURNED':
      return 'Not Returned'
  }
}
