import { ListItem, SvgIconTypeMap, Typography } from '@mui/material'
import { OverridableComponent } from '@mui/material/OverridableComponent'
import { FC } from 'react'

interface Props {
  message: string
  iconComponent: OverridableComponent<SvgIconTypeMap>
  color: string
}

export const SidebarEmptyListItem: FC<Props> = (props) => {
  return (
    <ListItem>
      <props.iconComponent sx={{ color: props.color, fontSize: 20 }} />
      <Typography ml={1} fontSize={12} fontWeight={700}>
        {props.message}
      </Typography>
    </ListItem>
  )
}
