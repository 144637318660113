import { TaskStatus } from '@quickcommerceltd/zephyr-types'

export function getTaskStatusMarkerUrl(taskStatus: TaskStatus): string {
  switch (taskStatus) {
    case 'UNASSIGNED':
      return '/assets/pin-task-unassigned.svg'
    case 'PENDING_RIDER_ASSIGNMENT':
      return '/assets/pin-task-assigned.svg'
    case 'ASSIGNED':
      return '/assets/pin-task-assigned.svg'
    case 'PICKED_UP':
      return '/assets/pin-task-stacked.svg'
    case 'DELIVERING':
    case 'RETURNING_TO_STORE':
      return '/assets/pin-task-delivering.svg'
    case 'DELIVERED':
      return '/assets/pin-task-delivered.svg'
    case 'FAILED':
    case 'RETURNED':
    case 'NOT_RETURNED':
      return '/assets/pin-task-failed.svg'
  }
}
