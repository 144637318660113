import { Task, TaskFailureReason, TaskStatus } from '@quickcommerceltd/zephyr-types'
import { useCallback, useMemo, useState } from 'react'
import toast from 'react-hot-toast'
import { completeTaskCallable } from '../../Auth/callables/task/completeTaskCallable'
import { logError } from '../../Common/helpers/logError'

interface Props {
  task: Task
  nextTaskStatus: TaskStatus
  failureReason?: TaskFailureReason
  failureNotes?: string
}

export function useCompleteTask() {
  const [isCompletingTask, setIsCompletingTask] = useState(false)

  const completeTask = useCallback(async (props: Props) => {
    const { task, nextTaskStatus, failureReason, failureNotes } = props

    try {
      setIsCompletingTask(true)

      await completeTaskCallable({
        taskId: task.id,
        taskStatus: nextTaskStatus,
        ...(failureReason && { failureReason }),
        ...(failureNotes && { failureNotes }),
      })
    } catch (error: any) {
      toast.error(error.message)
      logError(error)
    } finally {
      setIsCompletingTask(false)
    }
  }, [])

  return useMemo(
    () => ({
      completeTask,
      isCompletingTask,
    }),
    [completeTask, isCompletingTask]
  )
}
