import { Rider, Task } from '@quickcommerceltd/zephyr-types'
import { FC } from 'react'
import { FragmentWithDots } from '../../Common/components/FragmentWithDots'
import { RelativeTimestampView } from '../../Common/components/RelativeTimestampView'
import { getTaskStatusTranslation } from '../helpers/getTaskStatusTranslation'

interface Props {
  task: Task
  rider?: Rider
}

export const TaskDescriptionView: FC<Props> = (props) => {
  return (
    <FragmentWithDots
      items={[
        props.task.externalOrderNumber,
        props.task.status !== 'UNASSIGNED' && getTaskStatusTranslation(props.task),
        <RelativeTimestampView timestamp={props.task.createdAt} translation="Created {diff} ago" shouldBeInThePast />,
      ]}
    />
  )
}
