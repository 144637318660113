import { InfoOutlined } from '@mui/icons-material'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import LoadingButton from '@mui/lab/LoadingButton'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  Tab,
  Tooltip,
  Typography,
} from '@mui/material'
import { ThirdPartyProvider, Warehouse, WarehouseThirdPartyProviderConfigRecord } from '@quickcommerceltd/zephyr-types'
import { FC, useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useNavigate, useParams } from 'react-router-dom'
import { AppThemeProvider } from '../../App/components/AppThemeProvider'
import { updateWarehouseCallable } from '../../Auth/callables/warehouse/updateWarehouseCallable'
import { useHasPermission } from '../../Auth/hooks/useHasPermission'
import { ControlledSelect } from '../../Common/components/ControlledSelect'
import { ControlledSwitch } from '../../Common/components/ControlledSwitch'
import { THIRD_PARTY_PROVIDERS } from '../../Common/constants/THIRD_PARTY_PROVIDERS'
import { getThirdPartyProviderTranslation } from '../../Common/helpers/getThirdPartyProviderTranslation'
import { logError } from '../../Common/helpers/logError'
import { useThirdPartyProviders } from '../../Common/hooks/useThirdPartyProviders'
import { useWarehouses } from '../../Warehouse/hooks/useWarehouses'
import { getAreProvidersActiveTimesInvalid } from '../helpers/getAreProvidersActiveTimesInvalid'
import { WarehouseUpsertFormValues } from '../types/WarehouseUpsertFormValues'
import { SettingsWarehouseActiveTimesView } from './SettingsWarehouseActiveTimesView'
import { SettingsWarehousePreferredProviderView } from './SettingsWarehousePreferredProviderView'

const DEFAULT_AUTO_REASSIGNMENT_MINIMUM_THRESHOLD_IN_SECONDS = 120

export const SettingsWarehouseUpsertDialog: FC = () => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const hasPermission = useHasPermission()
  const navigate = useNavigate()
  const { warehouseId } = useParams<'warehouseId'>()
  const warehouse = useWarehouses((state) => state.warehouses.find((w) => w.id === warehouseId))
  const thirdPartyProviders = useThirdPartyProviders()
  const isNew = warehouseId === 'new'
  const defaultValues: WarehouseUpsertFormValues = {
    isAutoAssignmentEnabled: !!warehouse?.isAutoAssignmentEnabled,
    is3PAutoReassignmentEnabled: !!warehouse?.is3PAutoReassignmentEnabled,
    isRiderPackedNotificationEnabled: !!warehouse?.isRiderPackedNotificationEnabled,
    isStuartLevFleetEnabled: !!warehouse?.isStuartLevFleetEnabled,
    is1PAutoAssignmentEnabled: !!warehouse?.is1PAutoAssignmentEnabled,
    is1PAutoReassignmentEnabled: !!warehouse?.is1PAutoReassignmentEnabled,
    estimatedDeliveryTimeCoefficient: warehouse?.estimatedDeliveryTimeCoefficient || 1.0,
    warehouseThirdPartyProviderConfigs: THIRD_PARTY_PROVIDERS.reduce<WarehouseThirdPartyProviderConfigRecord>(
      (result, provider) => {
        const config = warehouse?.thirdPartyProviderConfigs?.[provider]

        result[provider] = {
          isTemporarilyDisabled: !!config?.isTemporarilyDisabled,
          autoReassignmentPickupEtaFactor: config?.autoReassignmentPickupEtaFactor || 1.5,
          autoReassignmentMinimumThresholdInSeconds: config?.autoReassignmentMinimumThresholdInSeconds || 240,
          activeTimes:
            config?.activeTimes?.map((activeTime) => ({
              ...activeTime,
              transportType: activeTime.transportType || 'BIKE',
            })) || [],
          defaultActiveTimes:
            config?.defaultActiveTimes?.map((activeTime) => ({
              ...activeTime,
              transportType: activeTime.transportType || 'BIKE',
            })) || [],
          defaultAutoReassignmentPickupEtaFactor: config?.defaultAutoReassignmentPickupEtaFactor || 1.5,
          defaultAutoReassignmentMinimumThresholdInSeconds:
            config?.defaultAutoReassignmentMinimumThresholdInSeconds || 240,
          defaultIsTemporarilyDisabled: !!config?.defaultIsTemporarilyDisabled,
        }

        return result
      },
      {}
    ),
    isPreferredThirdPartyProviderAssignmentEnabled: !!warehouse?.isPreferredThirdPartyProviderAssignmentEnabled,
    thirdPartyProviderPreferenceList: [
      ...(warehouse?.thirdPartyProviderPreferenceList ?? []),
      ...thirdPartyProviders.map((provider) => ({
        provider,
        autoReassignmentMinimumThresholdInSeconds: DEFAULT_AUTO_REASSIGNMENT_MINIMUM_THRESHOLD_IN_SECONDS,
      })),
    ].reduce<NonNullable<Warehouse['thirdPartyProviderPreferenceList']>>((result, preference) => {
      const existingPreference = result.find(({ provider }) => provider === preference.provider)
      if (!existingPreference) result.push(preference)
      return result
    }, []),
  }
  const [selectedProviderTab, setSelectedProviderTab] = useState<ThirdPartyProvider>('STUART')

  const { reset, control, handleSubmit, setValue, getValues, watch } = useForm<WarehouseUpsertFormValues>({
    defaultValues,
  })
  const thirdPartyProviderPreferenceList = watch('thirdPartyProviderPreferenceList')

  const closeDialog = useCallback(() => {
    navigate('/settings/stores')
    reset()
  }, [navigate, reset])

  const onSubmit = useCallback(
    async (formValues: WarehouseUpsertFormValues) => {
      try {
        if (getAreProvidersActiveTimesInvalid(thirdPartyProviders, formValues.warehouseThirdPartyProviderConfigs)) {
          toast.error("Time value 'To' should be greater than 'From'")
        } else {
          setIsSubmitting(true)
          if (warehouse) {
            await updateWarehouseCallable({
              warehouseId: warehouse.id,
              isAutoAssignmentEnabled: formValues.isAutoAssignmentEnabled,
              is3PAutoReassignmentEnabled: formValues.is3PAutoReassignmentEnabled,
              estimatedDeliveryTimeCoefficient: formValues.estimatedDeliveryTimeCoefficient,
              thirdPartyProviderConfigs: formValues.warehouseThirdPartyProviderConfigs || {},
              isPreferredThirdPartyProviderAssignmentEnabled: formValues.isPreferredThirdPartyProviderAssignmentEnabled,
              thirdPartyProviderPreferenceList: formValues.thirdPartyProviderPreferenceList || [],
              isRiderPackedNotificationEnabled: formValues.isRiderPackedNotificationEnabled,
              isStuartLevFleetEnabled: formValues.isStuartLevFleetEnabled,
              is1PAutoAssignmentEnabled: formValues.is1PAutoAssignmentEnabled,
              is1PAutoReassignmentEnabled: formValues.is1PAutoReassignmentEnabled,
            })
          }

          closeDialog()
        }
      } catch (error: any) {
        toast.error(error.message)
        logError(error)
      } finally {
        setIsSubmitting(false)
      }
    },
    [closeDialog, thirdPartyProviders, warehouse]
  )

  const isLoading = isSubmitting

  const etaMultiplierValues = [1.0, 1.1, 1.2, 1.3, 1.4, 1.5, 1.6, 1.7, 1.8, 1.9, 2.0].map((value) => ({
    value,
    label: `${Math.round(value * 100)}%`,
  }))

  const autoAssignmendInfo =
    'Toggles auto assignment of new tasks. If enabled, new tasks are auto assigned to either a 1P or a 3P rider. If disabled, new tasks must be assigned manually.'
  const autoReassignmentInfo =
    'Toggles auto reassignment of pending tasks. If enabled, pending tasks are auto unassigned and assigned again to a 3P rider, using the individual 3P settings below. Requires auto assignment.'
  const etaMultiplierInfo =
    'A coefficient that will be applied to the estimated delivery time of the order, which is used for the customer promise. For example, if the estimated delivery time is 30 minutes and the coefficient is 150%, the estimated delivery time will be 45 minutes.'
  const autoAssignmentModeInfo =
    'Toggles the 3P auto (re)assignment mode. If enabled, tasks are auto (re)assigned to 3P providers based on the provider priority config. If disabled, tasks are auto (re)assigned with the POETA mode.'
  const riderNotificationInfo =
    'If enabled, we will notify Stuart riders when orders are ready for pickup at this store.'
  const stuartLevFleetInfo =
    'If enabled, will use the LEV fleet for Stuart orders that would have used a bike and are where orders are less than XL size'

  const is1PAutoAssignmentInfo =
    'Toggles auto assignment of new tasks to 1P riders. If enabled, new tasks are auto assigned to a 1P rider using postcode delivery settings. If disabled, new tasks must be assigned manually to 1P riders.'

  const is1PAutoReassignmentEnabled =
    'Toggles auto reassignment of pending tasks to 1P riders. If enabled, it will eagerly un-assign postcode matching tasks from a 3P to a 1P rider. If disabled, no reassignment will happen.'

  return (
    <AppThemeProvider mode="light">
      <Dialog onClose={closeDialog} fullWidth open>
        <form>
          <Box my={2} mx={3} display="flex" justifyContent="space-between">
            <DialogTitle sx={{ p: 0, alignSelf: 'center' }}>
              {isNew ? 'Create Store' : `Edit Store: ${warehouse?.name} (${warehouse?.shortName})`}
            </DialogTitle>
          </Box>

          <Divider />

          <DialogContent>
            <Stack spacing={2}>
              <Stack>
                <Typography fontWeight="bold" fontSize={16}>
                  General Settings
                </Typography>
                <Stack direction="row" sx={{ alignItems: 'center' }}>
                  <ControlledSwitch
                    control={control}
                    disabled={isLoading}
                    label="Auto Assignment"
                    name="isAutoAssignmentEnabled"
                  />
                  <Tooltip title={autoAssignmendInfo}>
                    <IconButton sx={{ marginLeft: '-10px', alignItems: 'center' }}>
                      <InfoOutlined />
                    </IconButton>
                  </Tooltip>
                </Stack>

                {hasPermission('UPDATE_WAREHOUSE_THIRD_PARTY_PROVIDERS') && (
                  <Stack direction="row">
                    <ControlledSwitch
                      control={control}
                      disabled={isLoading || isSubmitting}
                      label="3P Auto Reassignment"
                      name="is3PAutoReassignmentEnabled"
                    />
                    <Tooltip title={autoReassignmentInfo}>
                      <IconButton sx={{ marginLeft: '-10px', alignItems: 'center' }}>
                        <InfoOutlined />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                )}

                {hasPermission('UPDATE_WAREHOUSE_THIRD_PARTY_PROVIDERS') && (
                  <Stack direction="row">
                    <ControlledSwitch
                      control={control}
                      disabled={isLoading || isSubmitting}
                      label="Send Notifications to Riders"
                      name="isRiderPackedNotificationEnabled"
                    />
                    <Tooltip title={riderNotificationInfo}>
                      <IconButton sx={{ marginLeft: '-10px', alignItems: 'center' }}>
                        <InfoOutlined />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                )}

                {hasPermission('UPDATE_WAREHOUSE_THIRD_PARTY_PROVIDERS') && (
                  <Stack direction="row">
                    <ControlledSwitch
                      control={control}
                      disabled={isLoading || isSubmitting}
                      label="Use Stuart LEV Fleet"
                      name="isStuartLevFleetEnabled"
                    />
                    <Tooltip title={stuartLevFleetInfo}>
                      <IconButton sx={{ marginLeft: '-10px', alignItems: 'center' }}>
                        <InfoOutlined />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                )}

                <Stack direction="row" sx={{ alignItems: 'center' }}>
                  <ControlledSwitch
                    control={control}
                    disabled={isLoading}
                    label="1P Auto Assignment"
                    name="is1PAutoAssignmentEnabled"
                  />
                  <Tooltip title={is1PAutoAssignmentInfo}>
                    <IconButton sx={{ marginLeft: '-10px', alignItems: 'center' }}>
                      <InfoOutlined />
                    </IconButton>
                  </Tooltip>
                </Stack>

                <Stack direction="row" sx={{ alignItems: 'center' }}>
                  <ControlledSwitch
                    control={control}
                    disabled={isLoading}
                    label="1P Auto Reassignment"
                    name="is1PAutoReassignmentEnabled"
                  />
                  <Tooltip title={is1PAutoReassignmentEnabled}>
                    <IconButton sx={{ marginLeft: '-10px', alignItems: 'center' }}>
                      <InfoOutlined />
                    </IconButton>
                  </Tooltip>
                </Stack>
              </Stack>

              {hasPermission('UPDATE_WAREHOUSE_THIRD_PARTY_PROVIDERS') && (
                <Stack>
                  <Typography fontWeight="bold" fontSize={16}>
                    Provider Priority
                  </Typography>
                  <Stack direction={'row'}>
                    <ControlledSwitch
                      control={control}
                      disabled={isLoading || isSubmitting}
                      label="Use Priority Auto Assignment Mode"
                      name="isPreferredThirdPartyProviderAssignmentEnabled"
                    />
                    <Tooltip title={autoAssignmentModeInfo}>
                      <IconButton sx={{ marginLeft: '-10px', alignItems: 'center' }}>
                        <InfoOutlined />
                      </IconButton>
                    </Tooltip>
                  </Stack>

                  <SettingsWarehousePreferredProviderView
                    preferenceList={thirdPartyProviderPreferenceList}
                    setValue={setValue}
                    disabled={isLoading || isSubmitting}
                  />
                </Stack>
              )}

              {hasPermission('UPDATE_WAREHOUSE_THIRD_PARTY_PROVIDERS') && (
                <Stack spacing={2}>
                  <Typography fontWeight="bold" fontSize={16}>
                    Delivery Promise Settings
                  </Typography>

                  <Stack direction="row" sx={{ gridGap: '10px', alignItems: 'center' }}>
                    <ControlledSelect
                      control={control}
                      disabled={isLoading}
                      items={etaMultiplierValues}
                      getValue={(k) => k.value || 1.0}
                      getName={(k) => k.label}
                      name={'estimatedDeliveryTimeCoefficient'}
                      label="Estimated Delivery Time Multiplier"
                      sx={{ width: '50%' }}
                    />
                    <Tooltip title={etaMultiplierInfo}>
                      <IconButton>
                        <InfoOutlined />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                </Stack>
              )}

              {hasPermission('UPDATE_WAREHOUSE_THIRD_PARTY_PROVIDERS') && (
                <TabContext value={selectedProviderTab}>
                  <TabList onChange={(_, value) => setSelectedProviderTab(value)}>
                    {thirdPartyProviders.map((provider) => (
                      <Tab key={provider} label={getThirdPartyProviderTranslation(provider)} value={provider} />
                    ))}
                  </TabList>

                  {thirdPartyProviders.map((provider) => (
                    <TabPanel key={provider} value={provider}>
                      <SettingsWarehouseActiveTimesView
                        provider={provider}
                        isLoading={isLoading}
                        control={control}
                        setValue={setValue}
                        getValues={getValues}
                        hasPermission={hasPermission('UPDATE_WAREHOUSE_THIRD_PARTY_PROVIDERS')}
                        warehouseId={warehouse?.id}
                        isSubmitting={isSubmitting}
                        watch={watch}
                      />
                    </TabPanel>
                  ))}
                </TabContext>
              )}
            </Stack>
          </DialogContent>

          <DialogActions
            sx={{
              position: 'sticky',
              bottom: 0,
              borderTop: `1px solid lightgrey`,
              background: 'white',
              zIndex: 1,
            }}
          >
            <Stack direction="row" spacing={1}>
              <LoadingButton variant="contained" loading={isLoading || isSubmitting} onClick={handleSubmit(onSubmit)}>
                {isNew ? 'Create Store' : 'Save Store'}
              </LoadingButton>

              <Button sx={{ mx: 2 }} onClick={closeDialog}>
                Cancel
              </Button>
            </Stack>
          </DialogActions>
        </form>
      </Dialog>
    </AppThemeProvider>
  )
}
