import LoadingButton from '@mui/lab/LoadingButton'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material'
import { Task, TaskCloneReason } from '@quickcommerceltd/zephyr-types'
import { FC, useState } from 'react'
import { AppThemeProvider } from '../../App/components/AppThemeProvider'
import { TASK_CLONE_REASONS } from '../constants/TASK_CLONE_REASONS'
import { getTaskCloneReasonTranslation } from '../helpers/getTaskCloneReasonTranslation'
import { useCloneTask } from '../hooks/useCloneTask'

interface Props {
  task: Task
  isOpen: boolean
  close: () => void
}

export const TaskCloneDialog: FC<Props> = (props) => {
  const { cloneTask, isCloningTask } = useCloneTask()
  const [cloneReason, setCloneReason] = useState<TaskCloneReason>(props.task.cloneReason || TASK_CLONE_REASONS[0])

  return (
    <AppThemeProvider mode="light">
      <Dialog open={props.isOpen} onClose={props.close} fullWidth>
        <DialogTitle>Clone Task</DialogTitle>
        <Divider />
        <DialogContent>
          <Box mt={2}>
            <FormControl fullWidth>
              <InputLabel>Clone Reason</InputLabel>
              <Select
                value={cloneReason}
                fullWidth
                label="Clone Reason"
                onChange={(event) => setCloneReason(event.target.value as TaskCloneReason)}
              >
                {TASK_CLONE_REASONS.map((reason) => (
                  <MenuItem key={reason} value={reason}>
                    {getTaskCloneReasonTranslation(reason)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </DialogContent>
        <Divider />
        <DialogActions>
          <LoadingButton
            autoFocus
            disabled={!cloneReason || (cloneReason as string) === 'NONE'}
            variant="contained"
            loading={isCloningTask}
            onClick={async () => {
              await cloneTask(props.task, cloneReason)
              props.close()
            }}
          >
            Clone
          </LoadingButton>
          <Button onClick={props.close}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </AppThemeProvider>
  )
}
