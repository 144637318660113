import LoadingButton from '@mui/lab/LoadingButton'
import { Menu, MenuItem } from '@mui/material'
import { Rider, Warehouse } from '@quickcommerceltd/zephyr-types'
import { FC, useState } from 'react'
import { useHasPermission } from '../../Auth/hooks/useHasPermission'
import { useWarehouses } from '../../Warehouse/hooks/useWarehouses'
import { isRiderWarehouseAssignable } from '../helpers/isRiderWarehouseAssignable'
import { RiderAssignWarehouseSelectDialog } from './RiderAssignWarehouseSelectDialog'

interface Props {
  rider: Rider
}

export const RiderAssignWarehouseSelect: FC<Props> = (props) => {
  const warehouses = useWarehouses((state) => state.warehouses)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [nextWarehouse, setNextWarehouse] = useState<Warehouse>()
  const hasPermission = useHasPermission()
  const isEnabled = hasPermission('UPDATE_RIDER_WAREHOUSE') && isRiderWarehouseAssignable(props.rider.status)
  const filteredWarehouses = warehouses.filter((w) => w.id !== props.rider.assignedWarehouseId)

  return (
    <>
      <LoadingButton disabled={!isEnabled} onClick={(event) => setAnchorEl(event.currentTarget)} size="small">
        {props.rider.assignedWarehouseId ? 'Reassign' : 'Assign'}
      </LoadingButton>
      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
      >
        {filteredWarehouses.map((w) => (
          <MenuItem
            key={w.id}
            onClick={() => {
              setAnchorEl(null)
              setNextWarehouse(w)
            }}
          >
            {w.name}
          </MenuItem>
        ))}
      </Menu>
      <RiderAssignWarehouseSelectDialog
        isOpen={!!nextWarehouse}
        rider={props.rider}
        nextWarehouse={nextWarehouse}
        close={() => setNextWarehouse(undefined)}
      />
    </>
  )
}
