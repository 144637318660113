import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'
import { getFunctions } from 'firebase/functions'
import { ENVIRONMENT } from '../../App/constants/ENVIRONMENT'

export const app = initializeApp(ENVIRONMENT.firebaseConfig)
export const auth = getAuth(app)
export const db = getFirestore(app)
export const functions = getFunctions(app, ENVIRONMENT.appRegion)
