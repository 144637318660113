import { Task } from '@quickcommerceltd/zephyr-types'
import { DateTime } from 'luxon'
import { PROMISED_DELIVERY_RANGE_IN_MINUTES } from '../constants/PROMISED_DELIVERY_RANGE_IN_MINUTES'

export function getPromisedDeliveryAt(task?: Task) {
  if (!task?.promisedDeliveryTimeInMinutes) return

  return DateTime.fromMillis(task.createdAt.toMillis()).plus({
    minutes: task.promisedDeliveryTimeInMinutes + PROMISED_DELIVERY_RANGE_IN_MINUTES,
  })
}
