import { Coords } from '../../Common/types/Coords'

export interface AutocompleteAddress {
  streetAddress1: string
  postalCode: string
  city: string
  country: string
  location: Coords
}

export function getAutocompleteAddressFromPlace(
  place: google.maps.places.PlaceResult | null,
  address1: string
): AutocompleteAddress | undefined {
  if (!place) return

  const getField = (type: string, isShort?: boolean): string | null => {
    const field = place.address_components?.find((f) => f.types.includes(type))

    if (field === undefined) {
      return null
    }

    return isShort ? field.short_name : field.long_name
  }

  const lat = place.geometry?.location?.lat()
  const lng = place.geometry?.location?.lng()

  if (!lat || !lng) return

  return {
    streetAddress1: address1,
    city: getField('locality') || getField('postal_town') || '',
    postalCode: getField('postal_code') || '',
    country: getField('country', true) || '',
    location: {
      latitude: lat,
      longitude: lng,
    },
  }
}
