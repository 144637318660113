import { Rider, Task, TaskStatus, Timestamp, Warehouse, ZephyrTypesConfig } from '@quickcommerceltd/zephyr-types'
import { getDocs, limit, orderBy, query, QueryConstraint, startAfter, where } from 'firebase/firestore'
import { DateTime } from 'luxon'
import { Dispatch, SetStateAction } from 'react'
import toast from 'react-hot-toast'
import { getTaskCollectionRef } from '../../Auth/helpers/getCollectionRef'
import { getTasksFromSnapshot } from '../../Auth/helpers/getDataFromQuerySnapshot'
import { exportCsv } from '../../Common/helpers/exportCsv'
import { logError } from '../../Common/helpers/logError'
import { getTaskCsvRows } from './getTaskCsvRows'

const LIMIT = 5000

interface Props {
  filterType: 'none' | 'warehouses' | 'riders' | 'taskId'
  taskStatuses: TaskStatus[]
  taskId: string
  riderIds: string[]
  warehouseIds: string[]
  from: Date
  to: Date
  allRiders: Rider[]
  allWarehouses: Warehouse[]
  setPage: Dispatch<SetStateAction<number>>
}

export async function exportTasks(props: Props) {
  const queryConstraints: QueryConstraint[] = []

  props.setPage(1)

  if (props.filterType === 'warehouses') {
    if (props.warehouseIds.length !== props.allWarehouses.length) {
      if (props.warehouseIds.length <= 10) {
        queryConstraints.push(where('warehouseId', 'in', props.warehouseIds))
      } else {
        throw new Error('Select up to 10 stores individually, or click "Clear Filters" for all stores.')
      }
    }
  }

  if (props.filterType === 'riders') {
    if (props.riderIds.length !== props.allRiders.length) {
      if (props.riderIds.length <= 10) {
        queryConstraints.push(where('id', 'in', props.riderIds))
      } else {
        throw new Error('Select up to 10 riders individually, or click "Clear Filters" for all riders.')
      }
    }
  }

  if (props.filterType === 'taskId') {
    queryConstraints.push(where('id', '==', props.taskId))
  }

  if (props.filterType !== 'taskId') {
    queryConstraints.push(where('createdAt', '>=', props.from), where('createdAt', '<', props.to))
  }

  const tasks: Task[] = []
  let lastCreatedAt: Timestamp | undefined

  do {
    try {
      const snapshot = await getDocs(
        query(
          getTaskCollectionRef(),
          ...queryConstraints,
          orderBy('createdAt', 'asc'),
          ...(lastCreatedAt ? [startAfter(lastCreatedAt)] : []),
          limit(LIMIT)
        )
      )

      ZephyrTypesConfig.logError = () => {}
      tasks.push(...getTasksFromSnapshot(snapshot))
      ZephyrTypesConfig.logError = logError

      lastCreatedAt = snapshot.docs.length ? snapshot.docs[snapshot.docs.length - 1].data().createdAt : undefined

      if (lastCreatedAt) {
        props.setPage((page) => page + 1)
        await new Promise((resolve) => setTimeout(resolve, 100))
      }
    } catch (error: any) {
      // Firestore randomly crashes because of too many requests.
      // We wait 5 seconds and try again.
      await new Promise((resolve) => setTimeout(resolve, 5000))
    }
  } while (lastCreatedAt)

  const filteredTasks = tasks.filter((task) => {
    if (!task.status) return false
    if (props.filterType !== 'taskId') {
      return props.taskStatuses.includes(task.status)
    }

    return true
  })

  const csvRows = getTaskCsvRows(filteredTasks, props.allRiders)

  exportCsv(csvRows, `Zephyr-export-${DateTime.utc().toFormat('yyyy-MM-dd-hh-mm-ss')}.csv`)

  toast.success(`You successfully downloaded ${csvRows.length - 1} tasks.`)
}
