import MopedOutlinedIcon from '@mui/icons-material/MopedOutlined'
import SupportAgentIcon from '@mui/icons-material/SupportAgent'
import WarehouseOutlinedIcon from '@mui/icons-material/WarehouseOutlined'
import { Box, List, Paper } from '@mui/material'
import { FC } from 'react'
import { Outlet } from 'react-router-dom'
import { AppThemeProvider } from '../../App/components/AppThemeProvider'
import { APP_HEADER_HEIGHT } from '../../App/constants/APP_HEADER_HEIGHT'
import { COLOR } from '../../App/constants/COLOR'
import { SettingsNavLink } from './SettingsNavLink'

export const SettingsMainView: FC = () => {
  return (
    <AppThemeProvider mode="light">
      <Paper sx={{ display: 'flex', flex: 1 }} square>
        <Box width={242} py={2} pl={2}>
          <List>
            <SettingsNavLink to="/settings/riders" title="Riders" iconComponent={MopedOutlinedIcon} />
            <SettingsNavLink to="/settings/users" title="Users" iconComponent={SupportAgentIcon} />
            <SettingsNavLink to="/settings/stores" title="Stores" iconComponent={WarehouseOutlinedIcon} />
          </List>
        </Box>
        <Box
          bgcolor={COLOR.blue[10]}
          flex={1}
          p={4}
          display="flex"
          overflow="auto"
          height={`calc(100vh - ${APP_HEADER_HEIGHT}px)`}
        >
          <Outlet />
        </Box>
      </Paper>
    </AppThemeProvider>
  )
}
