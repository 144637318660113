import { Task } from '@quickcommerceltd/zephyr-types'
import { getTaskStatusTranslation } from '../../Task/helpers/getTaskStatusTranslation'
import { useRiders } from '../hooks/useRiders'

export interface Validation {
  isValid: boolean
  error?: Error
}

export function validateUnassignTaskFromRider(task?: Task | null): Validation {
  try {
    if (!task) {
      throw Error('Missing task.')
    }

    const previousRider = useRiders.getState().riders.find((r) => r.id === task.riderId)

    // Unassign from 1P rider
    if (!task.thirdPartyProvider) {
      if (!previousRider) {
        throw Error('Can not find assigned rider.')
      }

      if (task.status !== 'ASSIGNED') {
        throw Error('You cannot unassign a task once it has been picked up.')
      }
    }

    // Unassign from 3P rider
    if (task.thirdPartyProvider) {
      if (task.status === 'ASSIGNED' && previousRider?.status !== 'PICKING_UP') {
        throw Error('You cannot unassign 3P tasks if the rider has already arrived at the store.')
      }

      if (!['PENDING_RIDER_ASSIGNMENT', 'ASSIGNED'].includes(task.status)) {
        throw Error(`You cannot unassign "${getTaskStatusTranslation(task)}" 3P tasks.`)
      }

      // TODO: Uncomment/delete this when we have decided whether we want to go with temper 1P riders
      //
      // if (
      //   task.isAutoAssigned &&
      //   task.estimatedArrivalAtStoreAt &&
      //   task.estimatedArrivalAtStoreAt.toMillis() > nowMillis
      // ) {
      //   const estimatedPickUpAt = task.estimatedArrivalAtStoreAt.toMillis();
      //   const diff = Math.ceil((estimatedPickUpAt - nowMillis) / 1000);
      //   throw Error(
      //     `This task was auto-assigned. You can manually unassign it in ${diff} seconds.`
      //   );
      // }
    }

    return { isValid: true }
  } catch (error: any) {
    return { isValid: false, error }
  }
}
