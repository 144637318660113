import { PickingStatus } from '@quickcommerceltd/zephyr-types'

export function getTaskPickingStatusTranslation(pickingStatus?: PickingStatus) {
  switch (pickingStatus) {
    case 'PACKED':
      return 'Packed'
    case 'PICKING':
      return 'Picking'
    case 'PICKED':
      return 'Picked'
    default:
      return 'Picker not started'
  }
}
