import { Polyline } from '@react-google-maps/api'
import { FC, useMemo } from 'react'
import { COLOR } from '../../App/constants/COLOR'
import { getSecondsFromTimestamp } from '../../Common/helpers/getSecondsFromTimestamp'
import { useRiderFromRiderId } from '../../Rider/hooks/useRiderFromRiderId'
import { useRiderHistory } from '../../Rider/hooks/useRiderHistory'
import { toLatLngLiteral } from '../helpers/toLatLngLiteral'
import { MapRiderMarker } from './MapRiderMarker'

interface Props {
  riderId: string
}

export const MapRiderHistoryView: FC<Props> = (props) => {
  const [rider] = useRiderFromRiderId(props.riderId)
  const { minute, logs, isLoading } = useRiderHistory()
  const second = minute * 60
  const log = useMemo(() => logs.find((log) => getSecondsFromTimestamp(log.createdAt) <= second), [logs, second])

  return (
    <>
      {!!rider && !!log && (
        <MapRiderMarker
          key={log.createdAt.toMillis()}
          location={log.update}
          rider={{
            ...rider,
            ...(log.update.riderStatus && { status: log.update.riderStatus }),
          }}
        />
      )}
      {!isLoading && (
        <Polyline
          key={props.riderId}
          path={logs.map((log) => toLatLngLiteral(log.update))}
          options={{
            strokeColor: COLOR.blue[500],
            strokeWeight: 2,
          }}
        />
      )}
    </>
  )
}
