import { Task } from '@quickcommerceltd/zephyr-types'
import { DateTime } from 'luxon'
import { getPromisedDeliveryAt } from '../helpers/getPromisedDeliveryAt'

interface TaskDeliveryTime {
  translation: string
  dateTime: DateTime
}

export function useTaskDeliveryTimes(task?: Task, estimatedDeliveryAt?: DateTime) {
  const deliveryTimes: TaskDeliveryTime[] = []
  const promisedDeliveryAt = getPromisedDeliveryAt(task)

  if (task) {
    deliveryTimes.push({
      translation: 'Created',
      dateTime: DateTime.fromMillis(task.createdAt.toMillis()),
    })
  }

  if (promisedDeliveryAt) {
    deliveryTimes.push({
      translation: 'Promised',
      dateTime: promisedDeliveryAt,
    })
  }

  if (task?.completedAt) {
    deliveryTimes.push({
      translation: task.failureReason ? 'Failed' : 'Delivered',
      dateTime: DateTime.fromMillis(task.completedAt.toMillis()),
    })
  } else if (estimatedDeliveryAt) {
    deliveryTimes.push({
      translation: 'Estimated',
      dateTime: estimatedDeliveryAt,
    })
  }

  return deliveryTimes
}
