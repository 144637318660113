import { User } from '@quickcommerceltd/zephyr-types'
import { FirestoreError } from 'firebase/firestore'
import { useMemo } from 'react'
import { useCollection } from 'react-firebase-hooks/firestore'
import { getUserCollectionRef } from '../../Auth/helpers/getCollectionRef'
import { getUsersFromSnapshot } from '../../Auth/helpers/getDataFromQuerySnapshot'

export function useUsers(): [User[], boolean, FirestoreError | undefined] {
  const [snapshot, isLoading, error] = useCollection(getUserCollectionRef())
  const users = useMemo(() => getUsersFromSnapshot(snapshot).sort((a, b) => a.name.localeCompare(b.name)), [snapshot])

  return [users, isLoading, error]
}
