import { FC } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { RiderDetailsView } from '../../Rider/components/RiderDetailsView'
import { RiderHistoryView } from '../../Rider/components/RiderHistoryView'
import { SettingsMainView } from '../../Settings/components/SettingsMainView'
import { SettingsRidersView } from '../../Settings/components/SettingsRidersView'
import { SettingsRiderUpsertDialog } from '../../Settings/components/SettingsRiderUpsertDialog'
import { SettingsUsersView } from '../../Settings/components/SettingsUsersView'
import { SettingsWarehousesView } from '../../Settings/components/SettingsWarehousesView'
import { SettingsWarehouseUpsertDialog } from '../../Settings/components/SettingsWarehouseUpsertDialog'
import { TaskDetailsView } from '../../Task/components/TaskDetailsView'
import { WarehouseLogsDialog } from '../../Warehouse/components/WarehouseLogsDialog'
import { App404View } from './App404View'
import { AppDashboardView } from './AppDashboardView'
import { R } from './AppRoute'

export const AppRouter: FC = () => {
  return (
    <Routes>
      <Route path="settings" element={<SettingsMainView />}>
        <Route index element={<Navigate replace to="riders" />} />
        <Route path="riders" element={<SettingsRidersView />}>
          <Route path=":riderId" element={<SettingsRiderUpsertDialog />} />
        </Route>
        <Route path="users" element={<SettingsUsersView />} />
        <Route path="stores" element={<SettingsWarehousesView />}>
          <Route path=":warehouseId">
            <Route index element={<SettingsWarehouseUpsertDialog />} />
            <Route path="logs" element={<WarehouseLogsDialog />} />
          </Route>
        </Route>
      </Route>
      <Route path="/" element={<AppDashboardView />}>
        <Route path="tasks/:taskId" element={<R l={1} e={<TaskDetailsView />} />}>
          <Route path="riders/:riderId" element={<R l={2} e={<RiderDetailsView />} />} />
        </Route>
        <Route path="riders/:riderId" element={<R l={1} e={<RiderDetailsView />} />}>
          <Route path="history" element={<RiderHistoryView />} />
          <Route path="tasks/:taskId" element={<R l={2} e={<TaskDetailsView />} />} />
        </Route>
      </Route>
      <Route path="*" element={<App404View />} />
    </Routes>
  )
}
