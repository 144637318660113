import { RiderStatus, TaskStatus } from '@quickcommerceltd/zephyr-types'
import create from 'zustand'

const LOCAL_STORAGE_KEY = 'ZEPHYR'

export const DEFAULT_LOCAL_STORAGE: LocalStorage = {
  filteredRiderStatuses: [
    'PICKING_UP',
    'IDLE_AT_STORE',
    'WAITING_FOR_PACKED_ORDER',
    'DELIVERING',
    'IDLE_AT_CUSTOMER',
    'RIDING_BACK_TO_STORE',
  ],
  filteredTaskStatuses: ['UNASSIGNED', 'PENDING_RIDER_ASSIGNMENT', 'ASSIGNED', 'DELIVERING', 'PICKED_UP'],
}

interface LocalStorage {
  selectedWarehouseId?: string
  filteredTaskStatuses?: TaskStatus[]
  filteredRiderStatuses?: RiderStatus[]
}

interface LocalStorageStore {
  data: Partial<LocalStorage>
  set: (next: Partial<LocalStorage>) => void
  initialize: () => void
}

export const useLocalStorage = create<LocalStorageStore>((set, get) => ({
  data: {},
  set: (next) => {
    const data = {
      ...get().data,
      ...next,
    }

    window.localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(data))
    set({ data })
  },
  initialize: () => {
    const dataJson = window.localStorage.getItem(LOCAL_STORAGE_KEY)
    const data: LocalStorage = dataJson ? JSON.parse(dataJson) : DEFAULT_LOCAL_STORAGE

    set({ data })
  },
}))

useLocalStorage.getState().initialize()
