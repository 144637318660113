import { Task } from '@quickcommerceltd/zephyr-types'
import { useDrag } from 'react-dnd'
import toast from 'react-hot-toast'
import { useHasPermission } from '../../Auth/hooks/useHasPermission'
import { DragCollect } from '../../Sidebar/types/DragCollect'
import { DragItem } from '../../Sidebar/types/DragItem'
import { DropResult } from '../../Sidebar/types/DropResult'
import { useAssignTask } from './useAssignTask'
import { useAssignTaskToThirdPartyProvider } from './useAssignTaskToThirdPartyProvider'
import { useUnassignTask } from './useUnassignTask'

export function useDragTask(task: Task) {
  const [assignTask, isAssigningTask] = useAssignTask()
  const [assignTaskToThirdPartyProvider, isAssigningTaskToThirdPartyProvider] = useAssignTaskToThirdPartyProvider()
  const [unassignTask, isUnassigningTask] = useUnassignTask()
  const hasPermission = useHasPermission()
  const hasUpdateTaskRiderPermission = hasPermission('UPDATE_TASK_RIDER')
  const isLoading =
    isAssigningTask ||
    isAssigningTaskToThirdPartyProvider ||
    isUnassigningTask ||
    !!task.isRunningThirdPartyProviderAction

  return useDrag<DragItem, DropResult, DragCollect>(
    () => ({
      type:
        isLoading || !hasUpdateTaskRiderPermission
          ? 'UNDROPPABLE_TASK'
          : task.status === 'UNASSIGNED'
            ? 'UNASSIGNED_TASK'
            : 'ASSIGNED_TASK',
      item: task,
      end: async (_, monitor) => {
        const result = monitor.getDropResult()

        if (result?.error) {
          return toast.error(result.error.message)
        } else if (result?.thirdPartyProvider) {
          await assignTaskToThirdPartyProvider(task, result.thirdPartyProvider)
        } else if (result?.rider) {
          await assignTask(task, result.rider)
        } else if (result?.isTaskUnassign) {
          await unassignTask(task)
        }
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
        isLoading,
      }),
    }),
    [task, assignTask, assignTaskToThirdPartyProvider, unassignTask, hasUpdateTaskRiderPermission, isLoading]
  )
}
