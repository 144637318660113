import LoadingButton from '@mui/lab/LoadingButton'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider } from '@mui/material'
import { Rider } from '@quickcommerceltd/zephyr-types'
import { FC } from 'react'
import { AppThemeProvider } from '../../App/components/AppThemeProvider'
import { useDeleteRider } from '../hooks/useDeleteRider'

interface Props {
  rider?: Rider
  isOpen: boolean
  close: () => void
}

export const SettingsRiderDeleteDialog: FC<Props> = (props) => {
  const [deleteRider, isDeletingRider] = useDeleteRider()

  return (
    <AppThemeProvider mode="light">
      <Dialog open={props.isOpen} onClose={props.close} fullWidth>
        <DialogTitle>Delete Rider</DialogTitle>
        <Divider />
        <DialogContent>
          {props.rider?.status === 'OFF_DUTY' ? (
            <DialogContentText>
              Are you sure you want to delete the rider <strong>{props.rider?.name}</strong>?
            </DialogContentText>
          ) : (
            <DialogContentText>
              Rider <strong>{props.rider?.name}</strong> can only be deleted when off duty.
            </DialogContentText>
          )}
        </DialogContent>
        <Divider />
        <DialogActions>
          <LoadingButton
            color="error"
            variant="contained"
            disabled={isDeletingRider || props.rider?.status !== 'OFF_DUTY'}
            loading={isDeletingRider}
            onClick={async () => {
              if (!props.rider) return
              await deleteRider(props.rider)
              props.close()
            }}
          >
            Delete
          </LoadingButton>
          <Button onClick={props.close} autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </AppThemeProvider>
  )
}
