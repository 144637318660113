import { GoogleMap, Marker, Polygon } from '@react-google-maps/api'
import { FC, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { COLOR } from '../../App/constants/COLOR'
import { useFilteredRiders } from '../../Rider/hooks/useFilteredRiders'
import { useRiderHistory } from '../../Rider/hooks/useRiderHistory'
import { useFilteredTasks } from '../../Task/hooks/useFilteredTasks'
import { useSelectedWarehouse } from '../../Warehouse/hooks/useSelectedWarehouse'
import { MAP_STYLES } from '../constans/MAP_STYLES'
import { toLatLng } from '../helpers/toLatLng'
import { toLatLngLiteral } from '../helpers/toLatLngLiteral'
import { MapRiderHistoryView } from './MapRiderHistoryView'
import { MapRiderMarker } from './MapRiderMarker'
import { MapTaskMarker } from './MapTaskMarker'

export const MapView: FC = () => {
  const riders = useFilteredRiders()
  const tasks = useFilteredTasks()
  const navigate = useNavigate()
  const selectedWarehouse = useSelectedWarehouse()
  const [isLoaded, setIsLoaded] = useState(false)
  const historyRiderId = useRiderHistory((state) => state.riderId)
  const center = useMemo(() => selectedWarehouse && toLatLngLiteral(selectedWarehouse?.address), [selectedWarehouse])

  if (!selectedWarehouse) return null

  return (
    <GoogleMap
      mapContainerStyle={{ flex: 1 }}
      options={{
        fullscreenControl: false,
        mapTypeControl: false,
        styles: MAP_STYLES,
      }}
      center={center}
      zoom={14}
      onLoad={() => {
        // If there is no `setTimeout`, then it will not render the initial markers
        setTimeout(() => setIsLoaded(true))
      }}
    >
      {isLoaded && (
        <>
          {selectedWarehouse.polygonPoints && (
            <Polygon
              path={selectedWarehouse.polygonPoints.map(toLatLngLiteral)}
              options={{
                fillColor: 'transparent',
                strokeColor: COLOR.white[0],
                strokeWeight: 2,
              }}
            />
          )}
          <Marker
            zIndex={1}
            key={selectedWarehouse.id}
            position={toLatLng(selectedWarehouse.address)}
            onClick={() => navigate(`/`)}
            icon={{
              url: '/assets/pin-warehouse.svg',
              scaledSize: new google.maps.Size(36, 36),
            }}
          />
          {!historyRiderId && tasks.map((task) => <MapTaskMarker key={task.id} task={task} />)}
          {!historyRiderId &&
            riders.map(
              (rider) => rider.coords && <MapRiderMarker key={rider.id} rider={rider} location={rider.coords} />
            )}
          {historyRiderId && <MapRiderHistoryView riderId={historyRiderId} />}
        </>
      )}
    </GoogleMap>
  )
}
