import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { Box, IconButton, Slider, TextField } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import { DateTime } from 'luxon'
import { FC, useCallback, useEffect, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { COLOR } from '../../App/constants/COLOR'
import { EscapeKeyToClose } from '../../Common/components/EscapeKeyToClose'
import { LoadingView } from '../../Common/components/LoadingView'
import { getMinutesFromTimestamp } from '../../Common/helpers/getMinutesFromTimestamp'
import { SIDEBAR_WIDTH } from '../../Sidebar/constants/SIDEBAR_WIDTH'
import { useRiderHistory } from '../hooks/useRiderHistory'

const DATE_TIME_FORMAT = 'dd MMM yyyy'
const WIDTH = `calc(100vw - ${SIDEBAR_WIDTH}px)`

export const RiderHistoryView: FC = () => {
  const params = useParams<'riderId'>()
  const navigate = useNavigate()
  const { date, minute, setDate, setMinute, setRiderId, logs, isLoading } = useRiderHistory()
  const close = useCallback(
    () => navigate(params.riderId ? `/riders/${params.riderId}` : '/'),
    [navigate, params.riderId]
  )

  useEffect(() => {
    const now = DateTime.local()

    setRiderId(params.riderId)
    setMinute(now.minute + now.hour * 60)

    return () => setRiderId(undefined)
  }, [params.riderId, setMinute, setRiderId])

  const marks = useMemo(() => {
    let prevTaskId = logs[0]?.update.taskId

    return logs
      .reduce<{ value: number }[]>((result, log, index) => {
        const hasChanged = log.update.taskId && log.update.taskId !== prevTaskId
        if (hasChanged || index === 0 || index === logs.length - 1) {
          prevTaskId = log.update.taskId
          const value = getMinutesFromTimestamp(log.createdAt)

          if (value !== result[result.length - 1]?.value) {
            result.push({ value })
          }
        }

        return result
      }, [])
      .sort((a, b) => a.value - b.value)
  }, [logs])

  const minMinute = marks[0]?.value || 0
  const maxMinute = marks[marks.length - 1]?.value || 0

  return (
    <Box
      position="absolute"
      flexDirection="row"
      display="flex"
      alignItems="center"
      left={SIDEBAR_WIDTH}
      width={WIDTH}
      bgcolor={COLOR.rock[500]}
      top={0}
      p={2}
    >
      <EscapeKeyToClose close={close} />
      <DatePicker
        inputFormat={DATE_TIME_FORMAT}
        onChange={(value) => setDate(value ? value : DateTime.local())}
        value={date}
        maxDate={DateTime.local()}
        renderInput={(inputProps) => <TextField {...inputProps} sx={{ width: 150 }} />}
      />
      {isLoading && (
        <Box display="flex" flex={1} justifyContent="center">
          <LoadingView />
        </Box>
      )}
      {!isLoading && (
        <Slider
          sx={{ mx: 4, flex: 1 }}
          value={logs.length ? minute : 0}
          onChange={(_, value) => typeof value === 'number' && setMinute(value)}
          valueLabelDisplay="auto"
          marks={marks}
          min={minMinute}
          max={maxMinute}
          step={null}
          valueLabelFormat={(value) =>
            DateTime.local()
              .set({
                minute: value % 60,
                hour: Math.floor(value / 60),
              })
              .toLocaleString(DateTime.TIME_24_SIMPLE)
          }
        />
      )}
      <IconButton
        color="inherit"
        size="large"
        disabled={isLoading || minute <= minMinute}
        onClick={() => setMinute(minute - 1)}
      >
        <KeyboardArrowLeftIcon />
      </IconButton>
      <IconButton
        color="inherit"
        size="large"
        disabled={isLoading || minute >= maxMinute}
        onClick={() => setMinute(minute + 1)}
      >
        <KeyboardArrowRightIcon />
      </IconButton>
      <IconButton color="inherit" size="large" onClick={close}>
        <CloseOutlinedIcon />
      </IconButton>
    </Box>
  )
}
