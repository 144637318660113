import { Task } from '@quickcommerceltd/zephyr-types'
import { useEffect, useRef } from 'react'
import { useMap } from '../../Map/hooks/useMap'

export function useHoverTask(task?: Task) {
  const unmountRef = useRef<() => void>()
  const setFocusedRiderId = useMap((store) => store.setFocusedRiderId)
  const setFocusedTaskIds = useMap((store) => store.setFocusedTaskIds)
  const removeFocusedTaskIds = useMap((store) => store.removeFocusedTaskIds)
  const removeFocusedRiderId = useMap((store) => store.removeFocusedRiderId)

  useEffect(() => {
    return () => {
      unmountRef.current?.()
      unmountRef.current = undefined
    }
  }, [])

  const onMouseOver = () => {
    if (!task) return

    const riderId =
      ['ASSIGNED', 'PICKED_UP', 'DELIVERING'].includes(task.status) && task.riderId ? task.riderId : 'NONE'

    setFocusedTaskIds([task.id])
    setFocusedRiderId(riderId)
    unmountRef.current = () => {
      removeFocusedTaskIds()
      removeFocusedRiderId()
    }
  }

  const onMouseOut = () => {
    unmountRef.current?.()
    unmountRef.current = undefined
  }

  return { onMouseOver, onMouseOut }
}
