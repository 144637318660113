import LoadingButton from '@mui/lab/LoadingButton'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Typography } from '@mui/material'
import { Rider, Warehouse } from '@quickcommerceltd/zephyr-types'
import { FC, useCallback } from 'react'
import toast from 'react-hot-toast'
import { AppThemeProvider } from '../../App/components/AppThemeProvider'
import { logError } from '../../Common/helpers/logError'
import { useWarehouseFromWarehouseId } from '../../Warehouse/hooks/useWarehouseFromWarehouseId'
import { useAssignRiderWarehouse } from '../hooks/useAssignRiderWarehouse'

interface Props {
  isOpen: boolean
  rider: Rider
  nextWarehouse?: Warehouse
  close: () => void
}

export const RiderAssignWarehouseSelectDialog: FC<Props> = (props) => {
  const [assignRiderWarehouse, isAssigningRiderWarehouse] = useAssignRiderWarehouse()
  const previousWarehouse = useWarehouseFromWarehouseId(props.rider.assignedWarehouseId)

  const onClick = useCallback(async () => {
    try {
      if (!props.nextWarehouse) return
      await assignRiderWarehouse(props.rider, props.nextWarehouse)

      props.close()
    } catch (error: any) {
      toast.error(error.message)
      logError(error)
    }
  }, [assignRiderWarehouse, props])

  if (!props.nextWarehouse) return null

  return (
    <AppThemeProvider mode="light">
      <Dialog open={props.isOpen} onClose={props.close} fullWidth>
        <DialogTitle>{previousWarehouse ? 'Reassign Rider' : 'Assign Rider'}</DialogTitle>
        <Divider />
        <DialogContent>
          <Typography>
            Are you sure you want to assign the rider from <strong>{previousWarehouse?.name || 'unassigned'}</strong> to{' '}
            <strong>{props.nextWarehouse?.name || 'none'}</strong>?
          </Typography>
        </DialogContent>
        <Divider />
        <DialogActions>
          <LoadingButton autoFocus variant="contained" loading={isAssigningRiderWarehouse} onClick={onClick}>
            {previousWarehouse ? 'Reassign' : 'Assign'}
          </LoadingButton>
          <Button onClick={props.close}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </AppThemeProvider>
  )
}
