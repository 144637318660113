import { Task, TaskStatus } from '@quickcommerceltd/zephyr-types'

export function getTaskStatusTranslation(task: Partial<Task> & { status: TaskStatus }): string {
  switch (task.status) {
    case 'PENDING_RIDER_ASSIGNMENT':
      return 'Pending'
    case 'ASSIGNED':
      return 'Assigned'
    case 'PICKED_UP':
      return 'Stacked'
    case 'DELIVERING':
      return task.hasArrivedAtCustomer ? 'Handing Over' : 'In Delivery'
    case 'DELIVERED':
      return 'Successful'
    case 'FAILED':
      return 'Failed'
    case 'UNASSIGNED':
      return 'Unassigned'
    case 'RETURNING_TO_STORE':
      return 'Returning'
    case 'RETURNED':
      return 'Returned'
    case 'NOT_RETURNED':
      return 'Not Returned'
  }
}
