import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import { Alert, Box, CircularProgress, List } from '@mui/material'
import { CollectionReference, limit, orderBy, query, QuerySnapshot } from 'firebase/firestore'
import { FC, useCallback, useMemo, useState } from 'react'
import { useCollection } from 'react-firebase-hooks/firestore'
import { COLOR } from '../../App/constants/COLOR'
import { SidebarEmptyListItem } from '../../Sidebar/components/SidebarEmptyListItem'
import { Log } from '../types/Log'
import { LogDialog } from './LogDialog'

interface Props<T extends Log> {
  collectionRef: CollectionReference<T>
  getLogsFromSnapshot: (snapshot?: QuerySnapshot<T>) => T[]
  limit: number
  LogListItem: FC<{
    log: T
    isFirstItem: boolean
    isLastItem: boolean
    onClick: () => void
  }>
}

const STATUS_ORDER: Record<string, number> = {
  UNKNOWN: 0,
  PICKED_UP: 0,
  DELIVERING: 1,
  DELIVERED: 2,
}

export function LogListView<T extends Log>(props: Props<T>) {
  const [selectedLog, setSelectedLog] = useState<T>()
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [snapshot, isLoading, error] = useCollection(
    query(props.collectionRef, orderBy('createdAt', 'desc'), limit(props.limit))
  )

  const logs = useMemo(
    () =>
      props.getLogsFromSnapshot(snapshot).sort((a, b) => {
        if (a.createdAt.toMillis() === b.createdAt.toMillis()) {
          return STATUS_ORDER[b.update.status ?? 'UNKNOWN'] - STATUS_ORDER[a.update.status ?? 'UNKNOWN']
        }
        return b.createdAt.toMillis() - a.createdAt.toMillis()
      }),
    [props, snapshot]
  )

  const onClick = useCallback((log: T) => {
    setSelectedLog(log)
    setIsDialogOpen(true)
  }, [])

  const isLoaded = !isLoading && !error

  return (
    <>
      {isLoading && (
        <Box p={2} display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      )}
      {error && (
        <Box p={2}>
          <Alert severity="error">{error.message}</Alert>
        </Box>
      )}
      {isLoaded && (
        <List>
          {logs.length === 0 && (
            <SidebarEmptyListItem message="No logs" iconComponent={CancelOutlinedIcon} color={COLOR.red[500]} />
          )}
          {logs.map((log, index) => (
            <props.LogListItem
              key={`${log.createdAt.toMillis()}-${index}`}
              log={log}
              isFirstItem={index === 0}
              isLastItem={index === logs.length - 1}
              onClick={() => onClick(log)}
            />
          ))}
        </List>
      )}
      <LogDialog log={selectedLog} isOpen={isDialogOpen} close={() => setIsDialogOpen(false)} />
    </>
  )
}
