import { alpha, Box } from '@mui/material'
import { FC, ReactNode } from 'react'
import { COLOR } from '../../App/constants/COLOR'
import { useDropToUnassignTask } from '../../Task/hooks/useDropToUnassignTask'

interface Props {
  children: ReactNode
}

export const SidebarTaskUnassignDropArea: FC<Props> = (props) => {
  const [{ isOver, canDrop, error }, drop] = useDropToUnassignTask()

  return (
    <Box
      ref={drop}
      py={1}
      my={-1}
      bgcolor={isOver && canDrop ? alpha(error ? COLOR.red[500] : COLOR.green[500], 0.5) : 'transparent'}
    >
      {props.children}
    </Box>
  )
}
