import { Task, TaskStatus } from '@quickcommerceltd/zephyr-types'

const ASSIGNED_AND_IN_TRANSIT_STATUSES: TaskStatus[] = ['ASSIGNED', 'PICKED_UP', 'DELIVERING', 'RETURNING_TO_STORE']

export function isTaskOnRidersCurrentJourney(task: Task, riderId?: string) {
  if (!riderId) return false
  if (riderId !== task.riderId) return false

  return ASSIGNED_AND_IN_TRANSIT_STATUSES.includes(task.status)
}
