import { Box, Paper } from '@mui/material'
import { FC, ReactNode } from 'react'
import { COLOR } from '../../App/constants/COLOR'

interface Props {
  children: ReactNode
}

export const DataGridPaper: FC<Props> = (props) => {
  return (
    <Box
      mt={5}
      component={Paper}
      flex={1}
      sx={{
        '& .MuiDataGrid-columnHeaders': {
          borderBottom: `1px solid ${COLOR.blue[900]}`,
        },
        '& .MuiDataGrid-columnHeader': {
          '&:focus, &:focus-within': {
            outline: 'none !important',
          },
        },
        '& .MuiDataGrid-columnSeparator': {
          display: 'none !important',
        },
        '& .MuiDataGrid-footerContainer': {
          borderTop: `1px solid ${COLOR.blue[900]}`,
        },
        '& .MuiDataGrid-columnHeaderTitle': {
          fontSize: 16,
          fontWeight: 700,
        },
        '& .MuiDataGrid-row': {
          backgroundColor: 'transparent',
          '& .row-action': {
            visibility: 'hidden',
          },
          '&:hover': {
            backgroundColor: `${COLOR.blue[50]} !important`,
            '& .row-action': {
              visibility: 'visible',
            },
          },
        },
        '& .MuiDataGrid-cell': {
          borderBottom: `1px solid ${COLOR.gray[50]}`,
          '&:focus, &:focus-within': {
            outline: 'none !important',
          },
        },
      }}
    >
      {props.children}
    </Box>
  )
}
