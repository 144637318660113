import HistoryIcon from '@mui/icons-material/History'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import MopedOutlinedIcon from '@mui/icons-material/MopedOutlined'
import PhoneIphoneOutlinedIcon from '@mui/icons-material/PhoneIphoneOutlined'
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import WarehouseOutlinedIcon from '@mui/icons-material/WarehouseOutlined'
import { Box, CircularProgress, IconButton, List, Typography } from '@mui/material'
import { FC } from 'react'
import { Link, useParams } from 'react-router-dom'
import { COLOR } from '../../App/constants/COLOR'
import { getRiderLogCollectionRef } from '../../Auth/helpers/getCollectionRef'
import { getRiderLogsFromSnapshot } from '../../Auth/helpers/getDataFromQuerySnapshot'
import { PlatformOsIcon } from '../../Common/components/PlatformOsIcon'
import { ZAccordion } from '../../Common/components/ZAccordion'
import { ZDetailsListItem } from '../../Common/components/ZDetailsListItem'
import { getPlatformOsTranslation } from '../../Common/helpers/getPlatformTranslation'
import { LogListView } from '../../Log/components/LogListView'
import { RiderLogListItem } from '../../Log/components/RiderLogListItem'
import { TasksByStatusAccordions } from '../../Task/components/TasksByStatusAccordions'
import { useTasksOnRidersCurrentJourney } from '../../Task/hooks/useTasksOnRidersCurrentJourney'
import { useWarehouseFromWarehouseId } from '../../Warehouse/hooks/useWarehouseFromWarehouseId'
import { getRiderName } from '../helpers/getRiderName'
import { useHoverRider } from '../hooks/useHoverRider'
import { useRiderFromRiderId } from '../hooks/useRiderFromRiderId'
import { RiderAssignWarehouseSelect } from './RiderAssignWarehouseSelect'
import { RiderStatusSelect } from './RiderStatusSelect'

export const RiderDetailsView: FC = () => {
  const riderId = useParams<'riderId'>().riderId || 'unknown'
  const [rider, isLoadingRider] = useRiderFromRiderId(riderId)
  const warehouse = useWarehouseFromWarehouseId(rider?.assignedWarehouseId)
  const tasks = useTasksOnRidersCurrentJourney(rider)
  const { onMouseOut, onMouseOver } = useHoverRider(tasks, rider?.id)

  return (
    <>
      {!rider && isLoadingRider && (
        <Box p={2} display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      )}
      {!rider && !isLoadingRider && (
        <Typography px={2} fontWeight={700} fontSize={21}>
          No rider found.
        </Typography>
      )}
      {rider && (
        <>
          <Typography px={2} fontWeight={700} fontSize={21}>
            {getRiderName(rider)}
          </Typography>
          <Box p={1} display="flex" justifyContent="space-between">
            <RiderStatusSelect rider={rider} fontWeight={500} />
            <IconButton
              color="inherit"
              component={Link}
              to={`/riders/${rider.id}/history`}
              onMouseOver={onMouseOver}
              onMouseOut={onMouseOut}
              data-testid="CreateTaskIconButton"
            >
              <VisibilityOutlinedIcon sx={{ fontSize: 16 }} />
            </IconButton>
          </Box>
          <ZAccordion title="Details" titleIcon={<MopedOutlinedIcon sx={{ color: COLOR.blue[50] }} />}>
            <List>
              <ZDetailsListItem title={rider.phone} iconComponent={PhoneOutlinedIcon} testid="RiderDetailsItemPhone" />
              <ZDetailsListItem
                title="Assigned Store"
                iconComponent={WarehouseOutlinedIcon}
                testid="RiderDetailsItemAssignedToStore"
                secondaryAction={<RiderAssignWarehouseSelect rider={rider} />}
              >
                {warehouse?.name || <em>None</em>}
              </ZDetailsListItem>
              {!rider.isThirdPartyProvider && (
                <ZDetailsListItem
                  title="Device"
                  iconComponent={PhoneIphoneOutlinedIcon}
                  testid="RiderDetailsItemDevice"
                >
                  App Version: <strong>{rider.appVersion || 'Not found'}</strong>
                  <br />
                  Platform:{' '}
                  <PlatformOsIcon
                    platformOs={rider.appPlatformOs}
                    sx={{ fontSize: 12, mr: 0.5, position: 'relative', top: 1 }}
                  />
                  <strong>
                    {getPlatformOsTranslation(rider.appPlatformOs)} {rider.appPlatformVersion || ''}
                  </strong>
                </ZDetailsListItem>
              )}
            </List>
          </ZAccordion>
          <ZAccordion title="Tasks" titleIcon={<LocationOnIcon sx={{ color: COLOR.blue[50] }} />}>
            <TasksByStatusAccordions tasks={tasks} />
          </ZAccordion>
          <ZAccordion title="Timeline" titleIcon={<HistoryIcon sx={{ color: COLOR.blue[50] }} />}>
            <LogListView
              collectionRef={getRiderLogCollectionRef(riderId)}
              getLogsFromSnapshot={getRiderLogsFromSnapshot}
              LogListItem={RiderLogListItem}
              limit={20}
            />
          </ZAccordion>
        </>
      )}
    </>
  )
}
