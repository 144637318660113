import { TaskStatus } from '@quickcommerceltd/zephyr-types'

export function hasTaskBeenPickedUp(taskStatus: TaskStatus) {
  return Array<TaskStatus>(
    'PICKED_UP',
    'DELIVERING',
    'DELIVERED',
    'RETURNING_TO_STORE',
    'FAILED',
    'RETURNED',
    'NOT_RETURNED'
  ).includes(taskStatus)
}
