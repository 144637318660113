import { TaskStatus } from '@quickcommerceltd/zephyr-types'

export const TASK_STATUSES: TaskStatus[] = [
  'UNASSIGNED',
  'PENDING_RIDER_ASSIGNMENT',
  'ASSIGNED',
  'PICKED_UP',
  'DELIVERING',
  'DELIVERED',
  'FAILED',
  'RETURNING_TO_STORE',
  'RETURNED',
  'NOT_RETURNED',
]
