import { FC, useEffect } from 'react'
import { useEscapeKey } from '../hooks/useEscapeKey'

interface Props {
  close: () => void
}

export const EscapeKeyToClose: FC<Props> = (props) => {
  useEffect(() => {
    const close = props.close

    useEscapeKey.getState().mount(close)

    return () => useEscapeKey.getState().unmount(close)
  }, [props.close])

  return null
}
