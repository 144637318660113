export function isQueryInFields(query: string, fields: (string | undefined)[]) {
  const queries = query.trim().toLowerCase().split(' ')

  return queries.every((q) => {
    for (const f of fields) {
      if (f && f.toLowerCase().indexOf(q) > -1) {
        return true
      }
    }

    return false
  })
}
