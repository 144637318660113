import { UserRole } from '@quickcommerceltd/zephyr-types'
import { Permission } from '../types/Permission'

export const PERMISSION_ROLES: Record<Permission, UserRole[]> = {
  CLONE_TASK: ['ADMIN', 'LIVE_OPS', 'DISPATCHER', 'CUSTOMER_SERVICE'],
  COMPLETE_TASK: ['ADMIN', 'LIVE_OPS', 'DISPATCHER', 'CUSTOMER_SERVICE'],
  DELETE_TASK: ['ADMIN', 'LIVE_OPS'],
  EXPORT_TASKS: ['ADMIN', 'LIVE_OPS', 'DISPATCHER', 'CUSTOMER_SERVICE'],
  READ_ALL_WAREHOUSES: ['ADMIN', 'LIVE_OPS', 'CUSTOMER_SERVICE'],
  REDIRECT_TO_EXTERNAL_ORDER: ['ADMIN', 'LIVE_OPS', 'CUSTOMER_SERVICE'],
  UPDATE_RIDER_STATUS: ['ADMIN', 'LIVE_OPS', 'DISPATCHER'],
  UPDATE_RIDER_STATUS_TO_ANYTHING: ['ADMIN'],
  UPDATE_RIDER_WAREHOUSE: ['ADMIN', 'LIVE_OPS', 'DISPATCHER'],
  UPDATE_TASK_PICKING_STATUS: ['ADMIN', 'LIVE_OPS', 'DISPATCHER'],
  UPDATE_TASK_RIDER: ['ADMIN', 'LIVE_OPS', 'DISPATCHER'],
  UPDATE_TASK_WAREHOUSE: ['ADMIN', 'LIVE_OPS', 'DISPATCHER', 'CUSTOMER_SERVICE'],
  UPDATE_WAREHOUSE: ['ADMIN', 'LIVE_OPS', 'DISPATCHER'],
  UPDATE_WAREHOUSE_THIRD_PARTY_PROVIDERS: ['ADMIN'],
  UPSERT_RIDER: ['ADMIN', 'LIVE_OPS', 'DISPATCHER'],
  UPSERT_TASK: ['ADMIN', 'LIVE_OPS', 'DISPATCHER', 'CUSTOMER_SERVICE'],
  UPSERT_USER: ['ADMIN', 'LIVE_OPS'],
}
