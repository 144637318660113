import { Timestamp } from 'firebase/firestore'
import { DateTime } from 'luxon'

export function deepReplaceTimestamp(obj?: unknown) {
  if (typeof obj === 'object' && obj !== null) {
    const clonedObj: Record<string, unknown> = {}

    Object.entries(obj).forEach(([key, value]) => {
      if (value instanceof Timestamp) {
        clonedObj[key] = DateTime.fromMillis(value.toMillis()).toLocaleString(DateTime.DATETIME_FULL_WITH_SECONDS)
      } else {
        clonedObj[key] = deepReplaceTimestamp(value)
      }
    })

    return clonedObj
  }

  return obj
}
