import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import MopedOutlinedIcon from '@mui/icons-material/MopedOutlined'
import { Box, List } from '@mui/material'
import { query, where } from 'firebase/firestore'
import { FC, useMemo } from 'react'
import { useCollection } from 'react-firebase-hooks/firestore'
import { COLOR } from '../../App/constants/COLOR'
import { getTaskCollectionRef } from '../../Auth/helpers/getCollectionRef'
import { getTasksFromSnapshot } from '../../Auth/helpers/getDataFromQuerySnapshot'
import { LoadingIcon } from '../../Common/components/LoadingIcon'
import { ZAccordion } from '../../Common/components/ZAccordion'
import { isZappTask } from '../../Common/helpers/isMarketplaceTask'
import { useRiders } from '../../Rider/hooks/useRiders'
import { useTasks } from '../../Task/hooks/useTasks'
import { isQueryInFields } from '../helpers/isQueryInFields'
import { useSidebarSearch } from '../hooks/useSidebarSearch'
import { SidebarEmptyListItem } from './SidebarEmptyListItem'
import { SidebarRiderListItem } from './SidebarRiderListItem'
import { SidebarTaskListItemWithTooltip } from './SidebarTaskListItemWithTooltip'

export const SidebarSearchResults: FC = () => {
  const search = useSidebarSearch()
  const tasks = useTasks((state) => state.tasks)
  const riders = useRiders((state) => state.riders)

  const [loadedTasksSnapshot1, isLoadingTasks1] = useCollection(
    query(getTaskCollectionRef(), where('externalOrderNumber', '==', search.debouncedQuery))
  )

  const [loadedTasksSnapshot2, isLoadingTasks2] = useCollection(
    query(getTaskCollectionRef(), where('previousThirdPartyProviderTaskIds', 'array-contains', search.debouncedQuery))
  )

  const filteredTasks = useMemo(() => {
    const allTasks = [...tasks]

    const loadedTasks = [
      ...getTasksFromSnapshot(loadedTasksSnapshot1),
      ...getTasksFromSnapshot(loadedTasksSnapshot2),
    ].filter(isZappTask) // filter out marketplace orders as they are not actionable

    loadedTasks.forEach((qt) => {
      if (!allTasks.find((at) => at.id === qt.id)) {
        allTasks.push(qt)
      }
    })

    return allTasks.filter((task) =>
      isQueryInFields(search.query, [
        task.externalOrderNumber,
        task.externalOrderId,
        task.id,
        task.customer.firstName,
        task.customer.lastName,
        task.address.streetAddress1,
        task.address.postalCode,
        ...(task.previousThirdPartyProviderTaskIds ? task.previousThirdPartyProviderTaskIds : []),
      ])
    )
  }, [tasks, loadedTasksSnapshot1, loadedTasksSnapshot2, search.query])

  const filteredRiders = useMemo(
    () => riders.filter((rider) => isQueryInFields(search.query, [rider.id, rider.name, rider.phone])),
    [search.query, riders]
  )

  const isLoading = isLoadingTasks1 || isLoadingTasks2 || search.isDebouncing

  return (
    <Box pt={2}>
      <ZAccordion title="Tasks" titleIcon={isLoading ? <LoadingIcon size={24} /> : <LocationOnIcon />}>
        <List>
          {filteredTasks.length === 0 && (
            <SidebarEmptyListItem message="No tasks" iconComponent={CheckCircleOutlineIcon} color={COLOR.green[500]} />
          )}
          {filteredTasks.map((task) => (
            <SidebarTaskListItemWithTooltip key={task.id} task={task} />
          ))}
        </List>
      </ZAccordion>
      <ZAccordion title="Riders" titleIcon={<MopedOutlinedIcon />}>
        <List>
          {filteredRiders.length === 0 && (
            <SidebarEmptyListItem message="No riders" iconComponent={CheckCircleOutlineIcon} color={COLOR.green[500]} />
          )}
          {filteredRiders.map((rider) => (
            <SidebarRiderListItem key={rider.id} rider={rider} />
          ))}
        </List>
      </ZAccordion>
    </Box>
  )
}
