import { RiderStatus } from '@quickcommerceltd/zephyr-types'

export const RIDER_STATUSES: RiderStatus[] = [
  'PICKING_UP',
  'IDLE_AT_STORE',
  'WAITING_FOR_PACKED_ORDER',
  'DELIVERING',
  'IDLE_AT_CUSTOMER',
  'RIDING_BACK_TO_STORE',
  'OFF_DUTY',
]
