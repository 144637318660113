import AddCircleIcon from '@mui/icons-material/AddCircle'
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft'
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight'
import CancelIcon from '@mui/icons-material/Cancel'
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CircleIcon from '@mui/icons-material/Circle'
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined'
import WatchLaterIcon from '@mui/icons-material/WatchLater'
import { TaskLog } from '@quickcommerceltd/zephyr-types'
import { FC } from 'react'
import { COLOR } from '../../App/constants/COLOR'
import { getLogListItemProps } from '../helpers/getLogListItemProps'
import { LogListItem } from './LogListItem'

interface Props {
  log: TaskLog
  isFirstItem: boolean
  isLastItem: boolean
  onClick: () => void
}

export const TaskLogListItem: FC<Props> = (props) => {
  const restProps = getLogListItemProps(props)

  if (props.isLastItem) {
    return <LogListItem {...restProps} title="Task created" iconColor={COLOR.gray[300]} iconComponent={AddCircleIcon} />
  }

  switch (props.log.update.status) {
    case 'FAILED':
      return <LogListItem {...restProps} title="Task failed" iconColor={COLOR.red[500]} iconComponent={CancelIcon} />

    case 'UNASSIGNED':
      return (
        <LogListItem {...restProps} title="Task unassigned" iconColor={COLOR.gray[300]} iconComponent={CircleIcon} />
      )

    case 'DELIVERED':
      return (
        <LogListItem
          {...restProps}
          title="Task succeeded"
          iconColor={COLOR.green[500]}
          iconComponent={CheckCircleIcon}
        />
      )

    case 'PICKED_UP':
      return (
        <LogListItem
          {...restProps}
          title="Task picked up"
          iconColor={COLOR.blue[500]}
          iconComponent={ArrowCircleRightIcon}
        />
      )

    case 'DELIVERING':
      return (
        <LogListItem
          {...restProps}
          title="Task started"
          iconColor={COLOR.blue[500]}
          iconComponent={ArrowCircleRightIcon}
        />
      )

    case 'ASSIGNED':
      return (
        <LogListItem
          {...restProps}
          title="Task assigned"
          iconColor={COLOR.yellow[500]}
          iconComponent={CheckCircleIcon}
        />
      )

    case 'PENDING_RIDER_ASSIGNMENT':
      return (
        <LogListItem
          {...restProps}
          title="Task pending"
          iconColor={COLOR.yellow[500]}
          iconComponent={CheckCircleIcon}
        />
      )
    case 'RETURNING_TO_STORE':
      return (
        <LogListItem
          {...restProps}
          title="Task returning"
          iconColor={COLOR.blue[500]}
          iconComponent={ArrowCircleLeftIcon}
        />
      )
    case 'RETURNED':
      return (
        <LogListItem
          {...restProps}
          title="Task returned"
          iconColor={COLOR.red[500]}
          iconComponent={ArrowCircleLeftIcon}
        />
      )
    case 'NOT_RETURNED':
      return (
        <LogListItem
          {...restProps}
          title="Task not returned"
          iconColor={COLOR.red[500]}
          iconComponent={ArrowCircleLeftIcon}
        />
      )
  }

  switch (props.log.update.pickingStatus) {
    case 'PICKING':
      return (
        <LogListItem {...restProps} title="Picking started" iconColor={COLOR.gray[300]} iconComponent={CircleIcon} />
      )
    case 'PICKED':
      return (
        <LogListItem {...restProps} title="Picking completed" iconColor={COLOR.gray[300]} iconComponent={CircleIcon} />
      )
    case 'PACKED':
      return (
        <LogListItem
          {...restProps}
          title="Packing completed"
          iconColor={COLOR.gray[300]}
          iconComponent={CheckCircleIcon}
        />
      )
  }

  if (props.log.update.arrivedForPickupAt) {
    return (
      <LogListItem
        {...restProps}
        title="Arrived for pickup"
        iconColor={COLOR.violet[500]}
        iconComponent={ArrowCircleRightIcon}
      />
    )
  }

  if (props.log.update.riderId === 'delete()') {
    return (
      <LogListItem {...restProps} title="Task unassigned" iconColor={COLOR.red[500]} iconComponent={ChangeCircleIcon} />
    )
  }

  if (props.log.update.isRunningThirdPartyProviderAction) {
    return (
      <LogListItem
        {...restProps}
        title="3P action started"
        iconColor={COLOR.gray[300]}
        iconComponent={ChangeCircleIcon}
      />
    )
  }

  if (props.log.update.arrivedAtCustomerAt) {
    return (
      <LogListItem
        {...restProps}
        title="Arrived at customer"
        iconColor={COLOR.blue[500]}
        iconComponent={CheckCircleIcon}
      />
    )
  }

  if (props.log.update.arrivedAtStoreAt) {
    return (
      <LogListItem
        {...restProps}
        title="Arrived at store"
        iconColor={COLOR.green[500]}
        iconComponent={ArrowCircleLeftIcon}
      />
    )
  }

  if (props.log.update.thirdPartyProviderDataRecord) {
    return (
      <LogListItem {...restProps} title="3P data updated" iconColor={COLOR.gray[300]} iconComponent={WatchLaterIcon} />
    )
  }

  if (['API', 'SYSTEM'].includes(props.log.updatedBy.type)) {
    return (
      <LogListItem {...restProps} title="Task synced" iconColor={COLOR.gray[300]} iconComponent={ChangeCircleIcon} />
    )
  }

  return (
    <LogListItem {...restProps} title="Task edited" iconColor={COLOR.gray[300]} iconComponent={CircleOutlinedIcon} />
  )
}
