import LoadingButton from '@mui/lab/LoadingButton'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
} from '@mui/material'
import { DispatcherCreateRiderRequest } from '@quickcommerceltd/zephyr-types'
import { FC, useCallback, useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useNavigate, useParams } from 'react-router-dom'
import { AppThemeProvider } from '../../App/components/AppThemeProvider'
import { createRiderCallable } from '../../Auth/callables/rider/createRiderCallable'
import { updateRiderCallable } from '../../Auth/callables/rider/updateRiderCallable'
import { ControlledSelect } from '../../Common/components/ControlledSelect'
import { ControlledTextField } from '../../Common/components/ControlledTextField'
import { logError } from '../../Common/helpers/logError'
import { useRiderFromRiderId } from '../../Rider/hooks/useRiderFromRiderId'
import { useWarehouses } from '../../Warehouse/hooks/useWarehouses'
import { SettingsRiderDeleteDialog } from './SettingsRiderDeleteDialog'

type FormValues = {
  name: string
  phone: string
  email: string
  warehouseIds: string[]
}

export const SettingsRiderUpsertDialog: FC = () => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const warehouses = useWarehouses((state) => state.warehouses)
  const [isRiderDeleteDialogOpen, setIsRiderDeleteDialogOpen] = useState(false)
  const navigate = useNavigate()
  const { riderId } = useParams<'riderId'>()
  const isNew = riderId === 'new'
  const isFormInitializedRef = useRef(false)
  const [rider, isLoadingRider] = useRiderFromRiderId(riderId)
  const warehouseIds = warehouses.map((w) => w.id)

  const defaultValues: FormValues = {
    name: '',
    phone: '',
    email: '',
    warehouseIds,
  }

  const { reset, control, handleSubmit } = useForm<FormValues>({
    defaultValues,
  })

  useEffect(() => {
    if (!isFormInitializedRef.current && rider) {
      reset(rider)
      isFormInitializedRef.current = true
    }
  }, [reset, rider])

  const closeDialog = useCallback(() => {
    navigate('/settings/riders')
    reset()
  }, [navigate, reset])

  const onSubmit = useCallback(
    async (formValues: FormValues) => {
      try {
        const request: DispatcherCreateRiderRequest = {
          name: formValues.name.trim(),
          phone: formValues.phone.trim(),
          email: formValues.email.trim(),
          warehouseIds: formValues.warehouseIds,
        }

        setIsSubmitting(true)
        if (rider) {
          await updateRiderCallable({ riderId: rider.id, ...request })
        } else {
          await createRiderCallable(request)
        }

        closeDialog()
      } catch (error: any) {
        toast.error(error.message)
        logError(error)
      } finally {
        setIsSubmitting(false)
      }
    },
    [closeDialog, rider]
  )

  const isLoading = isLoadingRider || isSubmitting

  return (
    <AppThemeProvider mode="light">
      <Dialog onClose={closeDialog} fullWidth open>
        <form>
          <Box my={2} mx={3} display="flex" justifyContent="space-between">
            <DialogTitle sx={{ p: 0, alignSelf: 'center' }}>{isNew ? 'Create Rider' : 'Edit Rider'}</DialogTitle>
          </Box>
          <Divider />
          <DialogContent>
            <DialogContentText>Rider Details</DialogContentText>
            <Box sx={{ mt: 2 }}>
              <ControlledTextField
                required
                fullWidth
                name="name"
                label="Rider First and Last Name"
                control={control}
                disabled={isLoading}
                sx={{ mr: 2 }}
              />
            </Box>
            <Box display="flex" sx={{ mt: 2 }}>
              <ControlledTextField
                required
                fullWidth
                type="tel"
                name="phone"
                label="Rider Phone Number"
                placeholder="+44123456789"
                autoComplete="off"
                disabled={isLoading}
                control={control}
              />
              <ControlledTextField
                fullWidth
                sx={{ ml: 2 }}
                name="email"
                label="Rider Email"
                autoComplete="off"
                disabled={isLoading}
                control={control}
              />
            </Box>
            <DialogContentText sx={{ mt: 2 }}>Stores</DialogContentText>
            <ControlledSelect
              control={control}
              name="warehouseIds"
              label="Assigned to"
              allSelectedLabel="All Stores"
              helperText="At least one store is required"
              items={warehouses}
              getValue={(warehouse) => warehouse.id}
              getName={(warehouse) => warehouse.name}
              sx={{ mt: 2 }}
              disabled={isLoading}
              canSelectAll
              multiple
              required
              fullWidth
            />
          </DialogContent>
          <Divider />
          <DialogActions>
            <LoadingButton variant="contained" loading={isLoading} onClick={handleSubmit(onSubmit)}>
              {isNew ? 'Create Rider' : 'Save Rider'}
            </LoadingButton>
            <Button sx={{ mx: 2 }} onClick={closeDialog}>
              Cancel
            </Button>
            <Box display="flex" flex={1} />
            {rider && (
              <Button color="error" onClick={() => setIsRiderDeleteDialogOpen(true)}>
                Permanently remove Rider
              </Button>
            )}
          </DialogActions>
        </form>
      </Dialog>
      {rider && (
        <SettingsRiderDeleteDialog
          rider={rider}
          isOpen={isRiderDeleteDialogOpen}
          close={() => setIsRiderDeleteDialogOpen(false)}
        />
      )}
    </AppThemeProvider>
  )
}
