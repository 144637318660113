import { Task, ThirdPartyProvider } from '@quickcommerceltd/zephyr-types'
import { useCallback, useState } from 'react'
import toast from 'react-hot-toast'
import { assignTaskToThirdPartyProviderCallable } from '../../Auth/callables/task/assignTaskToThirdPartyProviderCallable'
import { logError } from '../../Common/helpers/logError'

export function useAssignTaskToThirdPartyProvider(): [
  (task: Task, thirdPartyProvider: ThirdPartyProvider) => Promise<void>,
  boolean,
] {
  const [isAssigningTaskToThirdPartyProvider, setIsAssigningTaskToThirdPartyProvider] = useState(false)

  const assignTaskToThirdPartyProvider = useCallback(async (task: Task, thirdPartyProvider: ThirdPartyProvider) => {
    try {
      setIsAssigningTaskToThirdPartyProvider(true)
      await assignTaskToThirdPartyProviderCallable({
        taskId: task.id,
        thirdPartyProvider,
      })
    } catch (error: unknown) {
      toast.error((error as Error).message)
      logError(error as Error)
    } finally {
      setIsAssigningTaskToThirdPartyProvider(false)
    }
  }, [])

  return [assignTaskToThirdPartyProvider, isAssigningTaskToThirdPartyProvider]
}
