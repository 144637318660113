import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import { List } from '@mui/material'
import { FC, useMemo } from 'react'
import { COLOR } from '../../App/constants/COLOR'
import { ZAccordion } from '../../Common/components/ZAccordion'
import { TASK_COMPLETE_STATUSES } from '../../Task/constants/TASK_COMPLETE_STATUSES'
import { useTasks } from '../../Task/hooks/useTasks'
import { SidebarEmptyListItem } from './SidebarEmptyListItem'
import { SidebarTaskListItem } from './SidebarTaskListItem'

export const SidebarCompletedView: FC = () => {
  const tasks = useTasks((state) => state.tasks)

  const visibleTasks = useMemo(
    () =>
      tasks
        .filter((t) => TASK_COMPLETE_STATUSES.includes(t.status))
        .sort((a, b) => (b.completedAt?.toMillis() || 0) - (a.completedAt?.toMillis() || 0)),
    [tasks]
  )

  return (
    <ZAccordion title="Completed" titleIcon={<LocationOnIcon />} testId="SidebarCompletedAccordian">
      <List>
        {visibleTasks.length === 0 && (
          <SidebarEmptyListItem
            message="No tasks completed"
            iconComponent={CheckCircleOutlineIcon}
            color={COLOR.green[500]}
          />
        )}
        {visibleTasks.map((task) => (
          <SidebarTaskListItem key={task.id} task={task} />
        ))}
      </List>
    </ZAccordion>
  )
}
