import LoadingButton from '@mui/lab/LoadingButton'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, TextField } from '@mui/material'
import { Task, TaskFailureReason, TaskStatus } from '@quickcommerceltd/zephyr-types'
import { FC, useCallback } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { AppThemeProvider } from '../../App/components/AppThemeProvider'
import { ControlledSelect } from '../../Common/components/ControlledSelect'
import { TASK_COMPLETE_STATUSES } from '../constants/TASK_COMPLETE_STATUSES'
import { TASK_FAILURE_REASONS } from '../constants/TASK_FAILURE_REASONS'
import { getTaskFailureReasonTranslation } from '../helpers/getTaskFailureReasonTranslation'
import { getTaskStatusTranslationByStatus } from '../helpers/getTaskStatusTranslationByStatus'
import { useCompleteTask } from '../hooks/useCompleteTask'

interface Props {
  task: Task
  isOpen: boolean
  close: () => void
}

interface FieldValues {
  taskStatus: TaskStatus
  failureNotes: string
  failureReason: TaskFailureReason | ''
}

export const TaskCompleteDialog: FC<Props> = (props) => {
  const { completeTask, isCompletingTask } = useCompleteTask()
  const { control, getValues, watch, handleSubmit } = useForm<FieldValues>({
    defaultValues: {
      taskStatus: 'DELIVERED',
      failureReason: '',
      failureNotes: '',
    },
  })

  const onSubmit = useCallback<SubmitHandler<FieldValues>>(
    async (values) => {
      await completeTask({
        task: props.task,
        nextTaskStatus: values.taskStatus,
        failureReason: values.taskStatus === 'FAILED' && values.failureReason ? values.failureReason : undefined,
        failureNotes: values.taskStatus === 'FAILED' ? undefined : values.failureNotes,
      })

      props.close()
    },
    [completeTask, props]
  )
  const values = getValues()

  watch(['taskStatus', 'failureReason', 'failureNotes'])

  return (
    <AppThemeProvider mode="light">
      <Dialog open={props.isOpen} onClose={props.close} fullWidth>
        <DialogTitle>Complete Task</DialogTitle>
        <DialogContent>
          <ControlledSelect
            control={control}
            name="taskStatus"
            label="Task Status"
            required
            items={TASK_COMPLETE_STATUSES}
            getValue={(status) => status}
            getName={(status) => getTaskStatusTranslationByStatus(status)}
            sx={{ mt: 2 }}
            fullWidth
          />
          <ControlledSelect
            control={control}
            name="failureReason"
            label="Failure Reason"
            disabled={values.taskStatus !== 'FAILED'}
            required={values.taskStatus === 'FAILED'}
            items={TASK_FAILURE_REASONS.filter((reason) => reason !== 'OTHER')}
            getValue={(reason) => reason}
            getName={(reason) => getTaskFailureReasonTranslation(reason)}
            sx={{ mt: 2 }}
            fullWidth
          />
          <Controller
            name="failureNotes"
            control={control}
            render={({ field }) => (
              <Box mt={2}>
                <TextField
                  {...field}
                  disabled={values.taskStatus !== 'FAILED'}
                  required={values.taskStatus === 'FAILED' && values.failureReason === 'OTHER'}
                  label="Failure Notes"
                  rows={3}
                  fullWidth
                  multiline
                  data-testid="TaskCompleteDialogFailureNotes"
                />
              </Box>
            )}
          />
        </DialogContent>
        <Divider />
        <DialogActions>
          <LoadingButton
            variant="contained"
            loading={isCompletingTask}
            onClick={handleSubmit(onSubmit)}
            data-testid="TaskCompleteDialogCompleteButton"
            disabled={
              values.taskStatus === 'FAILED' &&
              (!values.failureReason || (values.failureReason === 'OTHER' && !values.failureNotes))
            }
          >
            Complete
          </LoadingButton>
          <Button onClick={props.close} autoFocus data-testid="TaskCompleteDialogCancelButton">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </AppThemeProvider>
  )
}
