import { Task, ThirdPartyProvider } from '@quickcommerceltd/zephyr-types'
import { getThirdPartyProviderTranslation } from '../../Common/helpers/getThirdPartyProviderTranslation'

export function getTaskPickUpTooltip(task: Task, thirdPartyProviders: ThirdPartyProvider[]) {
  const etaStrings = thirdPartyProviders.reduce<string[]>((result, provider) => {
    const data = task.thirdPartyProviderDataRecord?.[provider]

    const estimatedPickUpDurationInSeconds = data?.estimatedPickUpDurationInSeconds

    if (estimatedPickUpDurationInSeconds === undefined) {
      return result
    }

    if (!['UNASSIGNED', 'PENDING_RIDER_ASSIGNMENT'].includes(task.status)) {
      return result
    }

    const isDisallowed = data?.isDisallowed

    const value = isDisallowed ? 'Disallowed' : `${Math.ceil(estimatedPickUpDurationInSeconds / 60)} mins`

    result.push(`${getThirdPartyProviderTranslation(provider)}: ${value}`)

    return result
  }, [])

  if (etaStrings.length) {
    return etaStrings.join('\n')
  }
}
