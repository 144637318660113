import { Box, Button, Typography } from '@mui/material'
import { FC, useEffect, useState } from 'react'
import { isVersionSupported } from '../../Common/helpers/isVersionSupported'
import { COLOR } from '../constants/COLOR'
import { ENVIRONMENT } from '../constants/ENVIRONMENT'
import { useAppConfig } from '../hooks/useAppConfig'

const SECONDS_TO_RELOAD = 5 * 60

export const AppMinVersionView: FC = () => {
  const [secondsLeft, setSecondsLeft] = useState(0)
  const isSupported = useAppConfig((store) =>
    isVersionSupported(store.appConfig?.minVersion.web || '1', ENVIRONMENT.version)
  )

  useEffect(() => {
    if (isSupported) return

    setSecondsLeft(SECONDS_TO_RELOAD)
    const interval = setInterval(() => {
      setSecondsLeft((secondsLeft) => {
        if (secondsLeft <= 1) {
          clearInterval(interval)
          window.location.reload()
        }

        return secondsLeft - 1
      })
    }, 1000)

    return () => clearInterval(interval)
  }, [isSupported])

  if (isSupported || ENVIRONMENT.nodeEnv === 'development') return null

  return (
    <Box display="flex" alignItems="center" justifyContent="space-between" sx={{ bgcolor: COLOR.blue[500] }} p={2}>
      <Typography fontWeight="bold">
        There is a new version of Zephyr. The page will reload in {secondsLeft} seconds.
      </Typography>
      <Button sx={{ ml: 2 }} variant="outlined" color="inherit" onClick={() => window.location.reload()}>
        Reload now
      </Button>
    </Box>
  )
}
