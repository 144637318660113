import { Task } from '@quickcommerceltd/zephyr-types'
import { useEffect, useRef } from 'react'
import { useMap } from '../../Map/hooks/useMap'

export function useHoverRider(tasks: Task[], riderId?: string) {
  const unmountRef = useRef<() => void>()
  const setFocusedRiderId = useMap((store) => store.setFocusedRiderId)
  const setFocusedTaskIds = useMap((store) => store.setFocusedTaskIds)
  const removeFocusedTaskIds = useMap((store) => store.removeFocusedTaskIds)
  const removeFocusedRiderId = useMap((store) => store.removeFocusedRiderId)

  useEffect(() => {
    return () => {
      unmountRef.current?.()
      unmountRef.current = undefined
    }
  }, [])

  const onMouseOver = () => {
    if (!riderId) return
    setFocusedRiderId(riderId)
    setFocusedTaskIds(tasks.map((t) => t.id))
    unmountRef.current = () => {
      removeFocusedTaskIds()
      removeFocusedRiderId()
    }
  }

  const onMouseOut = () => {
    unmountRef.current?.()
    unmountRef.current = undefined
  }

  return { onMouseOver, onMouseOut }
}
