import { Box, ListItem, ListItemSecondaryAction, SvgIconTypeMap, Typography } from '@mui/material'
import { OverridableComponent } from '@mui/material/OverridableComponent'
import { FC, ReactNode } from 'react'
import { Link } from 'react-router-dom'
import { COLOR } from '../../App/constants/COLOR'

interface Props {
  title: string
  iconComponent: OverridableComponent<SvgIconTypeMap>
  children?: ReactNode
  secondaryAction?: ReactNode
  onClick?: () => void
  to?: string
  testid?: string
}

export const ZDetailsListItem: FC<Props> = (props) => {
  return (
    <ListItem
      {...(props.onClick && { button: true as any, onClick: props.onClick })}
      {...(props.to && { component: Link, to: props.to, button: true as any })}
      {...(props.testid && { 'data-testid': props.testid })}
    >
      <props.iconComponent sx={{ color: COLOR.blue[50] }} />
      <Box flex={1} ml={1}>
        <Typography fontSize={12} fontWeight={700}>
          {props.title}
        </Typography>
        <Typography fontSize={12} fontWeight={500} sx={{ opacity: 0.75 }}>
          {props.children}
        </Typography>
      </Box>
      <ListItemSecondaryAction>{props.secondaryAction}</ListItemSecondaryAction>
    </ListItem>
  )
}
