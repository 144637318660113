import { useMemo } from 'react'
import { useLocalStorage } from '../../Common/hooks/useLocalStorage'
import { useWarehouses } from './useWarehouses'

export function useSelectedWarehouse() {
  const warehouses = useWarehouses((state) => state.warehouses)
  const selectedWarehouseId = useLocalStorage((store) => store.data.selectedWarehouseId)

  return useMemo(
    () => warehouses.find((warehouse) => warehouse.id === selectedWarehouseId),
    [selectedWarehouseId, warehouses]
  )
}
