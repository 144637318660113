import { TaskUnassignReason } from '@quickcommerceltd/zephyr-types'

export function getTaskUnassignReasonTranslation(reason: TaskUnassignReason): string {
  switch (reason) {
    case 'ACCIDENTALLY_REQUESTED':
      return 'Accidentally requested'
    case 'RIDER_DID_NOT_SHOW_UP':
      return 'Rider did not show up'
    case 'RIDER_ETA_TOO_LATE':
      return 'Rider arrives at store too late'
    case 'OOS_ITEM':
      return 'Item out of stock'
    case 'OTHER':
      return 'Other'
  }
}
