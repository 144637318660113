import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import { Button, IconButton, Menu, MenuItem, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { FC, useState } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'
import { Link } from 'react-router-dom'
import Logo from '../../Assets/images/zephyr-logo.svg'
import { auth } from '../../Auth/helpers/firebase'
import { logout } from '../../Auth/helpers/logout'
import { useHasPermission } from '../../Auth/hooks/useHasPermission'
import { ZAvatar } from '../../Common/components/ZAvatar'
import { TasksExportDialog } from '../../Task/components/TasksExportDialog'
import { TaskUpsertDialog } from '../../Task/components/TaskUpsertDialog'
import { getUserRoleTranslation } from '../../User/helpers/getUserRoleTranslation'
import { useMyUser } from '../../User/hooks/useMyUser'
import { useSelectedWarehouse } from '../../Warehouse/hooks/useSelectedWarehouse'
import { APP_HEADER_HEIGHT } from '../constants/APP_HEADER_HEIGHT'
import { COLOR } from '../constants/COLOR'
import { ENVIRONMENT } from '../constants/ENVIRONMENT'
import { AppHeaderNavigationButton } from './AppHeaderNavigationButton'

export const AppHeaderView: FC = () => {
  const selectedWarehouse = useSelectedWarehouse()
  const [authState] = useAuthState(auth)
  const myUser = useMyUser((state) => state.user)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [isTaskUpsertDialogOpen, setIsTaskUpsertDialogOpen] = useState(false)
  const [isTasksExportDialogOpen, setIsTasksExportDialogOpen] = useState(false)
  const hasPermission = useHasPermission()

  if (!authState) return null

  return (
    <Box display="flex" alignItems="center" height={APP_HEADER_HEIGHT} borderBottom={2} borderColor={COLOR.rock[400]}>
      <Box ml={2} position="relative" top="6px">
        <Link to="/">
          <img src={Logo} alt="Zephyr" height={28} data-testid="Zephyrlogo" />
        </Link>
      </Box>
      <Typography ml={1} mt={1.5} fontSize={12} textTransform="uppercase" fontWeight={500}>
        {ENVIRONMENT.name}
      </Typography>
      <Box display="flex" flex={1} justifyContent="center">
        <AppHeaderNavigationButton title="Dashboard" to="/" />
        <AppHeaderNavigationButton title="Settings" to="/settings" />
      </Box>
      <Box display="flex" mr={2} alignItems="center">
        {!!selectedWarehouse && hasPermission('UPSERT_TASK') && (
          <IconButton
            color="inherit"
            onClick={() => setIsTaskUpsertDialogOpen(true)}
            data-testid="CreateTaskIconButton"
          >
            <AddCircleOutlineIcon sx={{ fontSize: 24 }} />
          </IconButton>
        )}
        {hasPermission('EXPORT_TASKS') && (
          <IconButton
            color="inherit"
            onClick={() => setIsTasksExportDialogOpen(true)}
            data-testid="CSVExportIconButton"
          >
            <FileDownloadOutlinedIcon sx={{ fontSize: 24 }} />
          </IconButton>
        )}
        <Button
          variant="text"
          color="inherit"
          sx={{ textAlign: 'left' }}
          onClick={(event) => setAnchorEl(event.currentTarget)}
          data-testid="AvatarButton"
        >
          <ZAvatar
            src={authState.photoURL}
            alt={authState.displayName || 'User'}
            name={authState.displayName}
            sx={{ mx: 1 }}
          />
          <Box mr={1}>
            <Typography fontSize={12} fontWeight={700}>
              {authState.email}
            </Typography>
            <Typography fontSize={12}>{getUserRoleTranslation(myUser?.role)}</Typography>
          </Box>
        </Button>
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={logout} data-testid="LogoutButton">
          Logout
        </MenuItem>
      </Menu>
      <TaskUpsertDialog isOpen={isTaskUpsertDialogOpen} close={() => setIsTaskUpsertDialogOpen(false)} />
      {isTasksExportDialogOpen && (
        <TasksExportDialog isOpen={isTasksExportDialogOpen} close={() => setIsTasksExportDialogOpen(false)} />
      )}
    </Box>
  )
}
