import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import { Divider, InputAdornment, List, ListItem, Stack, TextField, Typography } from '@mui/material'
import { ThirdPartyProvider } from '@quickcommerceltd/zephyr-types'
import { ChangeEvent, DragEvent, FC, useCallback, useState } from 'react'
import { UseFormSetValue } from 'react-hook-form'
import { WarehouseUpsertFormValues } from '../types/WarehouseUpsertFormValues'

interface ConfigItem {
  provider: ThirdPartyProvider
  autoReassignmentMinimumThresholdInSeconds: number
}

interface Props {
  setValue: UseFormSetValue<WarehouseUpsertFormValues>
  disabled?: boolean
  preferenceList: ConfigItem[]
}

export const SettingsWarehousePreferredProviderView: FC<Props> = (props) => {
  const [draggedItem, setDraggedItem] = useState<ConfigItem | undefined>()
  const [listItems, setListItems] = useState<ConfigItem[]>(props.preferenceList)

  const handleDragStart = useCallback(
    (event: DragEvent<HTMLLIElement>, index: number) => {
      setDraggedItem(listItems[index])
      event.dataTransfer.dropEffect = 'none'
      event.dataTransfer.effectAllowed = 'move'
    },
    [listItems]
  )

  const handleDragOver = useCallback(
    (event: DragEvent<HTMLLIElement>, index: number) => {
      event.preventDefault()
      const draggedOverItem = listItems[index]

      // if the item is dragged over itself, ignore
      if (draggedItem === draggedOverItem) {
        return
      }

      // filter out the currently dragged item
      let newItems = listItems.filter((item: ConfigItem) => item !== draggedItem)

      // add the dragged item after the dragged over item
      newItems.splice(index, 0, draggedItem!)

      setListItems(newItems)
    },
    [listItems, draggedItem]
  )

  const handleDragEnd = useCallback(() => {
    setDraggedItem(undefined)
    props.setValue('thirdPartyProviderPreferenceList', listItems)
  }, [listItems, props])

  const handleThresholdChange = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
      const newItem = {
        ...listItems[index],
        autoReassignmentMinimumThresholdInSeconds: Number(event.target.value),
      }
      const newListItems = listItems.map((item) => (item.provider === newItem.provider ? newItem : item))
      setListItems(newListItems)
      props.setValue('thirdPartyProviderPreferenceList', newListItems)
    },
    [listItems, props]
  )

  return (
    <Stack>
      <Divider />
      <List sx={{ padding: 0 }}>
        {listItems.map((item, index) => (
          <ListItem
            key={item.provider}
            draggable={!props.disabled}
            onDragStart={(event) => handleDragStart(event, index)}
            onDragOver={(event) => handleDragOver(event, index)}
            onDragEnd={handleDragEnd}
            divider
            sx={{
              padding: '12px 4px',
              cursor: !!draggedItem ? 'grabbing' : 'grab',
              bgcolor: draggedItem?.provider === item.provider ? 'grey.100' : 'white',
            }}
          >
            <DragIndicatorIcon
              sx={{
                marginRight: 2,
                color: 'grey.600',
              }}
            />
            <Stack direction="row" justifyContent="space-between" width="100%" alignItems="center">
              <Stack direction="row" alignItems="center" color={props.disabled ? 'grey.500' : undefined}>
                <Typography fontWeight="bold" marginRight={1}>
                  {index + 1}
                </Typography>
                <Typography>{item.provider}</Typography>
              </Stack>

              <TextField
                size="small"
                label="Minimum Duration Before Reassignment"
                sx={{
                  width: '50%',
                  '.MuiOutlinedInput-root': { pr: 0 },
                }}
                type="number"
                disabled={props.disabled}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" sx={{ marginRight: 1 }}>
                      <Typography color={props.disabled ? 'grey.500' : undefined}>seconds</Typography>
                    </InputAdornment>
                  ),
                }}
                value={item.autoReassignmentMinimumThresholdInSeconds}
                onChange={(event) => handleThresholdChange(event, index)}
              />
            </Stack>
          </ListItem>
        ))}
      </List>
    </Stack>
  )
}
