import { Rider } from '@quickcommerceltd/zephyr-types'
import { Polyline } from '@react-google-maps/api'
import { FC, useMemo } from 'react'
import { COLOR } from '../../App/constants/COLOR'
import { Coords } from '../../Common/types/Coords'
import { useTasksOnRidersCurrentJourney } from '../../Task/hooks/useTasksOnRidersCurrentJourney'
import { useSelectedWarehouse } from '../../Warehouse/hooks/useSelectedWarehouse'
import { getPolylineArrows } from '../helpers/getPolylineArrows'
import { toLatLngLiteral } from '../helpers/toLatLngLiteral'

interface Props {
  rider: Rider
  location: Coords
  isFocused: boolean
}

export const MapRiderPath: FC<Props> = (props) => {
  const selectedWarehouse = useSelectedWarehouse()
  const tasks = useTasksOnRidersCurrentJourney(props.rider).filter((t) => t.status !== 'RETURNING_TO_STORE')

  const deliveryPath = useMemo(() => {
    const path: google.maps.LatLngLiteral[] = [toLatLngLiteral(props.location)]

    if (['PICKING_UP', 'RIDING_BACK_TO_STORE'].includes(props.rider.status) && selectedWarehouse) {
      path.push(toLatLngLiteral(selectedWarehouse.address))
    }

    path.push(...tasks.map((t) => toLatLngLiteral(t.address.location)))

    return path
  }, [props.location, props.rider.status, selectedWarehouse, tasks])

  const returnPath = useMemo(() => {
    const path: google.maps.LatLngLiteral[] = []

    if (tasks.length) {
      path.push(toLatLngLiteral(tasks[tasks.length - 1].address.location))
    }

    if (selectedWarehouse) {
      path.push(toLatLngLiteral(selectedWarehouse.address))
    }

    return path
  }, [selectedWarehouse, tasks])

  return (
    <>
      {deliveryPath.length >= 2 && (
        <Polyline
          visible={props.isFocused}
          path={deliveryPath}
          options={{
            strokeColor: COLOR.blue[500],
            strokeOpacity: 0,
            icons: getPolylineArrows(1),
          }}
        />
      )}
      {!props.rider.thirdPartyProvider && returnPath.length >= 2 && (
        <Polyline
          visible={props.isFocused}
          path={returnPath}
          options={{
            strokeColor: COLOR.blue[500],
            strokeOpacity: 0,
            icons: getPolylineArrows(0.4),
          }}
        />
      )}
    </>
  )
}
