import { Task, Warehouse } from '@quickcommerceltd/zephyr-types'
import { RiderWithCoords } from '../types/RiderWithCoords'
import { getEstimatedReturnTimeInMinutes } from './getEstimatedReturnTimeInMinutes'
import { isRiderInTransit } from './isRiderInTransit'

export function getEstimatedReturnTimeTranslation(warehouse: Warehouse, rider: RiderWithCoords, tasks: Task[]) {
  if (!isRiderInTransit(rider)) return

  const minutes = getEstimatedReturnTimeInMinutes(warehouse, rider, tasks)

  if (minutes === undefined) return

  return `Back in ${Math.round(minutes)} mins`
}
