import { ThirdPartyProvider, WarehouseThirdPartyProviderConfigRecord } from '@quickcommerceltd/zephyr-types'
import { DateTime } from 'luxon'

export function getAreProvidersActiveTimesInvalid(
  providers: ThirdPartyProvider[],
  warehouseThirdPartyProviderConfigs: WarehouseThirdPartyProviderConfigRecord
) {
  return providers.some((provider) => {
    return warehouseThirdPartyProviderConfigs?.[provider]?.activeTimes?.some((activeTime) =>
      Boolean(DateTime.fromFormat(activeTime.from, 'HH:mm') > DateTime.fromFormat(activeTime.to, 'HH:mm'))
    )
  })
}
