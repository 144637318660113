import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Accordion, AccordionDetails, AccordionSummary, styled, Typography } from '@mui/material'
import { FC, ReactNode } from 'react'
import { COLOR } from '../../App/constants/COLOR'

interface Props {
  title: string
  titleIcon: ReactNode
  children: ReactNode
  testId?: string
}

export const ZAccordion: FC<Props> = (props) => {
  return (
    <SAccordion square defaultExpanded>
      <SAccordionSummary expandIcon={<ExpandMoreIcon />} data-testid={props.testId}>
        {props.titleIcon}
        <Typography ml={1} fontSize={16} fontWeight={700}>
          {props.title}
        </Typography>
      </SAccordionSummary>
      <SAccordionDetails>{props.children}</SAccordionDetails>
    </SAccordion>
  )
}

const SAccordion = styled(Accordion)({
  borderColor: COLOR.rock[400],
  backgroundColor: COLOR.rock[800],
  borderTopWidth: 1,
  borderTopStyle: 'solid',
  backgroundImage: 'unset',
  '&.Mui-expanded': {
    margin: 0,
  },
})

const SAccordionSummary = styled(AccordionSummary)({
  height: 58,
  paddingLeft: 13,
  '&.Mui-expanded': {
    minHeight: 58,
  },
})

const SAccordionDetails = styled(AccordionDetails)({
  padding: 0,
  backgroundColor: COLOR.rock[500],
})
