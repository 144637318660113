import { TaskCloneReason } from '@quickcommerceltd/zephyr-types'

export const TASK_CLONE_REASONS: TaskCloneReason[] = [
  'DELIVERY_INCIDENT',
  'DESTINATION_INCORRECT',
  'UNAVAILABLE',
  'ITEM_INCORRECT',
  'ITEM_DAMAGED',
  'WRONG_ORDER_TAKEN',
  'OVER_WEIGHT_LIMIT',
]
