import { Timestamp } from '@quickcommerceltd/zephyr-types'

export function getSecondsFromTimestamp(timestamp: Timestamp) {
  const date = timestamp.toDate()
  const hours = date.getHours()
  const minutes = date.getMinutes()
  const seconds = date.getSeconds()

  return hours * 3600 + minutes * 60 + seconds
}
