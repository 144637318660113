import CancelIcon from '@mui/icons-material/Cancel'
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CircleIcon from '@mui/icons-material/Circle'
import ExploreIcon from '@mui/icons-material/Explore'
import { ThirdPartyProvider, WarehouseLog } from '@quickcommerceltd/zephyr-types'
import { TransportType } from '@quickcommerceltd/zephyr-types/dist/types/TransportType'
import { FC } from 'react'
import { COLOR } from '../../App/constants/COLOR'
import { THIRD_PARTY_PROVIDERS } from '../../Common/constants/THIRD_PARTY_PROVIDERS'
import { getThirdPartyProviderTranslation } from '../../Common/helpers/getThirdPartyProviderTranslation'
import { getTransportTypeTranslation } from '../../Common/helpers/getTransportTypeTranslation'
import { getLogListItemProps } from '../helpers/getLogListItemProps'
import { LogListItem } from './LogListItem'

interface Props {
  log: WarehouseLog
  isFirstItem: boolean
  isLastItem: boolean
  onClick: () => void
}

export const WarehouseLogListItem: FC<Props> = (props) => {
  const restProps = getLogListItemProps(props)
  const providerConfigs = getProviderConfigs(props.log)

  if (providerConfigs.length) {
    const title = [
      ...providerConfigs.map(
        (c) =>
          `${getThirdPartyProviderTranslation(c.provider)}: ${
            c.isEnabled
              ? c.transportType
                ? getTransportTypeTranslation(c.transportType)
                : 'On'
              : !c.transportType
                ? 'Changed Default Settings'
                : 'Off'
          }`
      ),
      ...(typeof props.log.update.isAutoAssignmentEnabled === 'boolean'
        ? [`Auto assignment: ${props.log.update.isAutoAssignmentEnabled ? 'On' : 'Off'}`]
        : []),
    ].join(' | ')

    return (
      <LogListItem
        {...restProps}
        title={title}
        iconColor={title.includes('Changed Default Settings') ? COLOR.yellow[500] : COLOR.blue[500]}
        iconComponent={ChangeCircleIcon}
      />
    )
  }

  if (props.log.update.status) {
    switch (props.log.update.status) {
      case 'OPEN':
        return (
          <LogListItem
            {...restProps}
            title="Store opened"
            iconColor={COLOR.green[500]}
            iconComponent={CheckCircleIcon}
          />
        )
      case 'DRAFT':
      case 'CLOSED':
        return <LogListItem {...restProps} title="Store closed" iconColor={COLOR.red[500]} iconComponent={CancelIcon} />
    }
  }

  if (props.log.update.polygonPoints) {
    return (
      <LogListItem {...restProps} title="Polygon updated" iconColor={COLOR.violet[500]} iconComponent={ExploreIcon} />
    )
  }

  if (typeof props.log.update.promisedDeliveryTimeInMinutes === 'number') {
    return (
      <LogListItem {...restProps} title="Promise updated" iconColor={COLOR.orange[500]} iconComponent={CircleIcon} />
    )
  }

  return (
    <LogListItem {...restProps} title="Store updated" iconColor={COLOR.gray[300]} iconComponent={ChangeCircleIcon} />
  )
}

interface ProviderConfig {
  provider: ThirdPartyProvider
  isEnabled?: boolean
  transportType?: TransportType
}

function getProviderConfigs(log: WarehouseLog): ProviderConfig[] {
  return THIRD_PARTY_PROVIDERS.reduce<ProviderConfig[]>((result, provider) => {
    const configs = log.update.thirdPartyProviderConfigs

    if (typeof configs !== 'object') return result
    const config = configs[provider]
    if (typeof config !== 'object') return result

    result.push({
      provider,
      isEnabled: config.isEnabled === 'delete()' ? false : config.isEnabled,
      transportType: config.transportType === 'delete()' ? undefined : config.transportType,
    })

    return result
  }, [])
}
