import { FC, Fragment, ReactNode } from 'react'

interface Props {
  lines: ReactNode[]
}

export const FragmentWithBreaks: FC<Props> = (props) => {
  return (
    <>
      {props.lines
        .filter((line) => !!line)
        .map((line, index) => (
          <Fragment key={index}>
            {line}
            <br />
          </Fragment>
        ))}
    </>
  )
}
