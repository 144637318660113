import { Tooltip } from '@mui/material'
import { Task } from '@quickcommerceltd/zephyr-types'
import { FC, forwardRef, ReactElement } from 'react'
import { getTaskPickUpTooltip } from '../../Task/helpers/getTaskPickUpTooltip'
import { useSelectedWarehouseThirdPartyProviders } from '../../Warehouse/hooks/useSelectedWarehouseThirdPartyProviders'
import { SidebarTaskListItem } from './SidebarTaskListItem'

interface Props {
  task: Task
  isMoveToBottomSupported?: boolean
  pl?: number
}

export const SidebarTaskListItemWithTooltip: FC<Props> = (props) => {
  const thirdPartyProviders = useSelectedWarehouseThirdPartyProviders()
  const tooltip = getTaskPickUpTooltip(props.task, thirdPartyProviders)

  if (tooltip) {
    return (
      <Tooltip title={<div style={{ whiteSpace: 'pre-line' }}>{tooltip}</div>} placement="right">
        <Container>
          <SidebarTaskListItem {...props} />
        </Container>
      </Tooltip>
    )
  } else {
    return <SidebarTaskListItem {...props} />
  }
}

const Container = forwardRef<HTMLDivElement, { children: ReactElement }>((props, ref) => {
  return <div {...props} ref={ref} />
})
