import { DispatcherUpdateTaskRequest } from '@quickcommerceltd/zephyr-types'
import { useCallback, useMemo, useState } from 'react'
import toast from 'react-hot-toast'
import { updateTaskCallable } from '../../Auth/callables/task/updateTaskCallable'
import { logError } from '../../Common/helpers/logError'

export function useEditTask() {
  const [isEditingTask, setIsEditingTask] = useState(false)

  const editTask = useCallback(async (params: DispatcherUpdateTaskRequest) => {
    try {
      setIsEditingTask(true)

      await updateTaskCallable({
        taskId: params.taskId,
        warehouseId: params.warehouseId,
        externalOrderId: params.externalOrderId,
        externalOrderNumber: params.externalOrderNumber,
        ...(params.address && {
          address: {
            streetAddress1: params.address.streetAddress1,
            postalCode: params.address.postalCode,
            city: params.address.city,
            country: params.address.country,
            location: {
              latitude: params.address.location.latitude,
              longitude: params.address.location.longitude,
            },
            deliveryOption: params.address.deliveryOption,
          },
        }),
        ...(params.customer && {
          customer: {
            phone: params.customer.phone,
            lastName: params.customer.lastName,
            firstName: params.customer.firstName,
          },
        }),
        ...(params.noteFromCustomer && {
          noteFromCustomer: params.noteFromCustomer,
        }),
      })
    } catch (error: any) {
      toast.error(error.message)
      logError(error)
    } finally {
      setIsEditingTask(false)
    }
  }, [])

  return useMemo(
    () => ({
      editTask,
      isEditingTask,
    }),
    [editTask, isEditingTask]
  )
}
