import LoadingButton from '@mui/lab/LoadingButton'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider } from '@mui/material'
import { ThirdPartyProvider, WarehouseThirdPartyProviderConfigRecord } from '@quickcommerceltd/zephyr-types'
import { FC, useState } from 'react'
import { UseFormGetValues, UseFormSetValue } from 'react-hook-form'
import toast from 'react-hot-toast'
import { AppThemeProvider } from '../../App/components/AppThemeProvider'
import { updateWarehouseCallable } from '../../Auth/callables/warehouse/updateWarehouseCallable'
import { getThirdPartyProviderTranslation } from '../../Common/helpers/getThirdPartyProviderTranslation'
import { logError } from '../../Common/helpers/logError'
import { getAreProvidersActiveTimesInvalid } from '../helpers/getAreProvidersActiveTimesInvalid'
import { WarehouseUpsertFormValues } from '../types/WarehouseUpsertFormValues'

interface Props {
  isOpen: boolean
  provider: ThirdPartyProvider
  close: () => void
  setValue: UseFormSetValue<WarehouseUpsertFormValues>
  getValues: UseFormGetValues<WarehouseUpsertFormValues>
  warehouseId?: string
  isSubmitting?: boolean
}

export const SettingsDefaultConfirmationDialog: FC<Props> = (props) => {
  const [isSubmitting, setIsSubmitting] = useState(!!props.isSubmitting)

  const handleOnSubmit = async () => {
    const formConfig = props.getValues().warehouseThirdPartyProviderConfigs ?? {}
    if (formConfig[props.provider]) {
      try {
        if (getAreProvidersActiveTimesInvalid([props.provider], formConfig)) {
          toast.error("Time value 'To' should be greater than 'From'")
        } else {
          setIsSubmitting(true)
          const newDefaultConfig = {
            [props.provider]: {
              defaultActiveTimes: formConfig[props.provider]?.activeTimes,
              defaultAutoReassignmentPickupEtaFactor: formConfig[props.provider]?.autoReassignmentPickupEtaFactor,
              defaultAutoReassignmentMinimumThresholdInSeconds:
                formConfig[props.provider]?.autoReassignmentMinimumThresholdInSeconds,
              defaultIsTemporarilyDisabled: formConfig[props.provider]?.isTemporarilyDisabled,
            },
          }
          if (props.warehouseId) {
            await updateWarehouseCallable({
              warehouseId: props.warehouseId,
              thirdPartyProviderConfigs: newDefaultConfig,
              isThirdPartyProviderConfigsSetDefaultRequest: true,
            })
          }

          props.close()
        }
      } catch (error: any) {
        toast.error(error.message)
        logError(error)
      } finally {
        updateFormDefaultValues(formConfig)
        setIsSubmitting(false)
      }
    }
  }

  const updateFormDefaultValues = (formConfig: WarehouseThirdPartyProviderConfigRecord) => {
    props.setValue(
      `warehouseThirdPartyProviderConfigs.${props.provider}.defaultActiveTimes`,
      formConfig[props.provider]?.activeTimes
    )
    props.setValue(
      `warehouseThirdPartyProviderConfigs.${props.provider}.defaultAutoReassignmentMinimumThresholdInSeconds`,
      formConfig[props.provider]?.autoReassignmentMinimumThresholdInSeconds
    )
    props.setValue(
      `warehouseThirdPartyProviderConfigs.${props.provider}.defaultAutoReassignmentPickupEtaFactor`,
      formConfig[props.provider]?.autoReassignmentPickupEtaFactor
    )
    props.setValue(
      `warehouseThirdPartyProviderConfigs.${props.provider}.defaultIsTemporarilyDisabled`,
      formConfig[props.provider]?.isTemporarilyDisabled
    )
  }

  return (
    <AppThemeProvider mode="light">
      <Dialog open={props.isOpen} onClose={props.close} fullWidth>
        <DialogTitle>Replace Default Settings</DialogTitle>
        <Divider />
        <DialogContent>
          <DialogContentText>
            {`Are you sure you want to replace the ${getThirdPartyProviderTranslation(props.provider)} default
            settings?`}
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions>
          <LoadingButton
            variant="contained"
            loading={isSubmitting}
            onClick={async () => {
              await handleOnSubmit()
            }}
          >
            Replace
          </LoadingButton>
          <Button onClick={props.close} disabled={isSubmitting}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </AppThemeProvider>
  )
}
