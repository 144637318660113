import {
  isRider,
  isRiderLocationLog,
  isRiderLog,
  isTask,
  isTaskLog,
  isUser,
  isWarehouse,
  isWarehouseLog,
  Rider,
  RiderLocationLog,
  RiderLog,
  Task,
  TaskLog,
  User,
  Warehouse,
  WarehouseLog,
} from '@quickcommerceltd/zephyr-types'
import { QuerySnapshot } from 'firebase/firestore'

export function getDataFromCollectionSnapshot<T>(isT: (obj: unknown) => obj is T, snapshot?: QuerySnapshot<T>) {
  if (!snapshot) return []

  return snapshot.docs.reduce<T[]>((result, doc) => {
    if (doc.exists()) {
      const data = doc.data()

      if (isT(data)) {
        result.push(data)
      }
    }

    return result
  }, [])
}

export function getRidersFromSnapshot(snapshot?: QuerySnapshot<Rider>) {
  return getDataFromCollectionSnapshot(isRider, snapshot)
}

export function getRiderLogsFromSnapshot(snapshot?: QuerySnapshot<RiderLog>) {
  return getDataFromCollectionSnapshot(isRiderLog, snapshot)
}

export function getRiderLocationLogsFromSnapshot(snapshot?: QuerySnapshot<RiderLocationLog>) {
  return getDataFromCollectionSnapshot(isRiderLocationLog, snapshot)
}

export function getTasksFromSnapshot(snapshot?: QuerySnapshot<Task>) {
  return getDataFromCollectionSnapshot(isTask, snapshot)
}

export function getTaskLogsFromSnapshot(snapshot?: QuerySnapshot<TaskLog>) {
  return getDataFromCollectionSnapshot(isTaskLog, snapshot)
}

export function getUsersFromSnapshot(snapshot?: QuerySnapshot<User>) {
  return getDataFromCollectionSnapshot(isUser, snapshot)
}

export function getWarehousesFromSnapshot(snapshot?: QuerySnapshot<Warehouse>) {
  return getDataFromCollectionSnapshot(isWarehouse, snapshot)
}

export function getWarehouseLogsFromSnapshot(snapshot?: QuerySnapshot<WarehouseLog>) {
  return getDataFromCollectionSnapshot(isWarehouseLog, snapshot)
}
