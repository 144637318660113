import { Task } from '@quickcommerceltd/zephyr-types'
import { useMemo, useRef } from 'react'
import { useDocument } from 'react-firebase-hooks/firestore'
import { getTaskFromSnapshot } from '../../Auth/helpers/getDataFromDocumentSnapshot'
import { getTaskDocRef } from '../../Auth/helpers/getDocRef'
import { isZappTask } from '../../Common/helpers/isMarketplaceTask'

export function useTaskFromTaskId(taskId?: string): [Task | undefined, boolean] {
  const taskRef = useRef<Task>()
  const [snapshot, isLoading] = useDocument(getTaskDocRef(taskId || 'unknown'))

  useMemo(() => {
    if (snapshot?.metadata.hasPendingWrites) return
    const task = getTaskFromSnapshot(snapshot)

    // filter out marketplace orders as they are not actionable
    taskRef.current = isZappTask(task) ? task : undefined
  }, [snapshot])

  return [taskRef.current, taskRef.current ? false : isLoading]
}
