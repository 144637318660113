import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { ENVIRONMENT } from '../../App/constants/ENVIRONMENT'

export const app = initializeApp(ENVIRONMENT.firebaseConfig)
export const auth = getAuth(app)
export const db = getFirestore(app)
export const functions = getFunctions(app, ENVIRONMENT.appRegion)

/**
 * Get Firebase Callable Cloud Function
 */
export const getCallable = <RequestData, ResponseData>(
  name: string,
  isResponseData?: (obj: unknown) => obj is ResponseData
) => {
  const callable = httpsCallable<RequestData, ResponseData>(functions, name)

  return async (request: RequestData) => {
    const result = await callable(request)

    if (isResponseData) {
      if (!isResponseData(result.data)) {
        throw Error('Invalid response data')
      }
    }

    return result.data
  }
}
