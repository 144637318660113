import CancelIcon from '@mui/icons-material/Cancel'
import SearchIcon from '@mui/icons-material/Search'
import { Box, IconButton, InputAdornment, SxProps, TextField } from '@mui/material'
import { FC, useRef } from 'react'
import { COLOR } from '../../App/constants/COLOR'
import { EscapeKeyToClose } from '../../Common/components/EscapeKeyToClose'

interface Props {
  query: string
  setQuery: (query: string) => void
  placeholder?: string
  sx?: SxProps
  fullWidth?: boolean
  'data-testid'?: string
}

export const SearchTextField: FC<Props> = (props) => {
  const textFieldRef = useRef<HTMLDivElement>(null)
  const closeRef = useRef(() => props.setQuery(''))

  return (
    <>
      {!!props.query && <EscapeKeyToClose close={closeRef.current} />}
      <TextField
        sx={props.sx}
        inputRef={textFieldRef}
        data-testid={props['data-testid']}
        fullWidth={props.fullWidth}
        value={props.query}
        placeholder={props.placeholder}
        onChange={(event) => props.setQuery(event.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Box onClick={() => textFieldRef.current?.focus()}>
                <SearchIcon sx={{ color: COLOR.gray[200] }} />
              </Box>
            </InputAdornment>
          ),
          endAdornment: props.query && (
            <InputAdornment position="end">
              <IconButton
                onClick={() => {
                  props.setQuery('')
                  textFieldRef.current?.focus()
                }}
              >
                <CancelIcon sx={{ color: COLOR.gray[200] }} />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </>
  )
}
