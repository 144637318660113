import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import { Alert, Box, Button, IconButton, Tooltip } from '@mui/material'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { User } from '@quickcommerceltd/zephyr-types'
import { FC, useMemo, useState } from 'react'
import { useHasPermission } from '../../Auth/hooks/useHasPermission'
import { DataGridPaper } from '../../Common/components/DataGridPaper'
import { LoadingView } from '../../Common/components/LoadingView'
import { SearchTextField } from '../../Common/components/SearchTextField'
import { ZAvatar } from '../../Common/components/ZAvatar'
import { isQueryInFields } from '../../Sidebar/helpers/isQueryInFields'
import { getUserRoleTranslation } from '../../User/helpers/getUserRoleTranslation'
import { useUsers } from '../../User/hooks/useUsers'
import { useWarehouses } from '../../Warehouse/hooks/useWarehouses'
import { SettingsTitleView } from './SettingsTitleView'
import { SettingsUserDeleteDialog } from './SettingsUserDeleteDialog'
import { SettingsUserUpsertDialog } from './SettingsUserUpsertDialog'

export const SettingsUsersView: FC = () => {
  const [query, setQuery] = useState('')
  const [allUsers, isLoading, error] = useUsers()
  const hasPermission = useHasPermission()
  const [selectedUser, setSelectedUser] = useState<User>()
  const [isUserUpsertDialogOpen, setIsUserUpsertDialogOpen] = useState(false)
  const [isUserDeleteDialogOpen, setIsUserDeleteDialogOpen] = useState(false)
  const isLoaded = !isLoading && !error
  const warehouses = useWarehouses((state) => state.warehouses)

  const filteredUsers = useMemo(() => {
    if (!query) return allUsers
    return allUsers.filter((user) => isQueryInFields(query, [user.id, user.name, user.email]))
  }, [allUsers, query])

  const columns = useMemo<GridColDef<User>[]>(
    () => [
      {
        field: 'name',
        headerName: 'Name',
        width: 300,
        valueGetter: (params) => params.row.name || 'None',
        renderCell: (params) => (
          <>
            <ZAvatar name={params.row.name} alt={params.row.name} sx={{ mr: 2 }} />
            {params.row.name || <em>None</em>}
          </>
        ),
      },
      {
        field: 'email',
        headerName: 'Email',
        width: 250,
      },
      {
        field: 'role',
        headerName: 'Role',
        width: 150,
        valueGetter: (params) => params.row.role || 'None',
        renderCell: (params) => (params.row.role ? getUserRoleTranslation(params.row.role) : <em>None</em>),
      },
      {
        field: 'warehouseIds',
        headerName: 'Assigned Stores',
        width: 200,
        renderCell: (params) => {
          if (
            ['ADMIN', 'CUSTOMER_SERVICE'].includes(params.row.role) ||
            params.row.warehouseIds?.length === warehouses.length
          ) {
            return 'All Stores'
          }

          const shortNames = params.row.warehouseIds
            ?.map((warehouseId) => warehouses.find((wh) => wh.id === warehouseId)?.shortName)
            .filter((shortName) => shortName)
            .join(', ')

          if (!shortNames) return

          // if less then 5 shortNames don't show tooltip
          if (shortNames.length < 23) return shortNames

          return (
            <Tooltip title={shortNames} placement="left">
              <span>{shortNames.slice(0, 23) + '...'}</span>
            </Tooltip>
          )
        },
      },
      {
        field: 'actions',
        headerName: '',
        width: 180,
        filterable: false,
        sortable: false,
        disableColumnMenu: true,
        hide: !hasPermission('UPSERT_USER'),
        valueGetter: (params) => params.row.id,
        renderCell: (params) => (
          <Box className="row-action">
            <IconButton
              onClick={() => {
                setSelectedUser(params.row)
                setIsUserUpsertDialogOpen(true)
              }}
            >
              <EditOutlinedIcon color="secondary" />
            </IconButton>
            <IconButton
              onClick={() => {
                setSelectedUser(params.row)
                setIsUserDeleteDialogOpen(true)
              }}
            >
              <DeleteOutlinedIcon color="secondary" />
            </IconButton>
          </Box>
        ),
      },
    ],
    [hasPermission, warehouses]
  )

  return (
    <Box display="flex" flex={1} flexDirection="column">
      <Box display="flex" flexDirection="row">
        <SettingsTitleView>Users</SettingsTitleView>
        <Box flex={1} />
        <SearchTextField
          query={query}
          setQuery={setQuery}
          sx={{ width: 320, mx: 2 }}
          placeholder="Search for ID, name, email"
        />
        {hasPermission('UPSERT_USER') && (
          <Button variant="contained" onClick={() => setIsUserUpsertDialogOpen(true)}>
            Add User
          </Button>
        )}
      </Box>
      {isLoading && <LoadingView />}
      {error && (
        <Alert sx={{ mt: 2 }} severity="error">
          {error.message}
        </Alert>
      )}
      {isLoaded && (
        <DataGridPaper>
          <DataGrid
            columns={columns}
            rows={filteredUsers}
            getRowId={(user) => user.id}
            disableColumnSelector
            disableSelectionOnClick
          />
        </DataGridPaper>
      )}
      <SettingsUserUpsertDialog
        key={`upsert-${selectedUser?.id}`}
        user={selectedUser}
        isOpen={isUserUpsertDialogOpen}
        close={() => {
          setSelectedUser(undefined)
          setIsUserUpsertDialogOpen(false)
        }}
      />
      <SettingsUserDeleteDialog
        key={`delete-${selectedUser?.id}`}
        user={selectedUser}
        isOpen={isUserDeleteDialogOpen}
        close={() => {
          setSelectedUser(undefined)
          setIsUserDeleteDialogOpen(false)
        }}
      />
    </Box>
  )
}
