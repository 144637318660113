import { DateTime } from 'luxon'
import { Log } from '../types/Log'

interface Props {
  log: Log
  isFirstItem: boolean
  isLastItem: boolean
  onClick: () => void
}

export function getLogListItemProps(props: Props) {
  return {
    createdAt: props.log.createdAt,
    createdByName: props.log.updatedBy.name,
    message: `${DateTime.fromMillis(props.log.createdAt?.toMillis()).toLocaleString(
      DateTime.DATETIME_FULL_WITH_SECONDS
    )} by ${props.log.updatedBy.name}`,
    isFirstItem: props.isFirstItem,
    isLastItem: props.isLastItem,
    onClick: props.onClick,
  }
}
