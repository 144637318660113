import { Rider } from '@quickcommerceltd/zephyr-types'
import { useMemo, useRef } from 'react'
import { useDocument } from 'react-firebase-hooks/firestore'
import { getRiderFromSnapshot } from '../../Auth/helpers/getDataFromDocumentSnapshot'
import { getRiderDocRef } from '../../Auth/helpers/getDocRef'

export function useRiderFromRiderId(riderId?: string): [Rider | undefined, boolean] {
  const riderRef = useRef<Rider>()
  const [snapshot, isLoading] = useDocument(getRiderDocRef(riderId || 'unknown'))

  useMemo(() => {
    if (snapshot?.metadata.hasPendingWrites) return
    riderRef.current = getRiderFromSnapshot(snapshot)
  }, [snapshot])

  return [riderRef.current, riderRef.current ? false : isLoading]
}
