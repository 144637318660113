import { InfoOutlined } from '@mui/icons-material'
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import { Alert, Box, Button, IconButton, InputAdornment, Stack, Tooltip, Typography } from '@mui/material'
import { ThirdPartyProvider, ThirdPartyProviderActiveTime } from '@quickcommerceltd/zephyr-types'
import { DateTime } from 'luxon'
import { useCallback, useMemo, useState } from 'react'
import { Control, UseFormGetValues, UseFormSetValue, UseFormWatch } from 'react-hook-form'
import { v4 } from 'uuid'
import { ControlledSelect } from '../../Common/components/ControlledSelect'
import { ControlledSwitch } from '../../Common/components/ControlledSwitch'
import { ControlledTextField } from '../../Common/components/ControlledTextField'
import { getThirdPartyProviderTranslation } from '../../Common/helpers/getThirdPartyProviderTranslation'
import { getTransportTypeTranslation } from '../../Common/helpers/getTransportTypeTranslation'
import { TRANSPORT_TYPES } from '../constants/TRANSPORT_TYPES'
import { isTransportTypeSupported } from '../helpers/isTransportTypeSupported'
import { WarehouseUpsertFormValues } from '../types/WarehouseUpsertFormValues'
import { SettingsDefaultConfirmationDialog } from './SettingsDefaultConfirmationDialog'

interface Props {
  provider: ThirdPartyProvider
  isLoading: boolean
  control: Control<WarehouseUpsertFormValues>
  setValue: UseFormSetValue<WarehouseUpsertFormValues>
  getValues: UseFormGetValues<WarehouseUpsertFormValues>
  hasPermission: boolean
  warehouseId?: string
  isSubmitting?: boolean
  watch: UseFormWatch<WarehouseUpsertFormValues>
}

const WEEKDAYS = [1, 2, 3, 4, 5, 6, 7]

const reassignCoefValues = [1.0, 1.1, 1.2, 1.3, 1.4, 1.5, 1.6, 1.7, 1.8, 1.9, 2.0, 2.1, 2.2, 2.3, 2.4, 2.5].map(
  (value) => ({
    value,
    label: `${Math.round(value * 100)}%`,
  })
)

const reassignmentMinimumInfo =
  'Minimum duration in seconds before the auto reassignment function is triggered, even if the task is overdue.'
const reassignmentDelayInfo =
  'A coefficient applied to the received pickup ETA. If a task is not assigned within this time, it is reassigned. For example, if the estimated pickup time is 10 minutes and the coefficient is 150%, the task will be reassigned after 15 minutes.'

export function SettingsWarehouseActiveTimesView(props: Props) {
  const { provider, control, isLoading, getValues, setValue, hasPermission } = props
  const [, setRenderCount] = useState(0)
  const rerender = () => setRenderCount((prev) => prev + 1)
  const config = getValues().warehouseThirdPartyProviderConfigs?.[provider]
  const activeTimes = config?.activeTimes || []
  const [isDefaultConfirmationDialogOpen, setIsDefaultConfirmationDialogOpen] = useState(false)
  const newActiveTime: ThirdPartyProviderActiveTime = useMemo(
    () => ({
      from: '00:00',
      to: '24:00',
      weekday: DateTime.local().weekday,
      transportType: 'BIKE',
    }),
    []
  )

  const setProviderActiveTimes = useCallback(
    (activeTimes: ThirdPartyProviderActiveTime[]) => {
      setValue(`warehouseThirdPartyProviderConfigs.${provider}.activeTimes`, activeTimes)
      rerender()
    },
    [provider, setValue]
  )

  const setProviderMinimumThreshold = useCallback(
    (threshold: number) => {
      setValue(`warehouseThirdPartyProviderConfigs.${provider}.autoReassignmentMinimumThresholdInSeconds`, threshold)
      rerender()
    },
    [provider, setValue]
  )

  const setProviderPickupFactor = useCallback(
    (factor: number) => {
      setValue(`warehouseThirdPartyProviderConfigs.${provider}.autoReassignmentPickupEtaFactor`, factor)
      rerender()
    },
    [provider, setValue]
  )

  const setProviderDisabled = useCallback(
    (isDisabled: boolean) => {
      setValue(`warehouseThirdPartyProviderConfigs.${provider}.isTemporarilyDisabled`, isDisabled)
      rerender()
    },
    [provider, setValue]
  )

  return (
    <Stack spacing={2} bgcolor="grey.50" padding={1} borderRadius={2}>
      <Stack direction="row" justifyContent="space-between" flex={1}>
        <ControlledSwitch
          control={control}
          disabled={isLoading}
          color="warning"
          label="Temporarily Disabled"
          name={`warehouseThirdPartyProviderConfigs.${provider}.isTemporarilyDisabled`}
        />
        <Stack direction="row" spacing={1}>
          <Button
            disabled={isLoading}
            onClick={() => {
              setProviderMinimumThreshold(config?.defaultAutoReassignmentMinimumThresholdInSeconds || 240)
              setProviderPickupFactor(config?.defaultAutoReassignmentPickupEtaFactor || 1.5)
              setProviderDisabled(config?.defaultIsTemporarilyDisabled || false)
              setProviderActiveTimes(config?.defaultActiveTimes?.length ? config?.defaultActiveTimes : [])
            }}
          >
            Reset to Default
          </Button>
          {hasPermission && (
            <Button onClick={() => setIsDefaultConfirmationDialogOpen(true)} disabled={isLoading}>
              Save as Default
            </Button>
          )}
        </Stack>
      </Stack>

      <Stack spacing={2} marginTop={1}>
        <Typography fontWeight="bold" fontSize={16}>
          Reassignment Settings
        </Typography>
        <Stack direction="row" sx={{ gridGap: '10px', alignItems: 'center' }}>
          <ControlledTextField
            control={control}
            disabled={isLoading}
            name={`warehouseThirdPartyProviderConfigs.${provider}.autoReassignmentMinimumThresholdInSeconds`}
            label="Minimum Duration Before Reassignment"
            type="number"
            endAdornment={
              <InputAdornment position="end" sx={{ marginRight: 1 }}>
                <Typography color={isLoading ? 'grey.500' : undefined}>seconds</Typography>
              </InputAdornment>
            }
            sx={{ width: '50%' }}
          />

          <Tooltip title={reassignmentMinimumInfo}>
            <IconButton>
              <InfoOutlined />
            </IconButton>
          </Tooltip>
        </Stack>
        <Stack direction="row" sx={{ gridGap: '10px', alignItems: 'center' }}>
          <ControlledSelect
            control={control}
            disabled={isLoading}
            items={reassignCoefValues}
            getValue={(k) => k.value}
            getName={(k) => k.label}
            name={`warehouseThirdPartyProviderConfigs.${provider}.autoReassignmentPickupEtaFactor`}
            label="3P Auto Reassignment Multiplier"
            sx={{ width: '50%' }}
          />

          <Tooltip title={reassignmentDelayInfo}>
            <IconButton>
              <InfoOutlined />
            </IconButton>
          </Tooltip>
        </Stack>
      </Stack>

      <Stack>
        <Typography fontWeight="bold" fontSize={16}>
          Active Times
        </Typography>

        <Stack alignItems="flex-start">
          {!activeTimes.length && (
            <Alert severity="info">{getThirdPartyProviderTranslation(provider)} is disabled</Alert>
          )}

          {activeTimes
            .sort(
              (a, b) =>
                DateTime.fromFormat(a.from, 'HH:mm').toMillis() - DateTime.fromFormat(b.from, 'HH:mm').toMillis()
            )
            .sort((a, b) => a.weekday - b.weekday)
            .map((_, index) => (
              <Stack key={v4()} spacing={1} mt={1.5} direction="row">
                <ControlledSelect
                  control={control}
                  name={`warehouseThirdPartyProviderConfigs.${provider}.activeTimes.${index}.weekday`}
                  fullWidth
                  label="Weekday"
                  items={WEEKDAYS}
                  disabled={isLoading}
                  onClick={rerender}
                  getValue={(weekday) => weekday}
                  getName={(weekday) => DateTime.local().set({ weekday }).weekdayLong}
                />
                <ControlledTextField
                  name={`warehouseThirdPartyProviderConfigs.${provider}.activeTimes.${index}.from`}
                  label="From"
                  type="time"
                  control={control}
                  disabled={isLoading}
                  fullWidth
                />
                <ControlledTextField
                  name={`warehouseThirdPartyProviderConfigs.${provider}.activeTimes.${index}.to`}
                  label="To"
                  type="time"
                  control={control}
                  disabled={isLoading}
                  fullWidth
                />
                {isTransportTypeSupported(provider) && (
                  <ControlledSelect
                    name={`warehouseThirdPartyProviderConfigs.${provider}.activeTimes.${index}.transportType`}
                    label="Transport"
                    control={control}
                    disabled={isLoading}
                    items={TRANSPORT_TYPES}
                    getValue={(transportType) => transportType}
                    getName={(transportType) => getTransportTypeTranslation(transportType)}
                    fullWidth
                  />
                )}
                <Box>
                  <IconButton
                    disabled={isLoading}
                    onClick={() => setProviderActiveTimes(activeTimes.filter((_, i) => i !== index))}
                  >
                    <DeleteOutlinedIcon />
                  </IconButton>
                </Box>
              </Stack>
            ))}
          <Button
            disabled={isLoading}
            onClick={() => {
              const nextActiveTimes = activeTimes?.length
                ? [...activeTimes, newActiveTime].sort((a, b) => a.weekday - b.weekday)
                : [newActiveTime]

              setProviderActiveTimes(nextActiveTimes)
            }}
            startIcon={<AddCircleOutlineOutlinedIcon />}
          >
            Add hours
          </Button>
        </Stack>
        <SettingsDefaultConfirmationDialog
          key={`upsert-${provider}`}
          provider={provider}
          isOpen={isDefaultConfirmationDialogOpen}
          close={() => {
            setIsDefaultConfirmationDialogOpen(false)
          }}
          getValues={getValues}
          setValue={setValue}
          warehouseId={props.warehouseId}
          isSubmitting={props.isSubmitting}
        />
      </Stack>
    </Stack>
  )
}
