import {
  Checkbox,
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  Radio,
  Select,
  SxProps,
  Theme,
} from '@mui/material'
import { useCallback } from 'react'
import { Control, Controller, FieldPath, FieldValues } from 'react-hook-form'

interface Props<T extends FieldValues, I> {
  control: Control<T>
  name: FieldPath<T>
  label: string
  helperText?: string
  items: I[]
  getName: (item: I) => string
  getValue: (item: I) => string | number
  sx?: SxProps<Theme>
  required?: boolean
  fullWidth?: boolean
  placeholder?: string
  multiple?: boolean
  canSelectAll?: boolean
  allSelectedLabel?: string
  disabled?: boolean
  disabledLabel?: string
  onClick?: () => void
}

export function ControlledSelect<T extends FieldValues, I>(props: Props<T, I>) {
  const isDisabledLabelVisible = props.disabled && !!props.disabledLabel
  const getNameFromValue = useCallback(
    (value: unknown) => {
      const item = props.items.find((i) => props.getValue(i) === value)

      return item && props.getName(item)
    },
    [props]
  )

  const renderValue = useCallback(
    (valueOrValues: unknown) => {
      if (isDisabledLabelVisible) {
        return props.disabledLabel
      } else if (props.multiple) {
        const values = valueOrValues as string[]

        if (values.length === props.items.length) {
          return props.allSelectedLabel || 'All'
        } else {
          return values.map((v) => getNameFromValue(v) || 'Unknown').join(', ')
        }
      } else {
        return getNameFromValue(valueOrValues)
      }
    },
    [
      isDisabledLabelVisible,
      props.multiple,
      props.disabledLabel,
      props.items.length,
      props.allSelectedLabel,
      getNameFromValue,
    ]
  )

  const hasSelectedAll = useCallback(
    (fieldValues: unknown) => (fieldValues as unknown[]).length === props.items.length,
    [props.items.length]
  )

  return (
    <FormControl
      sx={props.sx}
      size="small"
      fullWidth={props.fullWidth}
      required={props.required}
      disabled={props.disabled}
      data-testid={`ControlledSelect-${props.name}`}
    >
      <Controller
        name={props.name}
        control={props.control}
        rules={{ required: props.required ? 'Required' : false }}
        render={({ field, fieldState }) => (
          <>
            <InputLabel error={!!fieldState.error}>{props.label}</InputLabel>
            <Select
              {...field}
              placeholder={props.placeholder}
              multiple={props.multiple}
              label={props.label}
              displayEmpty
              disabled={props.disabled}
              value={isDisabledLabelVisible ? [''] : field.value}
              error={!!fieldState.error}
              renderValue={renderValue}
            >
              {props.multiple && props.canSelectAll && (
                <MenuItem
                  key="all"
                  onClick={() => {
                    field.onChange(hasSelectedAll(field.value) ? [] : props.items.map(props.getValue))
                  }}
                >
                  <Checkbox checked={hasSelectedAll(field.value)} />
                  <ListItemText primary={<em>Select all</em>} />
                </MenuItem>
              )}
              {props.items.map((item) => (
                <MenuItem key={props.getValue(item)} value={props.getValue(item)} onClick={props.onClick}>
                  {props.multiple && (
                    <Checkbox checked={(field.value as unknown[]).indexOf(props.getValue(item)) > -1} />
                  )}
                  {!props.multiple && <Radio checked={field.value === props.getValue(item)} />}
                  <ListItemText primary={props.getName(item)} />
                </MenuItem>
              ))}
            </Select>
            {!!fieldState.error && (
              <FormHelperText error={!!fieldState.error}>
                {props.helperText ? props.helperText : props.required && 'Required'}
              </FormHelperText>
            )}
          </>
        )}
      />
    </FormControl>
  )
}
