import { createTheme } from '@mui/material'
import { COLOR } from './COLOR'

export const THEME_LIGHT_MODE = createTheme({
  palette: {
    mode: 'light',
    text: {
      primary: COLOR.blue[900],
    },
    background: {
      default: COLOR.blue[10],
      paper: COLOR.white[0],
    },
    primary: {
      main: COLOR.blue[500],
      light: COLOR.blue[300],
    },
    secondary: {
      main: COLOR.blue[900],
    },
    divider: COLOR.gray[50],
    warning: {
      main: COLOR.red[1000],
    },
  },
  typography: {
    body1: {
      fontSize: 14,
    },
  },
  components: {
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: 24,
          justifyContent: 'flex-start',
        },
      },
    },
    MuiDialogContentText: {
      styleOverrides: {
        root: {
          fontWeight: 500,
          color: COLOR.rock[500],
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          backgroundColor: COLOR.blue[900],
          color: COLOR.blue[50],
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'unset',
          paddingLeft: 16,
          paddingRight: 16,
        },
        containedPrimary: {
          color: COLOR.white[0],
          boxShadow: 'none',
        },
        containedInherit: {
          backgroundColor: COLOR.blue[50],
          '&:hover': {
            backgroundColor: COLOR.blue[100],
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: COLOR.white[0],
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          margin: 0,
          fontSize: 10,
        },
      },
    },
    ...({
      MuiTabPanel: {
        styleOverrides: {
          root: {
            padding: 0,
          },
        },
      },
    } as any),
  },
})
