import create from 'zustand'

interface Store {
  taskId?: string
  isDialogOpen: boolean
  closeDialog?: () => void
  openDialog: (taskId: string) => Promise<void>
}

export const useUnassignTaskFromThirdPartyProviderDialog = create<Store>((set) => ({
  isDialogOpen: false,
  openDialog: (taskId) => {
    return new Promise((resolve) => {
      set({
        taskId,
        isDialogOpen: true,
        closeDialog: () => {
          set({ isDialogOpen: false })
          resolve()
        },
      })
    })
  },
}))
