import { Rider } from '@quickcommerceltd/zephyr-types'
import { useCallback, useState } from 'react'
import toast from 'react-hot-toast'
import { deleteRiderCallable } from '../../Auth/callables/rider/deleteRiderCallable'
import { logError } from '../../Common/helpers/logError'

export function useDeleteRider(): [(rider: Rider) => Promise<void>, boolean] {
  const [isDeletingRider, setIsDeletingRider] = useState(false)

  const deleteRider = useCallback(async (rider: Rider) => {
    try {
      setIsDeletingRider(true)

      await deleteRiderCallable({ riderId: rider.id })
    } catch (error: any) {
      toast.error(error.message)
      logError(error)
    } finally {
      setIsDeletingRider(false)
    }
  }, [])

  return [deleteRider, isDeletingRider]
}
