import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider } from '@mui/material'
import { FC, useCallback } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { AppThemeProvider } from '../../App/components/AppThemeProvider'
import { getWarehouseLogCollectionRef } from '../../Auth/helpers/getCollectionRef'
import { getWarehouseLogsFromSnapshot } from '../../Auth/helpers/getDataFromQuerySnapshot'
import { LogListView } from '../../Log/components/LogListView'
import { WarehouseLogListItem } from '../../Log/components/WarehouseLogListItem'

export const WarehouseLogsDialog: FC = () => {
  const navigate = useNavigate()
  const warehouseId = useParams<'warehouseId'>().warehouseId || 'unknown'

  const closeDialog = useCallback(() => {
    navigate('/settings/stores')
  }, [navigate])

  return (
    <AppThemeProvider mode="light">
      <Dialog onClose={closeDialog} fullWidth open>
        <form>
          <Box my={2} mx={3} display="flex" justifyContent="space-between">
            <DialogTitle sx={{ p: 0, alignSelf: 'center' }}>Store Logs</DialogTitle>
          </Box>
          <Divider />
          <DialogContent>
            <LogListView
              collectionRef={getWarehouseLogCollectionRef(warehouseId)}
              getLogsFromSnapshot={getWarehouseLogsFromSnapshot}
              LogListItem={WarehouseLogListItem}
              limit={20}
            />
          </DialogContent>
          <Divider />
          <DialogActions>
            <Button variant="contained" onClick={closeDialog} autoFocus>
              Close
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </AppThemeProvider>
  )
}
