import LoadingButton from '@mui/lab/LoadingButton'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider } from '@mui/material'
import { Task } from '@quickcommerceltd/zephyr-types'
import { FC } from 'react'
import { AppThemeProvider } from '../../App/components/AppThemeProvider'
import { useDeleteTask } from '../hooks/useDeleteTask'

interface Props {
  task: Task
  isOpen: boolean
  close: () => void
}

export const TaskDeleteDialog: FC<Props> = (props) => {
  const [deleteTask, isDeletingTask] = useDeleteTask()

  return (
    <AppThemeProvider mode="light">
      <Dialog open={props.isOpen} onClose={props.close} fullWidth>
        <DialogTitle>Delete Task</DialogTitle>
        <Divider />
        <DialogContent>
          <DialogContentText>Are you sure you want to delete this task?</DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions>
          <LoadingButton
            color="error"
            variant="contained"
            loading={isDeletingTask}
            data-testid="ConfirmDeleteTaskButton"
            onClick={async () => {
              await deleteTask(props.task)
              props.close()
            }}
          >
            Delete
          </LoadingButton>
          <Button onClick={props.close} autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </AppThemeProvider>
  )
}
