import { TaskFailureReason } from '@quickcommerceltd/zephyr-types'

export function getTaskFailureReasonTranslation(reason: TaskFailureReason) {
  switch (reason) {
    case 'DELIVERY_INCIDENT':
      return 'Driver / vehicle incident'
    case 'DESTINATION_INCORRECT':
      return 'Destination unreachable'
    case 'ITEM_DAMAGED':
      return 'Refused - damage'
    case 'ITEM_INCORRECT':
      return 'Refused - incorrect / missing items'
    case 'ORDER_CANCELLED_BY_CUSTOMER':
      return 'Order cancelled by customer'
    case 'ORDER_CANCELLED_BY_STORE':
      return 'Order cancelled by store'
    case 'AGE_VERIFICATION_FAILED':
      return 'Age verification failed'
    case 'WRONG_ORDER_TAKEN':
      return 'Wrong order taken'
    case 'NO_3P_RIDER_AVAILABLE':
      return 'No 3P rider available'
    case 'UNAVAILABLE':
      return 'Recipient unavailable'
    case 'PACKAGE_NOT_READY':
      return 'Package not ready'
    case 'OTHER':
      return 'Other'
  }
}
