import { Typography } from '@mui/material'
import { FC, ReactNode } from 'react'

interface Props {
  children: ReactNode
}

export const SettingsTitleView: FC<Props> = (props) => {
  return (
    <Typography variant="h2" fontWeight={700} fontSize={30}>
      {props.children}
    </Typography>
  )
}
