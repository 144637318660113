export const COLOR = {
  blue: {
    10: '#F5FCFF',
    50: '#E5F8FF',
    100: '#CCF1FF',
    200: '#99E3FF',
    300: '#66D5FF',
    400: '#33C7FF',
    500: '#02BAFF',
    600: '#0295CC',
    700: '#017099',
    800: '#002C3C',
    900: '#002533',
    1000: '#1B1464',
  },
  gray: {
    50: '#EBEBEB',
    100: '#D4D4D4',
    200: '#BBBBBB',
    300: '#999999',
    400: '#7E7E7E',
    500: '#666666',
    600: '#555555',
    700: '#464646',
    800: '#393939',
    900: '#292B30',
  },
  green: {
    500: '#00CC93',
  },
  orange: {
    500: '#F59401',
  },
  red: {
    500: '#D83232',
    1000: '#FF3333',
  },
  rock: {
    300: '#4B4C59',
    400: '#404258',
    500: '#2B2D3D',
    800: '#1A1C26',
  },
  yellow: {
    500: '#FFE000',
  },
  white: {
    0: '#FFFFFF',
  },
  violet: {
    500: '#DE00EE',
  },
}
