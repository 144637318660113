import { useCallback } from 'react'
import { useMyUser } from '../../User/hooks/useMyUser'
import { PERMISSION_ROLES } from '../constants/PERMISSION_ROLES'
import { Permission } from '../types/Permission'

export function useHasPermission() {
  const userRole = useMyUser((store) => store.user?.role)

  return useCallback(
    (permission: Permission) => {
      if (userRole) {
        return PERMISSION_ROLES[permission].includes(userRole)
      }

      return false
    },
    [userRole]
  )
}
