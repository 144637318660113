import { Typography } from '@mui/material'
import { Rider } from '@quickcommerceltd/zephyr-types'
import { Marker } from '@react-google-maps/api'
import { FC, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Coords } from '../../Common/types/Coords'
import { RiderDescriptionView } from '../../Rider/components/RiderDescriptionView'
import { getRiderName } from '../../Rider/helpers/getRiderName'
import { useHoverRider } from '../../Rider/hooks/useHoverRider'
import { useTasksOnRidersCurrentJourney } from '../../Task/hooks/useTasksOnRidersCurrentJourney'
import { getRiderMarkerIcon } from '../helpers/getRiderMarkerIcon'
import { toLatLng } from '../helpers/toLatLng'
import { useMap } from '../hooks/useMap'
import { MapInfoBox } from './MapInfoBox'
import { MapRiderPath } from './MapRiderPath'

interface Props {
  rider: Rider
  location: Coords
}

export const MapRiderMarker: FC<Props> = (props) => {
  const navigate = useNavigate()
  const [isInfoVisible, setIsInfoVisible] = useState(false)
  const isFocused = useMap((store) => store.focusedRiderId === props.rider.id)
  const isUnfocused = useMap((store) => !!store.focusedRiderId && store.focusedRiderId !== props.rider.id)
  const tasks = useTasksOnRidersCurrentJourney(props.rider)
  const { onMouseOut, onMouseOver } = useHoverRider(tasks, props.rider?.id)

  return (
    <>
      <Marker
        opacity={isUnfocused ? 0.15 : 1}
        zIndex={3}
        position={toLatLng(props.location)}
        options={{ anchorPoint: new google.maps.Point(0.5, 0.5) }}
        icon={getRiderMarkerIcon(isFocused, props.rider)}
        onClick={() => navigate(`/riders/${props.rider.id}`)}
        onMouseOver={() => {
          setIsInfoVisible(true)
          onMouseOver()
        }}
        onMouseOut={() => {
          setIsInfoVisible(false)
          onMouseOut()
        }}
      />
      {isInfoVisible && (
        <MapInfoBox type="RIDER" location={props.location} isHidden={false}>
          <Typography fontWeight={700} fontSize={12} whiteSpace="nowrap">
            {getRiderName(props.rider)}
          </Typography>
          <Typography fontSize={12} fontWeight={500} sx={{ opacity: 0.75 }} whiteSpace="nowrap">
            <RiderDescriptionView rider={props.rider} />
          </Typography>
        </MapInfoBox>
      )}
      <MapRiderPath rider={props.rider} location={props.location} isFocused={isFocused} />
    </>
  )
}
