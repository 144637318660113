import { TaskCloneReason } from '@quickcommerceltd/zephyr-types'

export function getTaskCloneReasonTranslation(reason: TaskCloneReason) {
  switch (reason) {
    case 'DELIVERY_INCIDENT':
      return 'Driver / vehicle incident'
    case 'DESTINATION_INCORRECT':
      return 'Destination unreachable'
    case 'ITEM_DAMAGED':
      return 'Refused - damage'
    case 'ITEM_INCORRECT':
      return 'Refused - incorrect / missing items'
    case 'WRONG_ORDER_TAKEN':
      return 'Wrong order taken'
    case 'OVER_WEIGHT_LIMIT':
      return 'Over weight limit'
    case 'UNAVAILABLE':
      return 'Recipient unavailable'
  }
}
