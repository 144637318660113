import { AppConfig, Rider, Task, User, WarehouseRiderLocation } from '@quickcommerceltd/zephyr-types'
import { doc, DocumentReference } from 'firebase/firestore'
import { db } from '../../Auth/helpers/firebase'
import { CollectionName } from '../types/CollectionName'

function getDocRef<T>(collectionName: CollectionName, docId: string) {
  return doc(db, collectionName, docId) as DocumentReference<T>
}

export function getAppConfigDocRef() {
  return getDocRef<AppConfig>('appConfig', 'appConfig')
}

export function getRiderDocRef(docId: string) {
  return getDocRef<Rider>('riders', docId)
}

export function getTaskDocRef(docId: string) {
  return getDocRef<Task>('tasks', docId)
}

export function getUserDocRef(docId: string) {
  return getDocRef<User>('users', docId)
}

export function getWarehouseRiderLocationDocRef(docId: string) {
  return getDocRef<WarehouseRiderLocation>('warehouseRiderLocations', docId)
}
