import CircleIcon from '@mui/icons-material/Circle'
import { WarehouseStatus } from '@quickcommerceltd/zephyr-types'
import { FC } from 'react'
import { COLOR } from '../../App/constants/COLOR'

const DEFAULT_ICON_SIZE = 10

interface Props {
  warehouseStatus: WarehouseStatus
  size?: number
}

export const WarehouseStatusIcon: FC<Props> = (props) => {
  const fontSize = props.size || DEFAULT_ICON_SIZE

  switch (props.warehouseStatus) {
    case 'CLOSED':
      return <CircleIcon sx={{ color: COLOR.red[500], fontSize }} />
    case 'DRAFT':
      return <CircleIcon sx={{ color: COLOR.gray[200], fontSize }} />
    case 'OPEN':
      return <CircleIcon sx={{ color: COLOR.green[500], fontSize }} />
  }
}
