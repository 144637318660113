export function toggleArray<T>(value: T, values: T[], all: T[]) {
  if (values.includes(value)) {
    return values.filter((v) => v !== value)
  } else {
    const next = [...values, value]

    if (next.length === all.length) {
      return undefined
    } else {
      return [...values, value]
    }
  }
}
