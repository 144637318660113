import { RiderLocationLog } from '@quickcommerceltd/zephyr-types'
import { onSnapshot, orderBy, query, where } from 'firebase/firestore'
import { DateTime } from 'luxon'
import toast from 'react-hot-toast'
import create from 'zustand'
import { getRiderLocationLogCollectionRef } from '../../Auth/helpers/getCollectionRef'
import { getRiderLocationLogsFromSnapshot } from '../../Auth/helpers/getDataFromQuerySnapshot'
import { Coords } from '../../Common/types/Coords'

type NormalizedRiderLocationLog = RiderLocationLog & {
  update: Coords
}

export interface RiderHistory {
  riderId?: string
  isLoading: boolean
  logs: NormalizedRiderLocationLog[]
  minute: number
  date: DateTime
  setRiderId: (riderId?: string) => void
  setMinute: (minute: number) => void
  setDate: (date: DateTime) => void
  subscribe: (date: DateTime, riderId: string) => void
  unsubscribe?: () => void
}

export const useRiderHistory = create<RiderHistory>((set, get) => ({
  logs: [],
  isLoading: true,
  minute: DateTime.local().minute,
  date: DateTime.local().startOf('day'),
  setRiderId: (riderId) => {
    if (riderId) get().subscribe(get().date, riderId)
    set({ riderId })
  },
  setDate: (date) => {
    const { riderId } = get()

    if (riderId) get().subscribe(date, riderId)
    set({ date })
  },
  setMinute: (minute) => set({ minute }),
  subscribe: (date, riderId) => {
    if (riderId === get().riderId && date === get().date) return

    get().unsubscribe?.()
    set({ isLoading: true })

    const unsubscribe = onSnapshot(
      query(
        getRiderLocationLogCollectionRef(riderId),
        orderBy('createdAt', 'desc'),
        where('createdAt', '>=', date.toJSDate()),
        where('createdAt', '<', date.plus({ day: 1 }).toJSDate())
      ),
      async (snapshot) => {
        const logs = getRiderLocationLogsFromSnapshot(snapshot)
          .reduce<NormalizedRiderLocationLog[]>((result, log) => {
            if (log.update.latitude && log.update.longitude) {
              result.push(log as NormalizedRiderLocationLog)
            }
            return result
          }, [])
          .sort((a, b) => b.createdAt.toMillis() - a.createdAt.toMillis())

        set({ logs, isLoading: false })
      },
      (error: any) => {
        toast.error(error.message)
        set({ logs: [], isLoading: false })
      }
    )

    set({
      unsubscribe: () => {
        unsubscribe()
        set({ logs: [], unsubscribe: undefined })
      },
    })
  },
}))
