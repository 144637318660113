import { Box, styled, Typography } from '@mui/material'
import { Task } from '@quickcommerceltd/zephyr-types'
import { DateTime } from 'luxon'
import { FC, useEffect, useState } from 'react'
import { COLOR } from '../../App/constants/COLOR'
import { getPromisedDeliveryAt } from '../helpers/getPromisedDeliveryAt'

interface Props {
  task: Task
  estimatedDeliveryAt?: DateTime
}

export const TaskOnTimeView: FC<Props> = (props) => {
  const [, setRerender] = useState(0)
  const promisedDeliveryAt = getPromisedDeliveryAt(props.task)
  const { estimatedDeliveryAt } = props
  const now = DateTime.local()

  useEffect(() => {
    const interval = setInterval(() => setRerender((v) => v + 1), 10 * 1000)

    return () => clearInterval(interval)
  }, [])

  if (!promisedDeliveryAt) return null
  if (!estimatedDeliveryAt) return null

  const isOnTime = promisedDeliveryAt > estimatedDeliveryAt
  const isIsDelayed = promisedDeliveryAt < now

  return (
    <SBox
      sx={{
        bgcolor: isOnTime ? COLOR.green[500] : isIsDelayed ? COLOR.red[500] : COLOR.orange[500],
      }}
    >
      <Typography fontSize={14} fontWeight={700}>
        {isOnTime ? 'on time' : isIsDelayed ? 'delayed' : 'forcasted delay'}
      </Typography>
    </SBox>
  )
}

const SBox = styled(Box)`
  padding: 2px 8px;
  border-radius: 6px;
  text-transform: uppercase;
`
