import { Box } from '@mui/material'
import { FC } from 'react'
import { SearchTextField } from '../../Common/components/SearchTextField'
import { useSidebarSearch } from '../hooks/useSidebarSearch'

export const SidebarSearchTextField: FC = () => {
  const { query, setQuery } = useSidebarSearch()

  return (
    <Box mt={2} mx={2}>
      <SearchTextField
        query={query}
        setQuery={setQuery}
        data-testid="SidebarSearchField"
        placeholder="Search for Task or Rider"
        fullWidth
      />
    </Box>
  )
}
