import { alpha, Box, Typography } from '@mui/material'
import { Task, ThirdPartyProvider } from '@quickcommerceltd/zephyr-types'
import { FC } from 'react'
import { COLOR } from '../../App/constants/COLOR'
import { getThirdPartyProviderTranslation } from '../../Common/helpers/getThirdPartyProviderTranslation'
import { useMap } from '../../Map/hooks/useMap'
import { RiderStatusIcon } from '../../Rider/components/RiderStatusIcon'
import { useDropThirdPartyProvider } from '../../Rider/hooks/useDropThirdPartyProvider'
import { SidebarListItem } from './SidebarListItem'
import { SidebarTaskListItemWithTooltip } from './SidebarTaskListItemWithTooltip'

interface Props {
  thirdPartyProvider: ThirdPartyProvider
  tasks: Task[]
}

export const SidebarThirdPartyProviderListItem: FC<Props> = (props) => {
  const [{ isOver, error }, drop] = useDropThirdPartyProvider(props.thirdPartyProvider)
  const isMapTaskFocused = useMap((store) => !!store.focusedMapTaskId)

  return (
    <>
      <SidebarListItem
        ref={drop}
        to={`/settings/stores`}
        sx={{
          opacity: isMapTaskFocused ? 0.1 : 1,
          ...(isOver && {
            backgroundColor: alpha(error ? COLOR.red[500] : COLOR.green[500], 0.5),
          }),
        }}
      >
        <RiderStatusIcon riderStatus="IDLE_AT_STORE" />
        <Box ml={1}>
          <Typography fontSize={12} fontWeight={700}>
            3P - {getThirdPartyProviderTranslation(props.thirdPartyProvider)}
          </Typography>
          <Typography fontSize={12} fontWeight={500} sx={{ opacity: 0.75 }}>
            Assign task here for {getThirdPartyProviderTranslation(props.thirdPartyProvider)} fulfillment
          </Typography>
        </Box>
      </SidebarListItem>
      {props.tasks.map((task) => (
        <SidebarTaskListItemWithTooltip key={task.id} task={task} pl={3} />
      ))}
    </>
  )
}
