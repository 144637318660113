import { alpha, Box, Typography } from '@mui/material'
import { FC } from 'react'
import { COLOR } from '../../App/constants/COLOR'
import { useAppConfig } from '../../App/hooks/useAppConfig'
import { isVersionSupported } from '../../Common/helpers/isVersionSupported'
import { useMap } from '../../Map/hooks/useMap'
import { RiderDescriptionView } from '../../Rider/components/RiderDescriptionView'
import { RiderStatusIcon } from '../../Rider/components/RiderStatusIcon'
import { getRiderName } from '../../Rider/helpers/getRiderName'
import { useDropRider } from '../../Rider/hooks/useDropRider'
import { useHoverRider } from '../../Rider/hooks/useHoverRider'
import { RiderWithCoords } from '../../Rider/types/RiderWithCoords'
import { useTasksOnRidersCurrentJourney } from '../../Task/hooks/useTasksOnRidersCurrentJourney'
import { SidebarListItem } from './SidebarListItem'
import { SidebarTaskListItem } from './SidebarTaskListItem'

interface Props {
  rider: RiderWithCoords
}

export const SidebarRiderListItem: FC<Props> = (props) => {
  const [{ isOver, error }, drop] = useDropRider(props.rider)
  const tasks = useTasksOnRidersCurrentJourney(props.rider)
  const { onMouseOut, onMouseOver } = useHoverRider(tasks, props.rider?.id)
  const isMapTaskFocused = useMap((store) => !!store.focusedMapTaskId)
  const isAppVersionSupported = useAppConfig((store) => {
    if (props.rider.isThirdPartyProvider || !props.rider.appPlatformOs || !props.rider.appVersion) {
      return true
    }

    return isVersionSupported(store.appConfig?.minVersion[props.rider.appPlatformOs] || '1', props.rider.appVersion)
  })
  const hasSidebarWarning = !isAppVersionSupported

  return (
    <>
      <SidebarListItem
        ref={drop}
        sx={{
          opacity: isMapTaskFocused ? 0.1 : 1,
          ...(isOver && {
            backgroundColor: alpha(error ? COLOR.red[500] : COLOR.green[500], 0.5),
          }),
        }}
        className={`warning-priority-${hasSidebarWarning ? 3 : 0}`}
        to={`/riders/${props.rider.id}`}
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
        data-testid={`SidebarRiderListItem-${props.rider.id}`}
      >
        <RiderStatusIcon riderStatus={props.rider.status} />
        <Box ml={1}>
          <Typography fontSize={12} fontWeight={700}>
            {getRiderName(props.rider)}
          </Typography>
          <Typography
            fontSize={12}
            fontWeight={500}
            sx={{
              opacity: 0.75,
              color: hasSidebarWarning ? COLOR.orange[500] : undefined,
            }}
          >
            {hasSidebarWarning && 'Unsupported app version'}
            {!hasSidebarWarning && <RiderDescriptionView rider={props.rider} />}
          </Typography>
        </Box>
      </SidebarListItem>
      {tasks.map((task, index) => (
        <SidebarTaskListItem key={task.id} task={task} pl={3} isMoveToBottomSupported={index !== tasks.length - 1} />
      ))}
    </>
  )
}
