import AddCircleIcon from '@mui/icons-material/AddCircle'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined'
import { RiderLog } from '@quickcommerceltd/zephyr-types'
import { FC } from 'react'
import { COLOR } from '../../App/constants/COLOR'
import { RiderStatusIcon } from '../../Rider/components/RiderStatusIcon'
import { getRiderStatusTranslation } from '../../Rider/helpers/getRiderStatusTranslation'
import { getLogListItemProps } from '../helpers/getLogListItemProps'
import { LogListItem } from './LogListItem'

interface Props {
  log: RiderLog
  isFirstItem: boolean
  isLastItem: boolean
  onClick: () => void
}

export const RiderLogListItem: FC<Props> = (props) => {
  const restProps = getLogListItemProps(props)

  if (props.log.update.createdAt) {
    return (
      <LogListItem {...restProps} title="Rider created" iconColor={COLOR.gray[300]} iconComponent={AddCircleIcon} />
    )
  }

  if (props.log.update.isOnDuty === true) {
    return <LogListItem {...restProps} title="On duty" iconColor={COLOR.gray[300]} iconComponent={CheckCircleIcon} />
  }

  if (props.log.update.isOnDuty === false) {
    return <LogListItem {...restProps} title="Off duty" iconColor={COLOR.gray[300]} iconComponent={CheckCircleIcon} />
  }

  if (props.log.update.assignedWarehouseId) {
    return (
      <LogListItem
        {...restProps}
        title="Reassigned store"
        iconColor={COLOR.green[500]}
        iconComponent={CheckCircleIcon}
      />
    )
  }

  if (props.log.update.status === 'IDLE_AT_CUSTOMER') {
    return (
      <LogListItem
        {...restProps}
        title="Completed stacked task"
        iconColor={COLOR.blue[500]}
        iconComponent={CheckCircleIcon}
      />
    )
  }

  if (props.log.update.status) {
    return (
      <LogListItem
        {...restProps}
        title={getRiderStatusTranslation(props.log.update.status)}
        icon={<RiderStatusIcon riderStatus={props.log.update.status} size={14} />}
      />
    )
  }

  return (
    <LogListItem {...restProps} title="Rider edited" iconColor={COLOR.gray[300]} iconComponent={CircleOutlinedIcon} />
  )
}
