import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import MyLocationOutlinedIcon from '@mui/icons-material/MyLocationOutlined'
import { Alert, Box, Button, IconButton } from '@mui/material'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { Rider } from '@quickcommerceltd/zephyr-types'
import { FC, useMemo, useState } from 'react'
import { Link, Outlet } from 'react-router-dom'
import { useHasPermission } from '../../Auth/hooks/useHasPermission'
import { DataGridPaper } from '../../Common/components/DataGridPaper'
import { LoadingView } from '../../Common/components/LoadingView'
import { SearchTextField } from '../../Common/components/SearchTextField'
import { ZAvatar } from '../../Common/components/ZAvatar'
import { useLocalStorage } from '../../Common/hooks/useLocalStorage'
import { RiderAssignWarehouseSelect } from '../../Rider/components/RiderAssignWarehouseSelect'
import { RiderStatusSelect } from '../../Rider/components/RiderStatusSelect'
import { getRiderName } from '../../Rider/helpers/getRiderName'
import { getRiderStatusTranslation } from '../../Rider/helpers/getRiderStatusTranslation'
import { useAllRiders } from '../../Rider/hooks/useAllRiders'
import { isQueryInFields } from '../../Sidebar/helpers/isQueryInFields'
import { useMyWarehouses } from '../../Warehouse/hooks/useMyWarehouses'
import { useWarehouses } from '../../Warehouse/hooks/useWarehouses'
import { SettingsRiderDeleteDialog } from './SettingsRiderDeleteDialog'
import { SettingsTitleView } from './SettingsTitleView'

export const SettingsRidersView: FC = () => {
  const [query, setQuery] = useState('')
  const [allRiders, isLoading, error] = useAllRiders()
  const hasPermission = useHasPermission()
  const localStorage = useLocalStorage()
  const warehouses = useWarehouses((state) => state.warehouses)
  const myWarehouses = useMyWarehouses()
  const [isRiderDeleteDialogOpen, setIsRiderDeleteDialogOpen] = useState(false)
  const [selectedRider, setSelectedRider] = useState<Rider>()
  const isLoaded = !isLoading && !error

  const filteredRiders = useMemo(() => {
    if (!query) return allRiders
    return allRiders.filter((rider) => isQueryInFields(query, [rider.id, rider.name, rider.phone]))
  }, [allRiders, query])

  const columns = useMemo<GridColDef<Rider>[]>(
    () => [
      {
        field: 'name',
        headerName: 'Name',
        width: 200,
        valueGetter: (params) => params.row.name || 'None',
        renderCell: (params) => (
          <>
            <ZAvatar name={params.row.name} alt={getRiderName(params.row)} sx={{ mr: 2 }} />
            {params.row.name || <em>None</em>}
          </>
        ),
      },
      { field: 'phone', headerName: 'Phone', width: 150 },
      { field: 'email', headerName: 'Email', width: 180 },
      {
        field: 'assignedWarehouseId',
        headerName: 'Store',
        width: 80,
        valueGetter: (params) => warehouses.find((w) => w.id === params.row.assignedWarehouseId)?.shortName,
        renderCell: (params) => params.formattedValue || <em>None</em>,
      },
      {
        field: 'status',
        headerName: 'Status',
        width: 120,
        valueGetter: (params) => getRiderStatusTranslation(params.row.status),
        renderCell: (params) => <RiderStatusSelect rider={params.row} />,
      },
      {
        field: 'actions',
        headerName: '',
        width: 230,
        filterable: false,
        sortable: false,
        disableColumnMenu: true,
        hide: !hasPermission('UPSERT_RIDER'),
        valueGetter: (params) => params.row.id,
        renderCell: (params) => (
          <Box className="row-action">
            <RiderAssignWarehouseSelect rider={params.row} />
            <IconButton component={Link} to={`/settings/riders/${params.row.id}`}>
              <EditOutlinedIcon color="secondary" />
            </IconButton>
            {!!params.row.assignedWarehouseId &&
              myWarehouses.map((w) => w.id).includes(params.row.assignedWarehouseId) && (
                <IconButton
                  component={Link}
                  to={`/riders/${params.row.id}`}
                  onClick={() =>
                    localStorage.set({
                      selectedWarehouseId: params.row.assignedWarehouseId,
                    })
                  }
                >
                  <MyLocationOutlinedIcon color="secondary" />
                </IconButton>
              )}
            <IconButton
              onClick={() => {
                setSelectedRider(params.row)
                setIsRiderDeleteDialogOpen(true)
              }}
            >
              <DeleteOutlinedIcon color="secondary" />
            </IconButton>
          </Box>
        ),
      },
    ],
    [hasPermission, localStorage, myWarehouses, warehouses]
  )

  return (
    <Box display="flex" flex={1} flexDirection="column">
      <Box display="flex" flexDirection="row">
        <SettingsTitleView>Riders</SettingsTitleView>
        <Box flex={1} />
        <SearchTextField
          query={query}
          setQuery={setQuery}
          sx={{ width: 320, mx: 2 }}
          placeholder="Search for ID, name, phone number"
        />
        {hasPermission('UPSERT_RIDER') && (
          <Button variant="contained" component={Link} to={'/settings/riders/new'}>
            Add Rider
          </Button>
        )}
      </Box>
      {isLoading && <LoadingView />}
      {error && (
        <Alert sx={{ mt: 2 }} severity="error">
          {error.message}
        </Alert>
      )}
      {isLoaded && (
        <DataGridPaper>
          <DataGrid
            columns={columns}
            rows={filteredRiders}
            getRowId={(rider) => rider.id}
            disableColumnSelector
            disableSelectionOnClick
          />
        </DataGridPaper>
      )}
      <SettingsRiderDeleteDialog
        key={`delete-${selectedRider?.id}`}
        rider={selectedRider}
        isOpen={isRiderDeleteDialogOpen}
        close={() => {
          setSelectedRider(undefined)
          setIsRiderDeleteDialogOpen(false)
        }}
      />
      <Outlet />
    </Box>
  )
}
