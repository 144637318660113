import { TaskFailureReason } from '@quickcommerceltd/zephyr-types'

export const TASK_FAILURE_REASONS: TaskFailureReason[] = [
  'ORDER_CANCELLED_BY_CUSTOMER',
  'ORDER_CANCELLED_BY_STORE',
  'DELIVERY_INCIDENT',
  'DESTINATION_INCORRECT',
  'ITEM_DAMAGED',
  'ITEM_INCORRECT',
  'UNAVAILABLE',
  'AGE_VERIFICATION_FAILED',
  'NO_3P_RIDER_AVAILABLE',
  'WRONG_ORDER_TAKEN',
  'OTHER',
]
