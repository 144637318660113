import { RiderStatus } from '@quickcommerceltd/zephyr-types'

export function getRiderStatusMarkerUrl(riderStatus: RiderStatus): string {
  switch (riderStatus) {
    case 'OFF_DUTY':
      return '/assets/pin-rider-off-duty.svg'
    case 'WAITING_FOR_PACKED_ORDER':
      return '/assets/pin-rider-waiting-for-packed-order.svg'
    case 'IDLE_AT_STORE':
      return '/assets/pin-rider-idle-at-store.svg'
    case 'IDLE_AT_CUSTOMER':
    case 'DELIVERING':
      return '/assets/pin-rider-delivering-v2.svg'
    case 'RIDING_BACK_TO_STORE':
    case 'PICKING_UP':
      return '/assets/pin-rider-riding-back-to-store-v2.svg'
  }
}
