import * as Sentry from '@sentry/react'
import { ENVIRONMENT } from '../../App/constants/ENVIRONMENT'

export function logError(error: Error) {
  if (ENVIRONMENT.nodeEnv === 'production') {
    try {
      Sentry.captureException(error)
    } catch {}
  }

  // eslint-disable-next-line no-console
  return console.warn(error)
}
