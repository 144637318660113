import LoadingButton from '@mui/lab/LoadingButton'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider } from '@mui/material'
import { Task, TaskStatus } from '@quickcommerceltd/zephyr-types'
import { FC, useCallback } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { AppThemeProvider } from '../../App/components/AppThemeProvider'
import { ControlledSelect } from '../../Common/components/ControlledSelect'
import { getTaskStatusTranslationByStatus } from '../helpers/getTaskStatusTranslationByStatus'
import { useCompleteTask } from '../hooks/useCompleteTask'

interface Props {
  task: Task
  isOpen: boolean
  close: () => void
}

interface FieldValues {
  taskStatus: TaskStatus
}

export const TaskReturnStatusChangeDialog: FC<Props> = (props) => {
  const { completeTask, isCompletingTask } = useCompleteTask()
  const { control, watch, handleSubmit } = useForm<FieldValues>({
    defaultValues: {
      taskStatus: 'RETURNED',
    },
  })

  const onSubmit = useCallback<SubmitHandler<FieldValues>>(
    async (values) => {
      await completeTask({
        task: props.task,
        nextTaskStatus: values.taskStatus,
      })

      props.close()
    },
    [completeTask, props]
  )

  watch(['taskStatus'])

  return (
    <AppThemeProvider mode="light">
      <Dialog open={props.isOpen} onClose={props.close} fullWidth>
        <DialogTitle>Change Task Return Status</DialogTitle>
        <DialogContent>
          <ControlledSelect
            control={control}
            name="taskStatus"
            label="Task Status"
            required
            items={['RETURNED' as TaskStatus]}
            getValue={(status) => status}
            getName={(status) => getTaskStatusTranslationByStatus(status)}
            sx={{ mt: 2 }}
            fullWidth
          />
        </DialogContent>
        <Divider />
        <DialogActions>
          <LoadingButton
            variant="contained"
            loading={isCompletingTask}
            onClick={handleSubmit(onSubmit)}
            data-testid="TaskCompleteDialogCompleteButton"
          >
            Change Status
          </LoadingButton>
          <Button onClick={props.close} autoFocus data-testid="TaskCompleteDialogCancelButton">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </AppThemeProvider>
  )
}
