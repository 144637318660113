import { Rider, Warehouse } from '@quickcommerceltd/zephyr-types'
import { useCallback, useState } from 'react'
import toast from 'react-hot-toast'
import { assignRiderWarehouseCallable } from '../../Auth/callables/task/assignRiderWarehouseCallable'
import { logError } from '../../Common/helpers/logError'

export function useAssignRiderWarehouse(): [(rider: Rider, warehouse: Warehouse) => Promise<void>, boolean] {
  const [isAssigningRiderWarehouse, setIsAssigningRiderWarehouse] = useState(false)

  const assignRiderWarehouse = useCallback(async (rider: Rider, warehouse: Warehouse) => {
    try {
      setIsAssigningRiderWarehouse(true)

      await assignRiderWarehouseCallable({
        warehouseId: warehouse.id,
        riderId: rider.id,
      })
    } catch (error: any) {
      toast.error(error.message)
      logError(error)
    } finally {
      setIsAssigningRiderWarehouse(false)
    }
  }, [])

  return [assignRiderWarehouse, isAssigningRiderWarehouse]
}
