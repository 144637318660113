import { Box, Divider, ListItem, SvgIconTypeMap, Typography, useTheme } from '@mui/material'
import { OverridableComponent } from '@mui/material/OverridableComponent'
import { FC, ReactNode } from 'react'
import { COLOR } from '../../App/constants/COLOR'

interface Props {
  iconComponent?: OverridableComponent<SvgIconTypeMap>
  iconColor?: string
  icon?: ReactNode
  title: string
  message: ReactNode
  isLastItem?: boolean
  isFirstItem?: boolean
  onClick?: () => void
}

export const LogListItem: FC<Props> = (props) => {
  const theme = useTheme()
  const dividerColor = theme.palette.mode === 'light' ? COLOR.gray[300] : COLOR.white[0]

  return (
    <ListItem sx={{ py: 0 }} button onClick={props.onClick}>
      <Box display="flex" flexDirection="row">
        <Box display="flex" flexDirection="column" width={24}>
          <Box display="flex" justifyContent="center" height={8}>
            {!props.isFirstItem && <Divider orientation="vertical" sx={{ borderColor: dividerColor }} />}
          </Box>
          <Box display="flex" justifyContent="center" my="2px">
            {props.icon}
            {props.iconComponent && props.iconColor && (
              <props.iconComponent sx={{ color: props.iconColor, fontSize: 14 }} />
            )}
          </Box>
          {!props.isLastItem && (
            <Box display="flex" justifyContent="center" flex={1}>
              <Divider orientation="vertical" sx={{ borderColor: dividerColor }} />
            </Box>
          )}
        </Box>
        <Box ml={1} py={1}>
          <Typography fontSize={12} fontWeight={700}>
            {props.title}
          </Typography>
          <Typography fontSize={12} fontWeight={500} sx={{ opacity: 0.75 }}>
            {props.message}
          </Typography>
        </Box>
      </Box>
    </ListItem>
  )
}
