import { RiderStatus, Task, Warehouse } from '@quickcommerceltd/zephyr-types'
import { getDistanceInMiles } from '../../Map/helpers/getDistance'
import { RESSOURCE_PERFORMANCE } from '../constants/RESSOURCE_PERFORMANCE'
import { RiderWithCoords } from '../types/RiderWithCoords'
import { isRiderNotStarted } from './isRiderNotStarted'

const ALLOWED_RIDER_STATUSES: RiderStatus[] = [
  'RIDING_BACK_TO_STORE',
  'WAITING_FOR_PACKED_ORDER',
  'DELIVERING',
  'IDLE_AT_CUSTOMER',
]

export function getEstimatedDeliveryTimeInMinutes(
  warehouse: Warehouse,
  rider: RiderWithCoords,
  tasks: Task[],
  destinationTaskId: string
) {
  const riderCoords = rider.coords
  const unpackedTasks = tasks.filter((t) => t.pickingStatus !== 'PACKED')
  let distance = 0
  let minutes = 0

  if (!riderCoords) return undefined
  if (!ALLOWED_RIDER_STATUSES.includes(rider.status)) return undefined
  if (rider.thirdPartyProvider) return undefined

  // Riding back to store.
  if (rider.status === 'RIDING_BACK_TO_STORE') {
    distance += getDistanceInMiles(riderCoords, warehouse.address)
  }

  // Pick up orders from store.
  if (isRiderNotStarted(rider)) {
    minutes += RESSOURCE_PERFORMANCE.avgPickUpDurationInMinutes
  }

  // Wait for non packed tasks, but only if rider has arrived at store.
  if (rider.status === 'WAITING_FOR_PACKED_ORDER' && unpackedTasks.length) {
    minutes += unpackedTasks.length * RESSOURCE_PERFORMANCE.avgPickingDurationInMinutes
  }

  // Start next task.
  if (rider.status === 'IDLE_AT_CUSTOMER') {
    minutes += RESSOURCE_PERFORMANCE.avgStartNextTaskDurationInMinutes
  }

  // Loop through the tasks on the trip.
  for (let index = 0; index <= tasks.length - 1; index++) {
    const nextTask = tasks[index]
    const nextCoords = nextTask.address.location
    const previousTask = tasks[index - 1]

    if (index === 0) {
      if (isRiderNotStarted(rider)) {
        // From store to next task.
        distance += getDistanceInMiles(warehouse.address, nextCoords)
      } else {
        // From rider to next task.
        distance += getDistanceInMiles(riderCoords, nextCoords)
      }
    } else {
      // From previous task to next task.
      distance += getDistanceInMiles(previousTask.address.location, nextCoords)
    }

    minutes += RESSOURCE_PERFORMANCE.avgHandoverDurationInMinutes

    // Do not proceed, after the destination has reached.
    if (destinationTaskId === nextTask.id) break

    minutes += RESSOURCE_PERFORMANCE.avgStartNextTaskDurationInMinutes
  }

  minutes += (distance / RESSOURCE_PERFORMANCE.avgMilesPerHours) * 60

  return minutes
}
