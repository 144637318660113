import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { Box, ListItemButton, SvgIconTypeMap, Typography } from '@mui/material'
import { OverridableComponent } from '@mui/material/OverridableComponent'
import { FC, ReactNode, useCallback } from 'react'
import { COLOR } from '../../App/constants/COLOR'
import { useFilter } from '../hooks/useFilter'
import { FilterKind } from '../types/FilterKind'

interface Props {
  title: string
  children: ReactNode
  filterKind: FilterKind
  iconComponent: OverridableComponent<SvgIconTypeMap>
}

export const FilterKindListItem: FC<Props> = (props) => {
  const filter = useFilter()
  const isSelected = filter.filterKind === props.filterKind
  const setFilterKind = useCallback(() => filter.setFilterKind(props.filterKind), [filter, props.filterKind])

  return (
    <ListItemButton
      onClick={setFilterKind}
      sx={{
        backgroundColor: isSelected ? COLOR.blue[50] : COLOR.white[0],
        '&:hover': {
          backgroundColor: isSelected ? COLOR.blue[100] : COLOR.gray[50],
        },
      }}
    >
      <props.iconComponent />
      <Box mx={2} flex={1} py={0.5}>
        <Typography fontWeight="bold" lineHeight={1.3}>
          {props.title}
        </Typography>
        <Typography fontSize={12} sx={{ opacity: 0.75 }}>
          {props.children}
        </Typography>
      </Box>
      <ArrowForwardIosIcon sx={{ fontSize: 12, color: COLOR.gray[100] }} />
    </ListItemButton>
  )
}
