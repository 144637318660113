import { Rider, Task } from '@quickcommerceltd/zephyr-types'
import { useMemo } from 'react'
import { isTaskOnRidersCurrentJourney } from '../helpers/isTaskOnRidersCurrentJourney'
import { useTasks } from './useTasks'

export function useTasksOnRidersCurrentJourney(rider?: Rider) {
  const tasks = useTasks((store) => store.tasks)

  return useMemo(
    () =>
      tasks
        .filter((t) => isTaskOnRidersCurrentJourney(t, rider?.id))
        .sort(rider?.thirdPartyProvider ? compareThirdPartyProviderRiderTasks : compareFirstPartyRiderTasks),
    [rider?.id, rider?.thirdPartyProvider, tasks]
  )
}

function compareFirstPartyRiderTasks(a: Task, b: Task) {
  return (a.assignedAt?.toMillis() || 0) - (b.assignedAt?.toMillis() || 0)
}

function compareThirdPartyProviderRiderTasks(a: Task, b: Task) {
  return (a.estimatedDeliveryAt?.toMillis() || 0) - (b.estimatedDeliveryAt?.toMillis() || 0)
}
