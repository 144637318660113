import { Rider, WarehouseRiderLocation } from '@quickcommerceltd/zephyr-types'
import { onSnapshot, query, where } from 'firebase/firestore'
import toast from 'react-hot-toast'
import create from 'zustand'
import { getRiderCollectionRef } from '../../Auth/helpers/getCollectionRef'
import { getWarehouseRiderLocationFromSnapshot } from '../../Auth/helpers/getDataFromDocumentSnapshot'
import { getRidersFromSnapshot } from '../../Auth/helpers/getDataFromQuerySnapshot'
import { getWarehouseRiderLocationDocRef } from '../../Auth/helpers/getDocRef'
import { getRidersWithCoords } from '../helpers/getRidersWithCoords'
import { RiderWithCoords } from '../types/RiderWithCoords'

export interface RidersStore {
  riders: RiderWithCoords[]
  subscribe: (warehouseId: string) => void
  unsubscribe: () => void
  unsubscribeFirestore?: () => void
}

export const useRiders = create<RidersStore>((set, get) => ({
  riders: [],
  subscribe: (warehouseId) => {
    let nonThirdPartyRiders: Rider[] = []
    let thirdPartyRiders: Rider[] = []
    let riderLocations: WarehouseRiderLocation['riderLocations'] = []

    const setRiders = () => {
      set({
        riders: getRidersWithCoords([...nonThirdPartyRiders, ...thirdPartyRiders], riderLocations),
      })
    }

    const unsubscribeNonThirdPartyRiders = onSnapshot(
      query(
        getRiderCollectionRef(),
        where('assignedWarehouseId', '==', warehouseId),
        where('isThirdPartyProvider', '==', false)
      ),
      async (snapshot) => {
        if (snapshot.metadata.hasPendingWrites) return
        nonThirdPartyRiders = getRidersFromSnapshot(snapshot)
        setRiders()
      },
      (error: any) => {
        toast.error(error.message)
        set({ riders: [] })
      }
    )

    const unsubscribeThirdPartyRiders = onSnapshot(
      query(
        getRiderCollectionRef(),
        where('assignedWarehouseId', '==', warehouseId),
        where('isThirdPartyProvider', '==', true),
        where('isOnDuty', '==', true)
      ),
      async (snapshot) => {
        if (snapshot.metadata.hasPendingWrites) return
        thirdPartyRiders = getRidersFromSnapshot(snapshot)
        setRiders()
      },
      (error: any) => {
        toast.error(error.message)
        set({ riders: [] })
      }
    )

    const unsubscribeWarehouseRiderLocation = onSnapshot(
      getWarehouseRiderLocationDocRef(warehouseId),
      async (snapshot) => {
        riderLocations = getWarehouseRiderLocationFromSnapshot(snapshot)?.riderLocations || []
        setRiders()
      },
      (error: any) => {
        toast.error(error.message)
        set({ riders: [] })
      }
    )

    set({
      unsubscribeFirestore: () => {
        unsubscribeNonThirdPartyRiders()
        unsubscribeThirdPartyRiders()
        unsubscribeWarehouseRiderLocation()
      },
    })
  },
  unsubscribe: () => {
    set({ riders: [] })
    get().unsubscribeFirestore?.()
  },
}))
