import { TransportType } from '@quickcommerceltd/zephyr-types/dist/types/TransportType'

export function getTransportTypeTranslation(transportType: TransportType): string {
  switch (transportType) {
    case 'BIKE':
      return 'Bike'
    case 'CAR':
      return 'Car'
  }
}
