import LoadingButton from '@mui/lab/LoadingButton'
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Stack } from '@mui/material'
import { TaskUnassignReason } from '@quickcommerceltd/zephyr-types'
import { FC, useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { AppThemeProvider } from '../../App/components/AppThemeProvider'
import { unassignTaskFromThirdPartyProviderCallable } from '../../Auth/callables/task/unassignTaskFromThirdPartyProviderCallable'
import { ControlledSelect } from '../../Common/components/ControlledSelect'
import { ControlledTextField } from '../../Common/components/ControlledTextField'
import { logError } from '../../Common/helpers/logError'
import { TASK_UNASSIGN_REASONS } from '../constants/TASK_UNASSIGN_REASONS'
import { getTaskUnassignReasonTranslation } from '../helpers/getTaskUnassignReasonTranslation'
import { useTasks } from '../hooks/useTasks'
import { useUnassignTaskFromThirdPartyProviderDialog } from '../hooks/useUnassignTaskFromThirdPartyProviderDialog'

type FormValues = {
  comment: string
  reason: TaskUnassignReason
}

const defaultValues: FormValues = {
  comment: '',
  reason: 'ACCIDENTALLY_REQUESTED',
}

export const TaskUnassignFromThirdPartyProviderDialog: FC = () => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { isDialogOpen, closeDialog, taskId } = useUnassignTaskFromThirdPartyProviderDialog()

  const { reset, control, handleSubmit } = useForm<FormValues>({
    defaultValues,
  })

  const task = useTasks((state) => state.tasks.find((t) => t.id === taskId))

  useEffect(() => {
    if (isDialogOpen) {
      reset(defaultValues)
    }
  }, [isDialogOpen, reset])

  const close = useCallback(() => {
    closeDialog?.()
  }, [closeDialog])

  const onSubmit = useCallback(
    async (formValues: FormValues) => {
      if (!taskId) return close()

      try {
        setIsSubmitting(true)
        await unassignTaskFromThirdPartyProviderCallable({
          taskId,
          comment: formValues.comment,
          reason: formValues.reason,
        })

        close()
      } catch (error: any) {
        toast.error(error.message)
        logError(error)
      } finally {
        setIsSubmitting(false)
      }
    },
    [close, taskId]
  )

  if (!isDialogOpen) return null

  return (
    <AppThemeProvider mode="light">
      <Dialog open onClose={close} fullWidth>
        <form>
          <DialogTitle>Unassign Rider</DialogTitle>
          <Divider />
          <DialogContent>
            <Stack spacing={2}>
              <ControlledSelect
                control={control}
                name="reason"
                label="Reason"
                items={TASK_UNASSIGN_REASONS}
                disabled={isSubmitting}
                getValue={(reason) => reason}
                getName={getTaskUnassignReasonTranslation}
                required
                fullWidth
              />
              <ControlledTextField
                name="comment"
                label="Comment"
                control={control}
                disabled={isSubmitting}
                fullWidth
                multiline
              />
            </Stack>
          </DialogContent>
          <Divider />
          {task?.assignedAt && (
            <Alert severity="error">
              Rider has already been assigned for this task! Zapp will be charged a cancelation fee.
            </Alert>
          )}
          <DialogActions>
            <LoadingButton
              autoFocus
              variant="contained"
              loading={isSubmitting}
              disabled={isSubmitting}
              onClick={handleSubmit(onSubmit)}
            >
              Unassign
            </LoadingButton>
            <Button onClick={close} disabled={isSubmitting}>
              Cancel
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </AppThemeProvider>
  )
}
