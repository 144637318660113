import { Box, CircularProgress } from '@mui/material'
import { FC } from 'react'

export const LoadingView: FC = () => {
  return (
    <Box display="flex" alignItems="center" justifyContent="center" height="100%">
      <CircularProgress />
    </Box>
  )
}
