import { Task, ThirdPartyProvider } from '@quickcommerceltd/zephyr-types'
import { useMemo } from 'react'
import { useThirdPartyProviders } from '../../Common/hooks/useThirdPartyProviders'
import { useTasks } from '../../Task/hooks/useTasks'
import { useSelectedWarehouse } from './useSelectedWarehouse'

interface PendingTasks {
  thirdPartyProvider: ThirdPartyProvider
  tasks: Task[]
}

export function useSelectedWarehousePendingTasks(): PendingTasks[] {
  const warehouse = useSelectedWarehouse()
  const thirdPartyProviders = useThirdPartyProviders()
  const tasks = useTasks((state) => state.tasks)

  return useMemo(() => {
    return thirdPartyProviders
      .map<PendingTasks>((thirdPartyProvider) => ({
        thirdPartyProvider,
        tasks: tasks
          .filter(
            (task) => task.status === 'PENDING_RIDER_ASSIGNMENT' && task.thirdPartyProvider === thirdPartyProvider
          )
          .sort((a, b) => b.createdAt.toMillis() - a.createdAt.toMillis()),
      }))
      .filter(
        (pendingTasks) =>
          warehouse?.thirdPartyProviderConfigs?.[pendingTasks.thirdPartyProvider]?.isEnabled ||
          pendingTasks.tasks.length
      )
  }, [tasks, thirdPartyProviders, warehouse?.thirdPartyProviderConfigs])
}
