import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import { Box, IconButton, Slide, useTheme } from '@mui/material'
import { FC, ReactNode } from 'react'
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom'
import { EscapeKeyToClose } from '../../Common/components/EscapeKeyToClose'
import { SIDEBAR_WIDTH } from '../../Sidebar/constants/SIDEBAR_WIDTH'
import { APP_HEADER_HEIGHT } from '../constants/APP_HEADER_HEIGHT'
import { COLOR } from '../constants/COLOR'

const SIDEBAR_HEIGHT = `calc(100vh - ${APP_HEADER_HEIGHT}px)`

interface Props {
  /**
   * Element
   */
  e: ReactNode
  /**
   * Level
   */
  l: number
}

const AppRoute: FC<Props> = (props) => {
  const theme = useTheme()
  const location = useLocation()
  const navigate = useNavigate()
  const to =
    location.pathname
      .split('/')
      .slice(0, (props.l - 1) * -2)
      .join('/') || '/'

  return (
    <Slide direction="right" easing={{ enter: theme.transitions.easing.easeOut }} in>
      <Box
        position="absolute"
        width={SIDEBAR_WIDTH}
        height={SIDEBAR_HEIGHT}
        left={(props.l - 1) * SIDEBAR_WIDTH}
        top={props.l === 1 ? APP_HEADER_HEIGHT : 0}
      >
        <EscapeKeyToClose close={() => navigate(to)} />
        <Outlet />
        <Box
          position="absolute"
          width={SIDEBAR_WIDTH}
          height={SIDEBAR_HEIGHT}
          bgcolor={COLOR.rock[500]}
          overflow="auto"
          sx={{ boxShadow: 3 }}
        >
          <Box p={1} display="flex" justifyContent={props.l === 1 ? 'flex-start' : 'flex-end'}>
            <IconButton color="inherit" size="large" component={Link} to={to}>
              {props.l === 1 ? <ArrowBackIcon /> : <CloseOutlinedIcon />}
            </IconButton>
          </Box>
          {props.e}
        </Box>
      </Box>
    </Slide>
  )
}

export const R = AppRoute
