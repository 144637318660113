import { Alert, Box, Button, Grid, Typography } from '@mui/material'
import { FC } from 'react'
import { logout } from '../../Auth/helpers/logout'

interface Props {
  title: string
}

export const AuthAlertView: FC<Props> = (props) => {
  return (
    <Grid container minHeight="100%" justifyContent="center" alignItems="center">
      <Alert severity="error">
        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography>{props.title}</Typography>
          <Button sx={{ mt: 2 }} color="primary" variant="contained" onClick={logout}>
            Logout
          </Button>
        </Box>
      </Alert>
    </Grid>
  )
}
