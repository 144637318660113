import { Rider } from '@quickcommerceltd/zephyr-types'
import { capitalize } from '../../Common/helpers/capitalize'
import { getThirdPartyProviderTranslation } from '../../Common/helpers/getThirdPartyProviderTranslation'

export function getRiderName(rider: Rider) {
  const name = capitalize(rider.name)

  if (rider.thirdPartyProvider) {
    return `${name} (${getThirdPartyProviderTranslation(rider.thirdPartyProvider)})`
  }

  return name
}
