import { WarehouseStatus } from '@quickcommerceltd/zephyr-types'

export function getWarehouseStatusTranslation(warehouseStatus: WarehouseStatus) {
  switch (warehouseStatus) {
    case 'CLOSED':
      return 'Closed'
    case 'DRAFT':
      return 'Draft'
    case 'OPEN':
      return 'Open'
  }
}
