import { Autocomplete, SxProps, TextField } from '@mui/material'
import { useCallback, useState } from 'react'
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService'
import { Control, Controller, FieldPath, FieldValues } from 'react-hook-form'
import { AutocompleteAddress, getAutocompleteAddressFromPlace } from '../helpers/getAutocompleteAddressFromPlace'

interface Props<T extends FieldValues> {
  label: string
  control: Control<T>
  name: FieldPath<T>
  sx?: SxProps
  required?: boolean
  fullWidth?: boolean
  helperText?: string
  onAddressChanged: (address: AutocompleteAddress) => void
}

export function ControlledAddressSearchField<T extends FieldValues>(props: Props<T>) {
  const [helperText, setHelperText] = useState<string>()
  const { placesService, placePredictions, getPlacePredictions, isPlacePredictionsLoading, autocompleteSessionToken } =
    usePlacesService({
      debounce: 500,
    })

  const onSearchQueryChange = useCallback(
    (query: string) => {
      getPlacePredictions({
        input: query,
        sessionToken: autocompleteSessionToken,
      })

      if (!query) {
        setHelperText(undefined)
      }
    },
    [autocompleteSessionToken, getPlacePredictions]
  )

  const onSelectPrediction = useCallback(
    (prediction: string | google.maps.places.AutocompletePrediction) => {
      setHelperText(undefined)
      if (!prediction || typeof prediction === 'string') return

      placesService?.getDetails(
        {
          placeId: prediction.place_id,
          fields: ['address_component', 'geometry'],
        },
        (place) => {
          const address = getAutocompleteAddressFromPlace(place, prediction.structured_formatting.main_text)

          if (!address) {
            setHelperText('could not find address location')
            return
          }

          props.onAddressChanged(address)
        }
      )
    },
    [placesService, props]
  )

  return (
    <Controller
      name={props.name}
      control={props.control}
      rules={{ required: props.required && 'Required' }}
      render={({ field, fieldState }) => (
        <Autocomplete
          freeSolo
          fullWidth={props.fullWidth}
          size="small"
          sx={{ display: 'flex', ...props.sx }}
          options={placePredictions}
          loading={isPlacePredictionsLoading}
          value={field.value as string}
          onChange={(_, v) => !!v && onSelectPrediction(v)}
          onInputChange={(_, v) => {
            field.onChange(v)
            onSearchQueryChange(v)
          }}
          getOptionLabel={(option: string | google.maps.places.AutocompletePrediction) =>
            typeof option === 'string' ? option : option.description
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label={props.label}
              value={field.value}
              required={props.required}
              error={!!fieldState.error}
              helperText={helperText || props.helperText || fieldState.error?.message}
              sx={{
                '.MuiAutocomplete-endAdornment': { position: 'initial' },
                '.MuiSvgIcon-fontSizeSmall': {
                  height: '0.8em',
                  width: '0.8em',
                },
              }}
            />
          )}
        />
      )}
    />
  )
}
