import { Task } from '@quickcommerceltd/zephyr-types'

export const isZappTask = (task: Task | undefined): boolean => {
  if (!task) return true

  return [undefined, 'ZAPP'].includes(task?.orderOrigin) && !task.marketplaceOrderId
}

export const isMarketplaceTask = (task: Task | undefined): boolean => {
  return !isZappTask(task)
}
