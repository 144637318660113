import { Box, Typography } from '@mui/material'
import { FC } from 'react'

export const App404View: FC = () => {
  return (
    <Box flex={1} display="flex" alignItems="center" justifyContent="center" flexDirection="column">
      <Typography variant="h2">404</Typography>
      <Typography>Page not found.</Typography>
    </Box>
  )
}
