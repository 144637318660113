import { FormControlLabel, Switch, SwitchProps, SxProps, Theme } from '@mui/material'
import { Control, Controller, FieldPath, FieldValues } from 'react-hook-form'

interface Props<T extends FieldValues> {
  control: Control<T>
  name: FieldPath<T>
  label: string
  color?: SwitchProps['color']
  sx?: SxProps<Theme>
  disabled?: boolean
}

export function ControlledSwitch<T extends FieldValues>(props: Props<T>) {
  return (
    <Controller
      name={props.name}
      control={props.control}
      render={({ field }) => (
        <FormControlLabel
          {...field}
          disabled={props.disabled}
          checked={!!field.value}
          control={<Switch color={props.color} />}
          label={props.label}
        />
      )}
    />
  )
}
