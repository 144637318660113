import { useMemo } from 'react'
import { useThirdPartyProviders } from '../../Common/hooks/useThirdPartyProviders'
import { useSelectedWarehouse } from './useSelectedWarehouse'

export function useSelectedWarehouseThirdPartyProviders() {
  const warehouse = useSelectedWarehouse()
  const thirdPartyProviders = useThirdPartyProviders()

  return useMemo(
    () => thirdPartyProviders.filter((provider) => warehouse?.thirdPartyProviderConfigs?.[provider]?.isEnabled),
    [thirdPartyProviders, warehouse?.thirdPartyProviderConfigs]
  )
}
