import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { FC } from 'react'

interface Props {
  isOpen: boolean
}

export const ZChevron: FC<Props> = (props) => {
  return (
    <ArrowDropDownIcon
      sx={{
        transform: `rotate(${props.isOpen ? 180 : 0}deg)`,
        transition: 'transform 150ms linear 0ms',
      }}
    />
  )
}
