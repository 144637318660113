import { Task, Warehouse } from '@quickcommerceltd/zephyr-types'
import { DateTime } from 'luxon'
import { useMemo } from 'react'
import { useTasksOnRidersCurrentJourney } from '../../Task/hooks/useTasksOnRidersCurrentJourney'
import { getEstimatedDeliveryTimeInMinutes } from '../helpers/getEstimatedDeliveryTimeInMinutes'
import { RiderWithCoords } from '../types/RiderWithCoords'

interface Props {
  warehouse?: Warehouse
  rider?: RiderWithCoords
  task?: Task
}

export function useEstimatedDeliveryAt(props: Props) {
  const tasks = useTasksOnRidersCurrentJourney(props.rider)

  return useMemo(() => {
    if (props.task?.estimatedDeliveryAt) {
      return DateTime.fromMillis(props.task.estimatedDeliveryAt.toMillis())
    }

    if (!props.warehouse) return
    if (!props.rider) return
    if (!props.task) return

    return DateTime.now().plus({
      minutes: getEstimatedDeliveryTimeInMinutes(props.warehouse, props.rider, tasks, props.task.id),
    })
  }, [props.rider, props.task, props.warehouse, tasks])
}
